import {
  Button,
  Container,
  Stack,
  Title,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import authService from "../api/authService";
import RouteList from "./routes";

const image = require("../assets/401.png");
const logo = require("../assets/logo.png");

interface NotAuthProps {}

const NotAuth: React.FC<NotAuthProps> = ({}) => {
  const theme = useMantineTheme();
  const navigation = useNavigate();

  return (
    <Container size={"xl"} style={{ height: "100vh" }}>
      <img
        src={logo}
        alt="logo"
        style={{ width: 120, height: 70, objectFit: "contain" }}
      />
      <Stack
        justify={"center"}
        align={"center"}
        style={{ height: "calc( 100% - 100px)" }}
      >
        <img
          src={image}
          style={{ width: 300, height: 300, objectFit: "contain" }}
          alt="404"
        />
        <Title>Not authorized</Title>
        <Text style={{ color: theme.colors.grey[0] }}>
          This account is not authorized to access this page
        </Text>
        {authService.getCurrentUser() ? (
          <Button
            radius={"xl"}
            size="lg"
            color={"indigo"}
            mt="xl"
            onClick={() => {
              authService.logout();
              navigation(`../${RouteList.AUTH_LOGIN}`, { replace: true });
            }}
          >
            Log out
          </Button>
        ) : (
          <Button
            radius={"xl"}
            size="lg"
            color={"indigo"}
            mt="xl"
            onClick={() => navigation(-1)}
          >
            Go back
          </Button>
        )}
      </Stack>
    </Container>
  );
};

export default NotAuth;

import {
  Avatar,
  Box,
  Button,
  Grid,
  Group,
  Loader,
  Table,
  Text,
  Title,
  Badge,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowsSort,
  Plus,
  SortAscending2,
  SortDescending2,
} from "tabler-icons-react";
import GlobalSearch from "../../components/GlobalSearch";
import {
  useTable,
  useSortBy,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";
import NewAccountForm from "../../components/NewAccountForm";
import userService from "../../api/userService";
import { showNotification } from "@mantine/notifications";
import { User } from "../../model/User";

interface CompanyAccountProps {}

const CompanyAccount: React.FC<CompanyAccountProps> = ({}) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [newForm, setNewForm] = useState(false);

  let location = useLocation();
  const id = location.pathname.split("/")[3];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await userService.getCompanyUsers(id);
      setData(result.data);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status >= 400 && ex.response.status < 500)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "userName",
        sortType: "basic",
        Cell: (props) => (
          <Group spacing={"xs"}>
            <Avatar
              src={props.row.original.avatar}
              color="indigo"
              size={"sm"}
              radius="xl"
            >
              {props.row.original.userName[0]}
            </Avatar>
            <Text size="sm">{props.row.original.userName}</Text>
          </Group>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
      },
      {
        Header: "Phones",
        accessor: "phones",
        sortType: "basic",
        Cell: (props) =>
          props.row.original.phones.length === 0
            ? "No data"
            : props.row.original.phones.length > 1
            ? `${props.row.original.phones[0]} and more`
            : props.row.original.phones[0],
      },
      {
        Header: "Roles",
        accessor: "roles",
        Cell: (props) => (
          <Group spacing={"xs"}>
            {props.row.original.roles &&
              props.row.original.roles.map((r) => (
                <Badge key={r} color={r === "admin" ? "green" : "indigo"}>
                  {r}
                </Badge>
              ))}
          </Group>
        ),
      },
      {
        Header: "Assigned vehicles",
        accessor: "vehicles",
        sortType: "basic",
        Cell: (props) => props.row.original.vehicles.length,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      //   defaultColumn,
      //   filterTypes,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    state,
  } = tableInstance;

  const handleUpdate = (user: User) => {
    setData([user, ...data]);
  };

  return (
    <Grid gutter={0} style={{ height: "calc(100vh - 55px)" }}>
      <NewAccountForm
        visible={newForm}
        onClose={() => setNewForm(false)}
        companyId={id}
        initialData={undefined}
        onUpdate={handleUpdate}
      />
      <Grid.Col span={8}>
        <Box p="xl">
          <Group position="apart">
            <Title order={3}>Accounts</Title>
            <Button
              leftIcon={<Plus />}
              radius="xl"
              color={"indigo"}
              size="xs"
              onClick={() => setNewForm(true)}
            >
              New account
            </Button>
          </Group>

          <GlobalSearch
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={`Search ${preGlobalFilteredRows.length} accounts..`}
          />
          {loading && (
            <Group position="center" style={{ width: "100%" }} py="xl">
              <Loader variant="bars" color={theme.colors.secondary[0]} />
            </Group>
          )}
          {!loading && (
            <div
              style={{
                maxHeight: "calc(100vh - 250px)",
                overflow: "scroll",
                border: `1px solid ${theme.colors.border[0]}`,
                borderRadius: 5,
              }}
            >
              <Table highlightOnHover>
                <table {...getTableProps()} style={{ width: "100%" }}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: theme.colors.background[1],
                        }}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <Group>
                              {column.render("Header")}

                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDescending2
                                    size={15}
                                    color={
                                      column.isSorted
                                        ? theme.colors.secondary[0]
                                        : theme.colors.white[0]
                                    }
                                  />
                                ) : (
                                  <SortAscending2
                                    size={15}
                                    color={
                                      column.isSorted
                                        ? theme.colors.secondary[0]
                                        : theme.colors.white[0]
                                    }
                                  />
                                )
                              ) : (
                                <ArrowsSort size={15} />
                              )}
                            </Group>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.length === 0 ? (
                      <Text p="md">No account data</Text>
                    ) : (
                      rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() => navigate(`${row.original._id}`)}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </Table>
            </div>
          )}
        </Box>
      </Grid.Col>

      <Grid.Col span={4}>
        <Box
          p="xl"
          style={{
            // backgroundColor: theme.colors.background[1],
            borderLeft: `1px solid ${theme.colors.border[0]}`,
            height: "100%",
          }}
        >
          {<Outlet />}
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default CompanyAccount;

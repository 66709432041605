import {
  Badge,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Grid,
  Group,
  Loader,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useFilters,
  useRowSelect,
} from "react-table";
import {
  ArrowsSort,
  Plus,
  Search,
  SortAscending2,
  SortDescending2,
  Tools,
} from "tabler-icons-react";
import vehicleService from "../../api/vehicleService";
import CompanyProfile from "../../components/CompanyProfile";
import GlobalSearch from "../../components/GlobalSearch";
import NewVehicleDrawer from "../../components/NewVehicleDrawer";
import SubscriptionUpdateForm from "../../components/SubscriptionUpdateForm";
import { Company } from "../../model/Company";
import { Vehicle } from "../../model/Vehicle";

interface CompanyVehiclesProps {}

const CompanyVehicles: React.FC<CompanyVehiclesProps> = ({}) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [newForm, setNewForm] = useState(false);
  const [updateTogether, setUpdateTogether] = useState(false);
  let location = useLocation();
  let isDetail = location.pathname.includes("/vehicles/");
  const id = location.pathname.split("/")[3];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    try {
      const result = await vehicleService.getAdminVehicles(id);
      setData(result.data);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status >= 400 && ex.response.status < 500)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Device IMEI",
        accessor: "deviceIMEI",
        sortType: "basic",
      },
      {
        Header: "Device phone",
        accessor: "devicePhone",
        sortType: "basic",
      },
      // {
      //   Header: "License",
      //   accessor: "license",
      //   sortType: "basic",
      // },
      {
        Header: "Subscription",
        accessor: "subscription.name",
        sortType: "basic",
        Cell: (props) => {
          if (!props.row.original.subscription) return "Null";
          const type = props.row.original.subscription.name;
          return (
            <Badge
              color={
                type === "Mini"
                  ? "yellow"
                  : type === "Regular"
                  ? "pink"
                  : type === "Xl"
                  ? "violet"
                  : "green"
              }
            >
              {type}
            </Badge>
          );
        },
      },
      {
        Header: "End date",
        accessor: "subscription.endAt",
        sortType: "basic",
        Cell: (props) => {
          if (
            !props.row.original.subscription ||
            !props.row.original.subscription.endAt
          )
            return null;
          const time = moment(props.row.original.subscription.endAt);
          const endSoon = time.diff(moment(), "days") < 8;

          return (
            <Text size="sm" color={endSoon ? "red" : "white"}>
              {time.format("ll")}
            </Text>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      //   defaultColumn,
      //   filterTypes,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    selectedFlatRows,
    state: { selectedRowIds },
    state,
  } = tableInstance;

  const IndeterminateCheckbox = React.forwardRef(
    // @ts-ignore
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        // @ts-ignore
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          {/* @ts-ignore */}
          <Checkbox color="indigo" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const handleCreate = (vehicle: Vehicle) => {
    setData([vehicle, ...data]);
  };

  const handleUpdate = (newData, selected) => {
    const modified = data.map((d) => {
      if (selected.find((s) => s === d._id)) {
        return { ...d, ...newData };
      } else return d;
    });

    setData(modified);
  };

  return (
    <Grid gutter={0} style={{ height: "calc(100vh - 55px)" }}>
      <NewVehicleDrawer
        visible={newForm}
        onClose={() => setNewForm(false)}
        companyId={id}
        onUpdate={handleCreate}
        initialData={undefined}
      />

      <SubscriptionUpdateForm
        visible={updateTogether}
        onClose={() => setUpdateTogether(false)}
        selection={selectedFlatRows.map((i) => i.original._id)}
        onUpdate={handleUpdate}
      />
      <Grid.Col span={8}>
        <Box p="xl">
          <Group position="apart">
            <Title order={3}>{`Vehicles`}</Title>
            <Group>
              {selectedFlatRows.length > 0 && (
                <Button
                  leftIcon={<Tools size={16} />}
                  radius="xl"
                  color={"green"}
                  size="xs"
                  onClick={() => setUpdateTogether(true)}
                >
                  Update selected together
                </Button>
              )}
              <Button
                leftIcon={<Plus size={16} />}
                radius="xl"
                color={"indigo"}
                size="xs"
                onClick={() => setNewForm(true)}
              >
                New vehicle
              </Button>
            </Group>
          </Group>

          <GlobalSearch
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={`Search ${preGlobalFilteredRows.length} vehicles..`}
          />
          {loading && (
            <Group position="center" style={{ width: "100%" }} py="xl">
              <Loader variant="bars" color={theme.colors.secondary[0]} />
            </Group>
          )}
          {!loading && (
            <div
              style={{
                maxHeight: "calc(100vh - 250px)",
                overflow: "scroll",
                border: `1px solid ${theme.colors.border[0]}`,
                borderRadius: 5,
              }}
            >
              <Table highlightOnHover>
                <table {...getTableProps()} style={{ width: "100%" }}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: theme.colors.background[1],
                        }}
                      >
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              <Group>
                                {column.render("Header")}
                                {column.id !== "selection" &&
                                  (column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <SortDescending2
                                        size={15}
                                        color={
                                          column.isSorted
                                            ? theme.colors.secondary[0]
                                            : theme.colors.white[0]
                                        }
                                      />
                                    ) : (
                                      <SortAscending2
                                        size={15}
                                        color={
                                          column.isSorted
                                            ? theme.colors.secondary[0]
                                            : theme.colors.white[0]
                                        }
                                      />
                                    )
                                  ) : (
                                    <ArrowsSort size={15} />
                                  ))}
                              </Group>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.length === 0 ? (
                      <Text p="md" size="sm">
                        No Data
                      </Text>
                    ) : (
                      rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  onClick={() => {
                                    if (cell.column.id !== "selection")
                                      navigate(`${row.original._id}`);
                                  }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </Table>
            </div>
          )}
        </Box>
      </Grid.Col>

      <Grid.Col span={4}>
        <Box
          p="xl"
          style={{
            // backgroundColor: theme.colors.background[1],
            borderLeft: `1px solid ${theme.colors.border[0]}`,
            height: "100%",
          }}
        >
          {isDetail ? <Outlet /> : <CompanyProfile />}
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default CompanyVehicles;

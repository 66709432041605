import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Coordinate } from "../model/Coordinate";
import { Loader } from "@mantine/core";
import logService from "../api/logService";
import Geocode from "react-geocode";

const API_KEY = "AIzaSyC7f4UzSDBN4tW-XCg2TicqytNEBc4OGik";

Geocode.setApiKey(API_KEY);
Geocode.setLocationType("APPROXIMATE");

interface PlaceTextProps {
  coords: Coordinate;
  style?: object;
}

const PlaceText: React.FC<PlaceTextProps> = ({ coords, style, children }) => {
  const [prevCoords, setPrevCoords] = useState(coords);
  const [place, setPlace] = useState<string>();
  const [lastFetched, setLastFetched] = useState<dayjs.Dayjs>();
  const expiryInMinutes = 5;

  useEffect(() => {
    if (!lastFetched) {
      if (coords) getPlace();
      return;
    }

    if (
      prevCoords.latitude !== coords.latitude ||
      prevCoords.longitude !== coords.longitude
    ) {
      if (coords) getPlace();
      return;
    }
    setPrevCoords(coords);

    const now = dayjs();
    if (lastFetched && now.diff(lastFetched, "minute") > expiryInMinutes) {
      if (coords) getPlace();
      return;
    }
  }, [coords]);

  const getPlace = async () => {
    setPlace(undefined);
    try {
      const response = await Geocode.fromLatLng(
        coords.latitude,
        coords.longitude
      );
      setPlace(
        response.results[0]
          ? response.results[0].formatted_address
          : "No location data"
      );
      const now = dayjs();
      setLastFetched(now);
    } catch (error) {
      logService.log(error);
      console.log(error);
    }
  };

  return (
    <div style={style}>
      {place ? (
        //  @ts-ignore
        children(place)
      ) : (
        <Loader variant="dots" color="indigo" size={"sm"} />
      )}
    </div>
  );
};

export default PlaceText;

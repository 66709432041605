import React, { useEffect, useMemo, useRef, useState } from "react";
import mapStyle, { defaultCoords } from "../config/mapStyle";
import MapMarker from "../components/MapMarker";
import { Center, Group, SegmentedControl, Box, Select } from "@mantine/core";
import VehicleSummaryBox from "../components/VehicleSummaryBox";
import { useMantineTheme } from "@mantine/core";
import { Bulb, BulbOff, Eye, Search } from "tabler-icons-react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl";
import { routeColors } from "../config/theme";
import { useSelector } from "react-redux";
import {
  getAllVehicles,
  getOffVehicles,
  getOnVehicles,
  getVehicle,
} from "../store/vehicles";
import { Vehicle } from "../model/Vehicle";
import { Coordinate } from "../model/Coordinate";
import { StopPoint } from "../model/Record";
import RouteMarker, { MarkerType } from "../components/RouteMarker";
import AppLoadingOverlay from "../components/AppLoadingOverlay";
import { i18n } from "../config/translation";
import moment from "moment";

interface MapListProps {}

const MapList: React.FC<MapListProps> = ({}) => {
  const [currentIMEI, setCurrentIMEI] = useState<string>();
  const mapRef = useRef<MapRef>();
  const [currentFilter, setCurrentFilter] = useState("All");
  const [routeData, setRouteData] = useState<number[][] | undefined>();
  const [routeInfo, setRouteInfo] = useState<StopPoint[] | undefined>();
  const [loaded, setLoaded] = useState(false);

  const vehicles = useSelector<Vehicle[]>(
    currentIMEI
      ? (state) => [getVehicle(state, currentIMEI)]
      : currentFilter === "All"
      ? getAllVehicles
      : currentFilter === "On"
      ? getOnVehicles
      : getOffVehicles
  );
  const theme = useMantineTheme();
  const API_KEY =
    "pk.eyJ1IjoiaHlleWVhaCIsImEiOiJjbDUzcHl3YWgwOHc2M2twb3V2bnBhbmZrIn0.THwmy8ODR-5qavK87Nwv6w";

  useEffect(() => {
    /* @ts-ignore */
    if (vehicles) fitToBounds(vehicles);
    /* @ts-ignore */
  }, [vehicles.length]);

  const applyToArray = (func, array) => func.apply(Math, array);

  const toDefaultLocation = () => {
    mapRef.current &&
      mapRef.current.fitBounds([38.99404, -6.579978, 39.53649, -6.958068], {
        padding: 200,
        duration: 500,
      });
  };

  const fitToBounds = (points: Vehicle[]) => {
    if (points.length === 0) {
      toDefaultLocation();
      return;
    }
    if (points.length === 1) {
      mapRef.current &&
        mapRef.current.flyTo({
          center: [
            points[0].latestRecord.longitude,
            points[0].latestRecord.latitude,
          ],
          essential: true,
          zoom: 10.5,
          duration: 500,
        });
      return;
    }

    const pointsLong = points.map((point) => point.latestRecord.longitude);
    const pointsLat = points.map((point) => point.latestRecord.latitude);
    const cornersLongLat: [number, number][] = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];

    mapRef.current &&
      mapRef.current.fitBounds(
        [
          cornersLongLat[0][0],
          cornersLongLat[0][1],
          cornersLongLat[1][0],
          cornersLongLat[1][1],
        ],
        {
          padding: 200,
          duration: 500,
        }
      );
  };

  const fitToRoute = (points: Coordinate[]) => {
    if (points.length === 0) {
      return;
    }

    const pointsLong = points.map((point) => point.longitude);
    const pointsLat = points.map((point) => point.latitude);
    const cornersLongLat: [number, number][] = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];
    mapRef.current.fitBounds(
      [
        cornersLongLat[0][0],
        cornersLongLat[0][1],
        cornersLongLat[1][0],
        cornersLongLat[1][1],
      ],
      {
        padding: {
          left: 500,
          right: 150,
          top: 200,
          bottom: 200,
        },
        duration: 500,
      }
    );
  };

  const fitToCar = (coords: Coordinate) => {
    mapRef.current.flyTo({
      center: [coords.longitude - 0.02, coords.latitude],
      essential: true,
      zoom: 13,
      duration: 500,
    });
  };

  const segmentData = [
    {
      value: "All",
      label: (
        <Center>
          <Eye size={16} />
          <Box ml={"xs"}>{i18n.t("all")}</Box>
        </Center>
      ),
    },
    {
      value: "On",
      label: (
        <Center>
          <Bulb size={18} />
          <Box ml={"xs"}>{i18n.t("on")}</Box>
        </Center>
      ),
    },
    {
      value: "Off",
      label: (
        <Center>
          <BulbOff size={18} />
          <Box ml={"xs"}>{i18n.t("off")}</Box>
        </Center>
      ),
    },
  ];

  const handleFilter = (filter) => {
    setRouteData(undefined);
    setCurrentIMEI(undefined);
    setRouteInfo(undefined);
    setCurrentFilter(filter);
  };

  const handleClickMarker = (imei: string) => {
    /* @ts-ignore */
    const vehicle = vehicles.find((v) => v.deviceIMEI === imei);

    fitToCar({
      latitude: vehicle.latestRecord.latitude,
      longitude: vehicle.latestRecord.longitude,
    });

    setCurrentIMEI(vehicle.deviceIMEI);
  };

  const handleSearch = (value: string) => {
    handleFilter("All");

    /* @ts-ignore */
    setCurrentIMEI(value);

    if (!value) {
      /* @ts-ignore */
      fitToBounds(vehicles);
    }

    handleClickMarker(value);
  };

  const handleRoute = (routeData: Coordinate[]) => {
    if (!routeData) {
      setRouteData(undefined);
      /* @ts-ignore */
      const car = vehicles.find((v) => v.deviceIMEI === currentIMEI);
      fitToCar({
        latitude: car.latestRecord.latitude,
        longitude: car.latestRecord.longitude,
      });
      return;
    }
    setRouteData(routeData.map((r) => [r.longitude, r.latitude]));
    fitToRoute(routeData);
  };

  const onPressRouteInfo = (coords: Coordinate) => {
    fitToCar(coords);
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        position: "relative",
      }}
    >
      <AppLoadingOverlay loading={!loaded} />
      <Map
        ref={mapRef}
        mapboxAccessToken={API_KEY}
        initialViewState={{
          longitude: defaultCoords.longitude,
          latitude: defaultCoords.latitude,
          zoom: 5,
          bearing: 0,
          pitch: 0,
        }}
        style={{ width: "100%", height: "100vh" }}
        mapStyle="mapbox://styles/hyeyeah/cl53rdrq2001o15s79uolijli"
        onLoad={() => {
          /* @ts-ignore */
          if (vehicles) fitToBounds(vehicles);
          setLoaded(true);
        }}
      >
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <NavigationControl position="top-right" />
        <ScaleControl position="bottom-right" />
        {routeData && (
          <Source
            id="polylineLayer"
            type="geojson"
            data={{
              type: "Feature",
              properties: {
                color: theme.colors.secondary[0],
              },
              geometry: {
                type: "LineString",
                coordinates: routeData,
              },
            }}
          >
            <Layer
              id="polylineLayer"
              type="line"
              source="my-data"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": ["get", "color"],
                "line-width": 5,
                "line-opacity": 1,
              }}
            />
          </Source>
        )}
        {routeInfo &&
          routeInfo.map((item, index) => (
            <Marker
              key={index.toString()}
              longitude={item.coords.longitude}
              latitude={item.coords.latitude}
              // onClick={(e) => {
              //   e.originalEvent.stopPropagation();
              //   handleClickMarker(item.deviceIMEI);
              // }}
            >
              <RouteMarker
                type={
                  item.type === "start"
                    ? MarkerType.start
                    : item.type === "end"
                    ? MarkerType.end
                    : MarkerType.park
                }
              />
            </Marker>
          ))}

        {!routeData &&
          vehicles
            /* @ts-ignore */
            .filter(
              (item) =>
                item.subscription && moment(item.subscription.endAt) >= moment()
            )
            .map((item, index) => (
              <Marker
                key={item._id}
                longitude={item.latestRecord.longitude}
                latitude={item.latestRecord.latitude}
                anchor="bottom"
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  handleClickMarker(item.deviceIMEI);
                }}
              >
                <MapMarker
                  data={item}
                  key={item._id}
                  showTitle
                  angle={item.latestRecord.angle}
                  type={item.vehicleType}
                />
              </Marker>
            ))}
      </Map>

      <VehicleSummaryBox
        onClose={() => {
          setRouteData(undefined);
          setRouteInfo(undefined);
          setCurrentIMEI(undefined);
          /* @ts-ignore */
          fitToBounds(vehicles);
          handleFilter("All");
        }}
        visible={currentIMEI ? true : false}
        /* @ts-ignore */
        currentVehicle={vehicles.find((v) => v.deviceIMEI === currentIMEI)}
        setRouteData={handleRoute}
        setRouteInfo={setRouteInfo}
        routeInfo={routeInfo}
        onPressRouteInfo={onPressRouteInfo}
      />

      <Group
        pt={2.5}
        sx={(theme) => ({
          position: "fixed",
          top: 10,
          right: 60,
        })}
        align="center"
        spacing={"xs"}
      >
        <Select
          placeholder={i18n.t("searchVehicle")}
          searchable
          clearable
          nothingFound={i18n.t("noResult")}
          data={vehicles
            /* @ts-ignore */
            .filter(
              (item) =>
                item.subscription && moment(item.subscription.endAt) >= moment()
            )
            .map((v) => ({ label: v.name, value: v.deviceIMEI }))}
          icon={<Search size={14} color={theme.white} />}
          radius="xl"
          value={currentIMEI}
          onChange={handleSearch}
          styles={{
            input: {
              backgroundColor: theme.colors.background[0],
              border: `1px solid ${theme.colors.border[0]}`,
              color: theme.white,
              "&::placeholder": {
                color: theme.colors.grey,
              },
            },
          }}
        />

        <SegmentedControl
          defaultValue="All"
          value={currentFilter}
          data={segmentData}
          color="indigo"
          radius={"xl"}
          sx={(theme) => ({
            backgroundColor: theme.colors.background[0],
            border: `1px solid ${theme.colors.border[0]}`,
          })}
          onChange={handleFilter}
        />
      </Group>
    </div>
  );
};

export default MapList;

import {
  Button,
  Drawer,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import logService from "../api/logService";
import notificationService from "../api/notificationService";
import { i18n } from "../config/translation";
import { Notification } from "../model/Notification";
import AppLoadingOverlay from "./AppLoadingOverlay";
import NotificationCard from "./NotificationCard";
import { useDispatch, useSelector } from "react-redux";
import { addCount, getCount } from "../store/notifications";
import { Bell } from "tabler-icons-react";

interface NotificationDrawerProps {
  visible?: boolean;
  onClose?: () => void;
}

const NotificationDrawer: React.FC<NotificationDrawerProps> = ({
  visible,
  onClose,
}) => {
  const theme = useMantineTheme();
  const [data, setData] = useState<Notification[]>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const count = useSelector(getCount);
  const [loadMoreVisible, setLoadMoreVisible] = useState(true);

  useEffect(() => {
    if (visible) getData(0);
  }, [visible]);

  const getData = async (skip) => {
    setLoading(true);
    try {
      const result = await notificationService.getNotifications(skip);
      setData(
        skip === 0
          ? result.data.notifications
          : [...data, ...result.data.notifications]
      );
      dispatch(addCount(result.data.count));
      if (result.data.notifications.length < 20) setLoadMoreVisible(false);
    } catch (ex) {
      if (ex.response.status === 400 || ex.response.status === 404) {
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      }
      logService.log(ex);
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    try {
      await notificationService.deleteNotification(id);
      setData(data.filter((item) => item._id !== id));
      //@ts-ignore
      dispatch(addCount(count - 1));
    } catch (ex) {
      if (ex.response.status === 400 || ex.response.status === 404) {
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      }
      logService.log(ex);
    }
    setLoading(false);
  };

  return (
    <Drawer
      position="right"
      opened={visible}
      onClose={onClose}
      title={i18n.t("notifications")}
      size="lg"
      styles={{
        drawer: {
          backgroundColor: theme.colors.background[0],
          borderLeft: `1px solid ${theme.colors.border[0]}`,
        },
        header: {
          padding: theme.spacing.xl,
          paddingBottom: 0,
        },
        title: {
          fontSize: theme.fontSizes.lg,
          fontWeight: "bold",
        },
      }}
    >
      <ScrollArea
        style={{ height: "calc(100vh - 90px)", position: "relative" }}
        scrollbarSize={4}
        type="scroll"
      >
        <AppLoadingOverlay loading={loading} />
        <Stack style={{ overflow: "visible" }} px="xl" pt="sm">
          {data && !data.length && (
            <Stack align="center" justify="center" style={{ height: "80vh" }}>
              <Bell color={theme.colors.primary[0]} />
              <Text>No notification</Text>
            </Stack>
          )}
          {data &&
            data.map((item, index) => (
              <NotificationCard
                item={item}
                key={index.toString()}
                onDelete={handleDelete}
              />
            ))}
          {loadMoreVisible && (
            <Button
              variant="subtle"
              color="indigo"
              onClick={() => getData(data.length)}
            >
              Load more
            </Button>
          )}
        </Stack>
      </ScrollArea>
    </Drawer>
  );
};

export default NotificationDrawer;

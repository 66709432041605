enum RouteList {
  AUTH_LOGIN = "login",
  NOT_AUTHORIZED = "401",
  NOT_FOUNT = "404",

  DASH_MAIN = "dashboard",
  DASH_MAP = "map",

  STATS_MAIN = "statistics",
  STATS_DETAIL = ":id",
  STATS_TYPE = ":type",

  VEHICLE_LIST = "vehicles",
  VEHICLE_DETAIL = ":id",

  DRIVER_LIST = "drivers",

  MANAGER_LIST = "managers",

  MANAGEMENT_MAIN = "management",
  MANAGEMENT_COMPANIES = "companies",
  MANAGEMENT_VEHICLES = "vehicleList",

  MANAGEMENT_COMPANY_DETAIL = "company/:id",
  MANAGEMENT_COMPANY_VEHICLES = "vehicles",
  MANAGEMENT_COMPANY_VEHICLE_DETAIL = ":id",
  MANAGEMENT_COMPANY_ACCOUNT = "accounts",
  MANAGEMENT_COMPANY_ACCOUNT_DETAIL = ":id",
}

export default RouteList;

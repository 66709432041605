import { I18n } from "i18n-js";
import moment from "moment";
import "moment/locale/sw";
import "moment/locale/en-gb";
import en from "./en.json";
import sw from "./sw.json";

export const i18n = new I18n({
  en,
  sw,
});

i18n.defaultLocale = "en";
i18n.enableFallback = true;

export const setLanguage = (language: string) => {
  i18n.locale = language ?? "en";
  moment.locale(language === "sw" ? "sw" : "en-gb");
  localStorage.setItem("language", language ?? "en");
};

import { Grid, LoadingOverlay } from "@mantine/core";
import React, { useEffect, useState } from "react";

import TimeGraph from "../components/TimeGraph";
import DailyTime from "../components/DailyTime";

interface TimeStatiscticsProps {}

const TimeStatisctics: React.FC<TimeStatiscticsProps> = ({}) => {
  return (
    <Grid style={{ position: "relative", minHeight: "80vh" }}>
      <Grid.Col span={8}>
        <TimeGraph />
      </Grid.Col>
      <Grid.Col span={4}>
        <DailyTime />
      </Grid.Col>
    </Grid>
  );
};

export default TimeStatisctics;

import { ActionIcon, Box, Group, useMantineTheme } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import React, { useState } from "react";
import { Search } from "tabler-icons-react";

interface AppDateTimePickerProps {
  placeholder?: string;
  label?: string;
  required?: boolean;
  value: Date;
  onChange: (Date) => void;
  color?: string;
  onClick: () => void;
}

const AppDateTimePicker: React.FC<AppDateTimePickerProps> = ({
  placeholder,
  label,
  required,
  value,
  onChange,
  color,
  onClick,
}) => {
  const theme = useMantineTheme();
  const minDate = new Date();
  minDate.setMonth(minDate.getMonth() - 1);

  return (
    <Group
      sx={(theme) => ({
        backgroundColor: color ? color : theme.colors.background[0],
        fontWeight: 400,
        height: 33,
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.border[0]}`,
      })}
      spacing={0}
      pr={5}
    >
      <DatePicker
        placeholder={placeholder}
        label={label}
        variant="unstyled"
        required={required}
        value={value}
        onChange={(date) => {
          const newDate = value;
          newDate.setFullYear(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          );
          onChange(newDate);
        }}
        size="xs"
        clearable={false}
        inputFormat={"MMM D"}
        maxDate={new Date()}
        minDate={minDate}
        firstDayOfWeek="sunday"
        initialLevel={"date"}
        styles={{
          input: {
            color: theme.white,
            width: 70,
            textAlign: "center",
          },
          dropdown: {
            backgroundColor: theme.colors.background[0],
            border: `1px solid ${theme.colors.border[0]}`,
          },
          day: {
            "&:hover": {
              backgroundColor: theme.colors.dark[8],
            },
          },
          selected: {
            backgroundColor: theme.colors.background[1],
          },
        }}
      />
      <TimeInput
        size="xs"
        placeholder="Pick time"
        defaultValue={value}
        onChange={(date) => {
          const newDate = value;
          newDate.setHours(date.getHours(), date.getMinutes());
          onChange(newDate);
        }}
        value={value}
        format="12"
        styles={{
          input: {
            color: theme.white,
            width: 85,
            textAlign: "center",
            background: "transparent",
          },
        }}
      />
      <ActionIcon
        color={"indigo"}
        variant="filled"
        radius={"md"}
        onClick={onClick}
      >
        <Search size={16} />
      </ActionIcon>
    </Group>
  );
};

export default AppDateTimePicker;

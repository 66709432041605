import { combineReducers } from "redux";
import { PayloadAction } from "@reduxjs/toolkit";

import userReducers, { userLoggedOut } from "./user";
import uiControlsReducers from "./uiControls";
import companyReducers from "./company";
import vehiclesReducers from "./vehicles";
import notificationsReducers from "./notifications";

const appReducer = combineReducers({
  user: userReducers,
  uiControls: uiControlsReducers,
  company: companyReducers,
  vehicles: vehiclesReducers,
  notifications: notificationsReducers,
});

export default (state: RootState, action: PayloadAction) => {
  if (action.type === userLoggedOut.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

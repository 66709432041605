import {
  Avatar,
  Badge,
  Box,
  Button,
  CloseButton,
  Container,
  Group,
  Loader,
  LoadingOverlay,
  Pagination,
  Radio,
  RadioGroup,
  Table,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ArrowsSort,
  Plus,
  Search,
  SortAscending2,
  SortDescending2,
} from "tabler-icons-react";
import {
  useTable,
  useSortBy,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";
import { getHeight } from "../../utility/getWindowSize";
import { useNavigate } from "react-router-dom";
import NewCompanyForm from "../../components/NewCompanyForm";
import GlobalSearch from "../../components/GlobalSearch";
import companyService from "../../api/companyService";
import logService from "../../api/logService";
import { Company } from "../../model/Company";
import { CompanyContext } from "../../context/CompanyContext";

interface CampanyListProps {}

const CampanyList: React.FC<CampanyListProps> = ({}) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [newForm, setNewForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await companyService.getCompanies();
      const companyData = [...result.data.companies];

      result.data.vehicleCount.forEach((v) => {
        const companyIndex = companyData.findIndex((c) => c._id === v._id);
        companyData[companyIndex] = {
          ...companyData[companyIndex],
          vehicles: v.count,
        };
      });
      setData(companyData);
      setLoading(false);
    } catch (ex) {
      logService.log(ex);
    }
  };

  const handleCreateCompany = (company: Company) => {
    setData([company, ...data]);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        Cell: (props) => (
          <Group spacing={"xs"}>
            <Avatar
              src={props.row.original.avatar}
              radius="xl"
              color={"indigo"}
              size="sm"
            >
              {props.row.original.name[0].toUpperCase()}
            </Avatar>
            <Text size="sm">{props.row.original.name}</Text>
          </Group>
        ),
      },

      {
        Header: "Vehicles",
        accessor: "vehicles",
        sortType: "basic",
        Cell: (props) => props.row.original.vehicles ?? 0,
      },
      {
        Header: "Operating",
        accessor: "isOperating",
        sortType: "basic",
        Cell: (props) => (
          <Badge color={props.row.original.isOperating ? "yellow" : "gray"}>
            {props.row.original.isOperating ? "Yes" : "No"}
          </Badge>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      //   defaultColumn,
      //   filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: Math.floor((getHeight() - 180) / 75),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    pageOptions,
    gotoPage,
    state,
  } = tableInstance;

  return (
    <Container py="xl" style={{ height: "100%" }}>
      <NewCompanyForm
        visible={newForm}
        onClose={() => setNewForm(false)}
        onUpdate={handleCreateCompany}
        initialData={undefined}
      />

      <Group position="apart">
        <Title order={3}>Companies</Title>
        <Button
          leftIcon={<Plus />}
          radius="xl"
          color={"indigo"}
          size="xs"
          onClick={() => setNewForm(true)}
        >
          New company
        </Button>
      </Group>

      <GlobalSearch
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        placeholder="Search companies..."
      />

      {loading && (
        <Group position="center" style={{ width: "100%" }} py="xl">
          <Loader variant="bars" color={theme.colors.secondary[0]} />
        </Group>
      )}
      {!loading && (
        <Table highlightOnHover>
          <table {...getTableProps()} style={{ width: "100%" }}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Group>
                        {column.render("Header")}

                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDescending2
                              size={15}
                              color={
                                column.isSorted
                                  ? theme.colors.secondary[0]
                                  : theme.colors.white[0]
                              }
                            />
                          ) : (
                            <SortAscending2
                              size={15}
                              color={
                                column.isSorted
                                  ? theme.colors.secondary[0]
                                  : theme.colors.white[0]
                              }
                            />
                          )
                        ) : (
                          <ArrowsSort size={15} />
                        )}
                      </Group>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() =>
                      navigate(`../company/${row.original._id}/vehicles`, {
                        state: { name: row.original.name },
                      })
                    }
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Table>
      )}

      <Group position="center" pt="xl">
        {/* <Group spacing={"xs"}>
          <Group>
            <Text size="sm"> Go to page: </Text>
            <TextInput
              size="sm"
              type="number"
              defaultValue={state.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              styles={{
                input: {
                  backgroundColor: theme.colors.background[1],
                  width: "100px",
                },
              }}
            />
          </Group>
        </Group> */}

        <Pagination
          total={pageOptions.length}
          color="indigo"
          page={state.pageIndex + 1}
          onChange={(page) => gotoPage(page - 1)}
          styles={{
            item: {
              backgroundColor: theme.colors.background[1],
              border: `0.5px solid ${theme.colors.border[0]}`,
            },
          }}
        />

        {/* <Group spacing={"xs"}>
          <ActionIcon
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            color="indigo"
            variant="filled"
          >
            <ChevronsLeft size={16} />
          </ActionIcon>
          <ActionIcon
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            color="indigo"
            variant="filled"
          >
            <ChevronLeft size={16} />
          </ActionIcon>

          <Text size="sm">
            Page{" "}
            <strong>
              {state.pageIndex + 1} of {pageOptions.length}
            </strong>
          </Text>

          <ActionIcon
            onClick={() => nextPage()}
            disabled={!canNextPage}
            color="indigo"
            variant="filled"
          >
            <ChevronRight size={16} />
          </ActionIcon>
          <ActionIcon
            color="indigo"
            variant="filled"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <ChevronsRight size={16} />
          </ActionIcon>
        </Group> */}
      </Group>
    </Container>
  );
};

export default CampanyList;

import {
  Box,
  Group,
  ScrollArea,
  SimpleGrid,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  FullscreenControl,
  GeolocateControl,
  Map,
  MapRef,
  Marker,
  NavigationControl,
} from "react-map-gl";
import { useParams } from "react-router-dom";
import { MapPin } from "tabler-icons-react";
import logService from "../api/logService";
import recordService from "../api/recordService";

import { defaultCoords } from "../config/mapStyle";
import { i18n } from "../config/translation";
import { Coordinate } from "../model/Coordinate";
import { Vehicle } from "../model/Vehicle";
import { getDistance, getHours } from "../utility/recordFormatter";
import AppDateTimePicker from "./AppDateTimePicker";
import AppLoadingOverlay from "./AppLoadingOverlay";
import Empty from "./Empty";
import MapMarker from "./MapMarker";
import NetworkError from "./NetworkError";
import PlaceText from "./PlaceText";
import StatsBox, { StatType } from "./StatsBox";

interface VehicleLocationMapProps {
  vehicle: Vehicle;
}

const API_KEY =
  "pk.eyJ1IjoiaHlleWVhaCIsImEiOiJjbDUzcHl3YWgwOHc2M2twb3V2bnBhbmZrIn0.THwmy8ODR-5qavK87Nwv6w";

const VehicleLocationMap: React.FC<VehicleLocationMapProps> = ({ vehicle }) => {
  const mapRef = useRef<MapRef>();
  const theme = useMantineTheme();
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState(vehicle);
  const [networkError, setNetworkError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setDate(new Date());
    if (mapRef.current) {
      if (!vehicle.latestRecord || !vehicle.initialRecord)
        return setNoData(true);
      fitToCar({
        latitude: vehicle.latestRecord.latitude,
        longitude: vehicle.latestRecord.longitude,
      });
      setData(vehicle);
    }
  }, [id]);

  const fitToCar = (coords: Coordinate) => {
    mapRef.current.flyTo({
      center: [coords.longitude, coords.latitude],
      essential: true,
      zoom: 13,
      duration: 500,
    });
  };

  const handleSearch = async () => {
    setLoading(true);
    setNetworkError(false);
    setNoData(false);

    try {
      const result = await recordService.getRecordForSpecificTime(
        vehicle.deviceIMEI,
        date
      );
      const { latestRecord, initialRecord } = result.data;

      if (!latestRecord || !initialRecord) {
        setLoading(false);
        setNoData(true);
        return;
      }

      const copied = { ...data };
      copied.initialRecord = result.data.initialRecord;
      copied.latestRecord = result.data.latestRecord;

      setData(copied);
      fitToCar({
        latitude: latestRecord.latitude,
        longitude: latestRecord.longitude,
      });
    } catch (error) {
      showNotification({
        title: i18n.t("somethingWrong"),
        message: i18n.t("tryAgain"),
        color: "red",
      });
      setNetworkError(true);
      logService.log(error);
    }
    setLoading(false);
  };

  return (
    <Box pt="sm" style={{ position: "relative" }}>
      <AppLoadingOverlay loading={loading} />
      <Group style={{ position: "absolute", zIndex: 1 }} mt="sm" ml="sm">
        <AppDateTimePicker
          color={theme.colors.primary[0]}
          onChange={setDate}
          value={date}
          onClick={handleSearch}
        />
      </Group>
      <Map
        ref={mapRef}
        mapboxAccessToken={API_KEY}
        initialViewState={{
          longitude: defaultCoords.longitude,
          latitude: defaultCoords.latitude,
          zoom: 10.5,
          bearing: 0,
          pitch: 0,
        }}
        style={{
          width: "100%",
          height: "calc(100vh - 120px)",
          border: `1px solid ${theme.colors.border[0]}`,
          borderRadius: 7,
        }}
        onLoad={() => {
          if (!data.latestRecord || !data.initialRecord) return setNoData(true);

          fitToCar({
            latitude: data.latestRecord.latitude,
            longitude: data.latestRecord.longitude,
          });
        }}
        mapStyle="mapbox://styles/hyeyeah/cl53rdrq2001o15s79uolijli"
      >
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <NavigationControl position="top-right" />
        {!noData && (
          <Marker
            longitude={data.latestRecord.longitude}
            latitude={data.latestRecord.latitude}
            anchor="bottom"
          >
            <MapMarker
              data={data}
              key={data._id}
              angle={data.latestRecord.angle}
              type={data.vehicleType}
            />
          </Marker>
        )}
      </Map>
      <ScrollArea
        p="md"
        m="md"
        pb="xs"
        style={{
          backgroundColor: theme.colors.background[0],
          position: "absolute",
          bottom: 0,
          width: "95%",
          alignSelf: "center",
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.border}`,
        }}
      >
        <Group noWrap align={"flex-start"} spacing="xs">
          {networkError ? (
            <NetworkError />
          ) : noData ? (
            <Empty />
          ) : (
            <>
              <Box
                p="md"
                sx={(theme) => ({
                  backgroundColor: theme.colors.background[1],
                  borderRadius: 10,
                  minHeight: 100,
                  border: `1px solid ${theme.colors.border[0]}`,
                })}
              >
                <Group spacing={3} noWrap pb="xs">
                  <MapPin
                    size={14}
                    color={
                      data.latestRecord.ignition === "Ignition On"
                        ? theme.colors.secondary[0]
                        : theme.colors.dark[3]
                    }
                  />

                  <Text size={"xs"}>{i18n.t("location")}</Text>
                </Group>
                <PlaceText
                  coords={{
                    latitude: data.latestRecord.latitude,
                    longitude: data.latestRecord.longitude,
                  }}
                >
                  {(text) => (
                    <Text
                      size="xs"
                      align="center"
                      color={
                        data.latestRecord.ignition === "Ignition On"
                          ? theme.white
                          : theme.colors.dark[3]
                      }
                    >
                      {text}
                    </Text>
                  )}
                </PlaceText>
              </Box>

              <StatsBox
                small
                type={StatType.speed}
                data={data.latestRecord.speed}
                isOn={data.latestRecord.ignition === "Ignition On"}
                style={{ minHeight: 100 }}
              />
              <StatsBox
                small
                type={StatType.distance}
                data={getDistance(data)}
                isOn={data.latestRecord.ignition === "Ignition On"}
                style={{ minHeight: 100 }}
              />
              <StatsBox
                small
                type={StatType.time}
                data={getHours(data)}
                isOn={data.latestRecord.ignition === "Ignition On"}
                style={{ minHeight: 100 }}
              />
            </>
          )}
        </Group>
        {!networkError && !noData && (
          <Text size="xs" color="dimmed" pt="xs">{`${i18n.t(
            "actualTime"
          )}:${moment(data.latestRecord.timestamp).format(
            "Do MMMM YYYY, h:mm:ss A"
          )}`}</Text>
        )}
      </ScrollArea>
    </Box>
  );
};

export default VehicleLocationMap;

import { CloseButton, Popover, Stack, useMantineTheme } from "@mantine/core";
import {
  Avatar,
  Box,
  Button,
  Container,
  Group,
  Tabs,
  Text,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MapPin, Phone } from "tabler-icons-react";
import logService from "../api/logService";
import recordService from "../api/recordService";
import { i18n } from "../config/translation";
import { Coordinate } from "../model/Coordinate";
import { StopPoint } from "../model/Record";
import { Vehicle } from "../model/Vehicle";
import AppLoadingOverlay from "./AppLoadingOverlay";
import ChangeDriverDrawer from "./ChangeDriverDrawer";
import PlaceText from "./PlaceText";
import StatusSign, { StatusSize } from "./StatusSign";
import SummaryBoxLive from "./SummaryBoxLive";
import VehicleSummaryTimeline from "./VehicleSummaryTimeline";

interface VehicleSummaryBoxProps {
  onClose?: () => void;
  visible: boolean;
  currentVehicle?: Vehicle;
  setRouteData: (item: Coordinate[]) => void;
  setRouteInfo: (item: StopPoint[]) => void;
  routeInfo?: StopPoint[];
  onPressRouteInfo: (coords: Coordinate) => void;
}

const VehicleSummaryBox: React.FC<VehicleSummaryBoxProps> = ({
  onClose,
  visible,
  currentVehicle,
  setRouteData,
  setRouteInfo,
  routeInfo,
  onPressRouteInfo,
}) => {
  const theme = useMantineTheme();
  const [activeTab, setActiveTab] = useState(0);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changeDriver, setChangeDriver] = useState(false);
  const isOn =
    currentVehicle && currentVehicle.latestRecord.ignition === "Ignition On";
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    setActiveTab(0);
  }, [currentVehicle && currentVehicle._id]);

  const getRouteData = async (deviceIMEI: string, date: Date) => {
    setNetworkError(false);
    setLoading(true);

    try {
      const result = await recordService.getRecordsByDate(
        currentVehicle.deviceIMEI,
        date
      );
      setRouteData(result.data.routeCoords);
      handleRouteInfo(result.data.pointList);
    } catch (error) {
      showNotification({
        title: i18n.t("somethingWrong"),
        message: i18n.t("tryAgain"),
        color: "red",
      });
      setNetworkError(true);
      logService.log(error);
    }

    setLoading(false);
  };

  const handleRouteInfo = (data: StopPoint[]) => {
    let info = [...data];

    if (data.length < 3) return setRouteInfo(info);

    if (
      info[0].coords.latitude.toString() ===
        info[1].coords.latitude.toString() &&
      info[0].coords.longitude.toString() ===
        info[1].coords.longitude.toString()
    ) {
      info.shift();
      info[0] = {
        ...info[0],
        startAt: info[0].endAt,
        endAt: undefined,
        type: "start",
      };
    }

    if (
      info[info.length - 1].coords.latitude.toString() ===
        info[info.length - 2].coords.latitude.toString() &&
      info[info.length - 1].coords.longitude.toString() ===
        info[info.length - 2].coords.longitude.toString()
    ) {
      info.pop();
      info[info.length - 1] = {
        ...info[info.length - 1],
        endAt: undefined,
        type: "end",
      };
    }

    setRouteInfo(info);
  };

  const handleSection = async (section: 0 | 1) => {
    setActiveTab(section);

    if (!section) {
      setRouteData(undefined);
      setRouteInfo(undefined);
    } else {
      getRouteData(currentVehicle.deviceIMEI, moment().toDate());
    }
  };

  const handleDate = (date: Date) => {
    getRouteData(currentVehicle.deviceIMEI, date);
  };

  return visible ? (
    <Container
      m={"sm"}
      px={0}
      sx={(theme) => ({
        position: "absolute",
        top: 0,
        zIndex: 1,
        borderRadius: 10,
        width: "35%",
      })}
    >
      <Container
        p={"md"}
        sx={(theme) => ({
          backgroundColor: theme.colors.background[0],
          borderRadius: 10,
          border: `1px solid ${theme.colors.border[0]}`,
        })}
      >
        <Group
          pb="xs"
          spacing="xs"
          position="right"
          sx={(theme) => ({
            backgroundColor: theme.colors.background[0],
            borderRadius: 0,
          })}
        >
          <CloseButton onClick={onClose} />
        </Group>
        {currentVehicle.driver ? (
          <Group position="apart" align={"flex-start"}>
            <Group>
              <Avatar
                radius="md"
                size="lg"
                /* @ts-ignore */
                src={currentVehicle.driver.avatar}
              />
              <Box
                sx={(theme) => ({
                  margin: "auto 0",
                })}
              >
                {/* @ts-ignore */}
                <Text>{currentVehicle.driver.name}</Text>
                <Group pt={5}>
                  <StatusSign
                    isOn={isOn}
                    size={StatusSize.extraSmall}
                    warn={
                      currentVehicle.hasRelay &&
                      currentVehicle.latestRecord.DigitalOutput1 === 1
                    }
                  />
                  <Text pl={"md"} size="sm">
                    {isOn ? i18n.t("inTransit") : i18n.t("noDriving")}
                  </Text>
                </Group>
              </Box>
            </Group>

            <Popover
              opened={opened}
              onClose={() => setOpened(false)}
              radius="md"
              target={
                <Button
                  size="xs"
                  radius="lg"
                  onClick={() => setOpened((o) => !o)}
                  leftIcon={<Phone size={14} />}
                  sx={(theme) => ({
                    backgroundColor: theme.colors.primary[0],
                  })}
                >
                  {i18n.t("contacts")}
                </Button>
              }
              width={180}
              position="right"
              withArrow
              styles={{
                popover: {
                  backgroundColor: theme.colors.primary[1],
                  // border: `1px solid ${theme.colors.primary[0]}`,
                },
                arrow: {
                  backgroundColor: theme.colors.primary[1],
                  // border: `1px solid ${theme.colors.primary[0]}`,
                },
              }}
            >
              <Stack>
                {/* @ts-ignore */}
                {currentVehicle.driver.phone &&
                  /* @ts-ignore */
                  currentVehicle.driver.phone.map((phone) => (
                    <Group key={phone} spacing="xs">
                      <Phone size={14} color={theme.colors.secondary[0]} />
                      <Text size="sm">{phone}</Text>
                    </Group>
                  ))}
                {/* @ts-ignore */}
                {(!currentVehicle.driver.phone ||
                  /* @ts-ignore */
                  currentVehicle.driver.phone.length === 0) && (
                  <Text size="sm">{i18n.t("noData")}</Text>
                )}
              </Stack>
            </Popover>
          </Group>
        ) : (
          <Group position="apart">
            {changeDriver && (
              <ChangeDriverDrawer
                visible={changeDriver}
                currentDriverId={
                  /* @ts-ignore */
                  currentVehicle.driver ? currentVehicle.driver._id : null
                }
                vehicle={currentVehicle}
                onClose={() => setChangeDriver(false)}
              />
            )}
            <Text color={"dimmed"} size="sm">
              {i18n.t("noAssign")}
            </Text>
            <Button
              color="indigo"
              radius="xl"
              size="xs"
              variant="outline"
              onClick={() => setChangeDriver(true)}
            >
              {i18n.t("assignDriver")}
            </Button>
          </Group>
        )}
        {currentVehicle.hasRelay &&
          currentVehicle.latestRecord.DigitalOutput1 === 1 && (
            <Box style={{ backgroundColor: theme.colors.red[8] }} mt="sm">
              <Text size="sm" style={{ textAlign: "center" }}>
                {`${i18n.t("activated")} ${i18n.t("wontStart")}`}
              </Text>
            </Box>
          )}
        <Group pt={"md"} spacing={"md"} noWrap align={"start"}>
          <Box>
            <Text size="xs" color={theme.colors.dark[2]}>
              {i18n.t("name")}
            </Text>
            <Text size="sm">{currentVehicle.name}</Text>
          </Box>

          <Box>
            <Text size="xs" color={theme.colors.dark[2]}>
              {i18n.t("model")}
            </Text>
            <Text size="sm">{currentVehicle.model}</Text>
          </Box>

          <Box>
            <Text size="xs" color={theme.colors.dark[2]}>
              {i18n.t("location")}
            </Text>
            <Group>
              <PlaceText
                coords={{
                  latitude: currentVehicle.latestRecord.latitude,
                  longitude: currentVehicle.latestRecord.longitude,
                }}
                style={{ minHeight: 45 }}
              >
                {(place) => (
                  <Text size="sm">
                    <MapPin
                      size={12}
                      strokeWidth={2}
                      color={theme.colors.secondary[0]}
                      style={{ marginRight: 5 }}
                    />
                    {place}
                  </Text>
                )}
              </PlaceText>
            </Group>
          </Box>
        </Group>
      </Container>

      <Container
        mt={"sm"}
        px={0}
        sx={(theme) => ({
          backgroundColor: theme.colors.background[1],
          borderRadius: 10,
          border: `1px solid ${theme.colors.border[0]}`,
          position: "relative",
        })}
      >
        <AppLoadingOverlay loading={loading} />
        <Tabs
          color={"indigo"}
          sx={(theme) => ({
            backgroundColor: theme.colors.background[0],
            borderRadius: 10,
            overflow: "hidden",
          })}
          active={activeTab}
          onTabChange={handleSection}
          tabPadding={0}
        >
          <Tabs.Tab label={i18n.t("liveData")}>
            <SummaryBoxLive currentVehicle={currentVehicle} />
          </Tabs.Tab>
          <Tabs.Tab label={i18n.t("route")}>
            <VehicleSummaryTimeline
              data={routeInfo}
              onDateChange={handleDate}
              onPressRouteInfo={onPressRouteInfo}
              networkError={networkError}
            />
          </Tabs.Tab>
        </Tabs>
      </Container>
    </Container>
  ) : null;
};

export default VehicleSummaryBox;

const sleepModes = [
  "No Sleep",
  "GPS Sleep",
  "Deep Sleep",
  "Online Sleep",
  "Ultra Sleep",
];
const ignitionStatus = ["Ignition Off", "Ignition On"];
const movementStatus = ["Movement Off", "Movement On"];
const gnssStatus = [
  "GNSS OFF",
  "GNSS ON with fix",
  "GNSS without fix",
  "GNSS sleep",
];

// AVLObject

export default function mapAVLObjectToRecord(item: any) {
  return {
    // deviceIMEI: "639364593736484",
    deviceIMEI: item.imei,
    timestamp: item.Timestamp,
    priority: item.Priority,
    longitude: item.Longitude,
    latitude: item.Latitude,
    altitude: item.Altitude,
    angle: item.Angle,
    satellites: item.Satellites,
    speed: item.Speed,
    totalOdometer: item["Total Odometer"],
    tripOdometer: item["Trip Odometer"],
    GSMSignal: item["GSM Signal"],
    externalVoltage: item["External Voltage"],
    batteryVoltage: item["Battery Voltage"],
    batteryCurrent: item["Battery Current"],
    GNSS:
      typeof item["GNSS Status"] === "number" &&
      gnssStatus[item["GNSS Status"]],
    HDOP: item["GNSS HDOP"],
    PDOP: item["GNSS PDOP"],
    sleepMode:
      typeof item["Sleep Mode"] === "number" && sleepModes[item["Sleep Mode"]],
    ignition:
      typeof item.Ignition === "number" && ignitionStatus[item.Ignition],
    movement:
      typeof item.Movement === "number" && movementStatus[item.Movement],
    activeGSMOperator: item["Active GSM Operator"],
    engineLoad: item["Engine Load"],
    engineRPM: item["Engine RPM"],
    fuelLevel: item["Fuel Level"],
    engineOilTemperature: item["Engine Oil Temperature"],
    digitalInput1: item["Digital Input 1"],
    analogInput1: item["Analog Input 1"],
    DigitalOutput1: item["Digital Output 1"],
    fuelUsedGPS: item["Fuel Used GPS"],
    fuelRateGPS: item["Fuel Rate GPS"],
    axisX: item["Axis X"],
    axisY: item["Axis Y"],
    axisZ: item["Axis Z"],
    ignitionOnCounter: item["Ignition On Counter"],
    VIN: item.VIN,
  };
}

import {
  ActionIcon,
  Avatar,
  Button,
  Group,
  Navbar,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Bell, Logout } from "tabler-icons-react";
import authService from "../api/authService";
import { i18n, setLanguage } from "../config/translation";
import RouteList from "../routes/routes";
import { getCompany } from "../store/company";
import { getCount } from "../store/notifications";
import { getUser } from "../store/user";
import CountBadge from "./CountBadge";
import { NavLinks } from "./NavLink";
import NotificationDrawer from "./NotificationDrawer";

const logo = require("../assets/logo.png");

interface MyNavbarProps {}

export const MyNavbar: React.FC<MyNavbarProps> = ({}) => {
  const theme = useMantineTheme();
  const [drawer, setDrawer] = useState(false);
  const company = useSelector(getCompany);
  const user = useSelector(getUser);
  const count = useSelector(getCount);

  return (
    <>
      <NotificationDrawer visible={drawer} onClose={() => setDrawer(false)} />
      <Navbar
        p="md"
        width={{ base: 250 }}
        styles={{
          root: {
            backgroundColor: theme.colors.background[1],
            borderRight: `1px solid ${theme.colors.border[0]}`,
          },
        }}
      >
        <Navbar.Section>
          <Group
            position="apart"
            align={"flex-start"}
            pb="sm"
            mb="sm"
            style={{
              borderBottom: `1px solid ${theme.colors.border[0]}`,
            }}
          >
            <div style={{ width: "70%" }}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "70%",
                }}
              />
              <Text
                inline
                size="sm"
                style={{ marginTop: -5, paddingLeft: 7 }}
                color={theme.colors.secondary[0]}
              >
                {company.name ?? ""}
              </Text>
            </div>
            <div style={{ position: "relative" }}>
              <ActionIcon
                mt={5}
                color="indigo"
                variant="outline"
                onClick={() => setDrawer(true)}
              >
                <Bell size={16} />
              </ActionIcon>
              {/* @ts-ignore  */}
              <CountBadge count={count} />
            </div>
          </Group>
        </Navbar.Section>
        <Navbar.Section grow>{<NavLinks />}</Navbar.Section>
        <Navbar.Section>
          <Group py="sm" position="apart">
            <Text size="xs" color={"dimmed"}>
              {i18n.t("language")}
            </Text>
            <Tooltip
              label={i18n.t("changeTo", {
                language: i18n.t(i18n.locale === "en" ? "swahili" : "english"),
              })}
            >
              <Button
                variant="subtle"
                size="xs"
                compact
                color={"indigo"}
                onClick={() => {
                  if (localStorage.getItem("language") === "sw")
                    setLanguage("en");
                  else setLanguage("sw");

                  window.location.reload();
                }}
              >
                {i18n.t(i18n.locale === "en" ? "english" : "swahili")}
              </Button>
            </Tooltip>
          </Group>
          <Group
            pt="sm"
            style={{
              borderTop: `1px solid ${theme.colors.border[0]}`,
              width: "100%",
            }}
            position="apart"
            noWrap
          >
            <Group spacing={"xs"} noWrap style={{ width: "80%" }}>
              <Avatar
                src={user.avatar}
                radius="xl"
                color="indigo"
                size={"sm"}
              />
              <Stack spacing={0} style={{ width: "90%" }}>
                <Text size="xs">{user.userName ?? ""}</Text>
                <Text
                  size="xs"
                  color="dimmed"
                  lineClamp={1}
                  style={{ width: "100%" }}
                >
                  {user.email ?? ""}
                </Text>
              </Stack>
            </Group>
            <Tooltip label={i18n.t("logout")}>
              <ActionIcon
                onClick={() => {
                  authService.logout();
                  window.location.replace(`/${RouteList.AUTH_LOGIN}`);
                }}
                color="red"
              >
                <Logout size={16} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Navbar.Section>
      </Navbar>
    </>
  );
};

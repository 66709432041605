import React from "react";
import { Truck, Building } from "tabler-icons-react";
import { ThemeIcon, UnstyledButton, Tooltip } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import RouteList from "../routes/routes";

interface ManagementNavLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  path: string;
}

function ManagementNavLink({
  icon,
  color,
  label,
  path,
}: ManagementNavLinkProps) {
  let resolved = useLocation();
  let isMatched = resolved.pathname.includes(path);

  return (
    <Link to={path} style={{ all: "unset" }}>
      <Tooltip label={label} color="indigo" withArrow position="right">
        <UnstyledButton
          sx={(theme) => ({
            display: "block",
            width: "100%",
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.white,
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: theme.colors.background[0],
            },
          })}
        >
          <ThemeIcon
            color={color}
            variant={isMatched ? "filled" : "outline"}
            radius={"md"}
            size="lg"
          >
            {icon}
          </ThemeIcon>
        </UnstyledButton>
      </Tooltip>
    </Link>
  );
}

const data = [
  {
    icon: <Building size={16} />,
    color: "indigo",
    label: "Companies",
    path: RouteList.MANAGEMENT_COMPANIES,
  },
  {
    icon: <Truck size={16} />,
    color: "green",
    label: "Vehicles",
    path: RouteList.MANAGEMENT_VEHICLES,
  },
];

export function ManagementNavLinks() {
  const links = data.map((link) => (
    <ManagementNavLink {...link} key={link.label} />
  ));
  return <div>{links}</div>;
}

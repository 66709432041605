import {
  Avatar,
  Button,
  Grid,
  Group,
  Loader,
  Table,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  ArrowsSort,
  Plus,
  SortAscending2,
  SortDescending2,
} from "tabler-icons-react";
import DriverDetail from "../components/EmployeeDetail";
import EditDriverDrawer from "../components/EditDriverForm";
import NewDriverForm from "../components/NewDriverForm";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import GlobalSearch from "../components/GlobalSearch";
import { Driver } from "../model/Driver";
import driverService from "../api/driverService";
import { useSelector } from "react-redux";
import { getCompany } from "../store/company";
import logService from "../api/logService";
import { showNotification } from "@mantine/notifications";
import { i18n } from "../config/translation";

interface DriverListProps {}

const DriverList: React.FC<DriverListProps> = ({}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState<"edit" | "new" | undefined>();
  const [currentDriver, setCurrentDriver] = useState<Driver>();
  const company = useSelector(getCompany);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await driverService.getDrivers(company._id);
      setData(result.data);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      logService.log(ex);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: i18n.t("name"),
        accessor: "name",
        sortType: "basic",
        Cell: (props) => (
          <Group spacing={"xs"}>
            <Avatar
              src={props.row.original.avatar}
              color="indigo"
              size={"sm"}
              radius="xl"
            >
              {props.row.original.name[0]}
            </Avatar>
            <Text size="sm">{props.row.original.name}</Text>
          </Group>
        ),
      },
      {
        Header: i18n.t("phone"),
        accessor: "phone",
        sortType: "basic",
        Cell: (props) =>
          props.row.original.phone && props.row.original.phone.length > 0
            ? props.row.original.phone.join(", ")
            : i18n.t("noPhoneData"),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      //   defaultColumn,
      //   filterTypes,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    selectedFlatRows,
    state: { selectedRowIds },
    state,
  } = tableInstance;

  const handleCreate = (driver: Driver) => {
    setData([driver, ...data]);
  };

  const handleUpdate = (driver: Driver) => {
    const copied = [...data];
    const currentIndex = copied.findIndex((u) => u._id === driver._id);

    copied[currentIndex] = driver;
    setData(copied);
    setCurrentDriver(driver);
  };

  const handleDelete = (id: string) => {
    setCurrentDriver(undefined);
    setData(data.filter((d) => d._id !== id));
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Grid style={{ width: "100%", height: "100vh" }} gutter={0}>
        <Grid.Col span={7} p={"xl"}>
          <Group position="apart">
            <Title order={3}>{i18n.t("drivers")}</Title>
            <Button
              size="xs"
              radius={"xl"}
              leftIcon={<Plus size={14} />}
              color="indigo"
              onClick={() => setMode("new")}
            >
              {i18n.t("addNewDriver")}
            </Button>
          </Group>

          <GlobalSearch
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={i18n.t("searchNDrivers", {
              number: preGlobalFilteredRows.length,
            })}
          />
          {loading && (
            <Group position="center" style={{ width: "100%" }} py="xl">
              <Loader variant="bars" color={theme.colors.secondary[0]} />
            </Group>
          )}
          {!loading && (
            <div
              style={{
                maxHeight: "calc(100vh - 160px)",
                overflow: "scroll",
                border: `1px solid ${theme.colors.border[0]}`,
                borderRadius: 5,
              }}
            >
              <Table highlightOnHover>
                <table {...getTableProps()} style={{ width: "100%" }}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        style={{
                          position: "sticky",
                          zIndex: 1,
                          top: 0,
                          backgroundColor: theme.colors.background[1],
                        }}
                      >
                        {headerGroup.headers.map((column) => {
                          return (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              <Group>
                                {column.render("Header")}
                                {column.id !== "selection" &&
                                  (column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <SortDescending2
                                        size={15}
                                        color={
                                          column.isSorted
                                            ? theme.colors.secondary[0]
                                            : theme.colors.white[0]
                                        }
                                      />
                                    ) : (
                                      <SortAscending2
                                        size={15}
                                        color={
                                          column.isSorted
                                            ? theme.colors.secondary[0]
                                            : theme.colors.white[0]
                                        }
                                      />
                                    )
                                  ) : (
                                    <ArrowsSort size={15} />
                                  ))}
                              </Group>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.length === 0 ? (
                      <Text p="md" size="sm">
                        {i18n.t("noData")}
                      </Text>
                    ) : (
                      rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  onClick={() => {
                                    setCurrentDriver(row.original);
                                  }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </Table>
            </div>
          )}
        </Grid.Col>

        <Grid.Col
          span={5}
          p={"xl"}
          sx={(theme) => ({
            backgroundColor: theme.colors.background[1],
            borderLeft: `1px solid ${theme.colors.border[0]}`,
          })}
        >
          {mode === "new" ? (
            <NewDriverForm
              onClose={() => {
                setCurrentDriver(undefined);
                setMode(undefined);
              }}
              onUpdate={handleCreate}
            />
          ) : mode === "edit" ? (
            <EditDriverDrawer
              initialData={currentDriver}
              onClose={() => {
                setMode(undefined);
              }}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
            />
          ) : (
            currentDriver && (
              <DriverDetail
                data={currentDriver}
                onEdit={() => setMode("edit")}
              />
            )
          )}
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default DriverList;

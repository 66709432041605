import {
  Drawer,
  Group,
  MantineTheme,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
  Image,
  Button,
  createStyles,
  Box,
  ScrollArea,
  Select,
  Checkbox,
  SegmentedControl,
  RadioGroup,
  Radio,
  SimpleGrid,
  ActionIcon,
  NumberInput,
  InputWrapper,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";

import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Calendar, Plus, Trash } from "tabler-icons-react";
import driverService from "../api/driverService";
import logService from "../api/logService";
import vehicleService from "../api/vehicleService";
import { CompanyContext } from "../context/CompanyContext";
import { subsList, subsPrice } from "../model/Subscription";
import { Vehicle } from "../model/Vehicle";
import AppLoadingOverlay from "./AppLoadingOverlay";
import ImageInput from "./ImageInput";

interface NewVehicleDrawerProps {
  visible: boolean;
  onClose: () => void;
  companyId: string;
  onUpdate: (data: Vehicle) => void;
  initialData?: Vehicle;
}

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const speedList = [undefined, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120].map(
  (speed) => ({
    label: speed ? `${speed} km/h` : "Off",
    value: speed ? `${speed}` : undefined,
  })
);

const timeList = [
  undefined,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
].map((time) => ({
  label: time ? `${time} hour${time > 1 ? "s" : ""}` : "Off",
  value: time ? `${time}` : undefined,
}));

const NewVehicleDrawer: React.FC<NewVehicleDrawerProps> = ({
  visible,
  onClose,
  companyId,
  onUpdate,
  initialData,
}) => {
  const [checked, setChecked] = useState(false);
  const [fuelTracking, setFuelTracking] = useState(
    initialData && initialData.fuelFormula ? true : false
  );
  const [duration, setDuration] = useState("0");
  const [contractDuration, setContractDuration] = useState("0");
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { trustedContacts } = useContext(CompanyContext);

  const theme = useMantineTheme();

  useEffect(() => {
    getDriverList();
  }, []);

  const getDriverList = async () => {
    try {
      const result = await driverService.getDrivers(companyId);
      setDrivers(
        result.data.map((item) => ({
          ...item,
          value: item._id,
          label: item.name,
        }))
      );
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      logService.log(ex);
    }
  };

  const handleClose = () => {
    form.reset();
    setChecked(false);
    setDuration("0");
    setContractDuration("0");
    onClose();
  };

  const form = useForm({
    initialValues: {
      deviceIMEI: initialData ? initialData.deviceIMEI : undefined,
      devicePhone: initialData ? initialData.devicePhone : undefined,
      gpsInstalled: initialData ? initialData.gpsInstalledDate : undefined,
      name: initialData ? initialData.name : undefined,
      license: initialData ? initialData.license : undefined,
      model: initialData ? initialData.model : undefined,
      avatar: initialData ? initialData.avatar : undefined,
      driver: initialData
        ? // @ts-ignore
          initialData.driver && initialData.driver._id
        : undefined,
      trustedContacts: initialData ? initialData.trustedContacts : [],
      subscription: initialData ? initialData.subscription.name : undefined,
      startAt: initialData ? initialData.subscription.startAt : undefined,
      endAt: initialData ? initialData.subscription.endAt : undefined,
      price: initialData
        ? initialData.subscription.price.toString()
        : undefined,
      contractStartAt: initialData ? initialData.contractStartAt : undefined,
      contractEndAt: initialData ? initialData.contractEndAt : undefined,
      adminNote: initialData ? initialData.adminNote : undefined,
      note: initialData ? initialData.note : undefined,
      hasRelay: initialData ? initialData.hasRelay : false,
      vehicleType: initialData ? initialData.vehicleType : undefined,
      speedLimit:
        initialData && initialData.speedLimit
          ? `${initialData.speedLimit}`
          : undefined,
      timeLimit:
        initialData && initialData.timeLimit
          ? `${initialData.timeLimit}`
          : undefined,
      a0:
        initialData && initialData.fuelFormula
          ? initialData.fuelFormula[0]
          : undefined,
      a1:
        initialData && initialData.fuelFormula
          ? initialData.fuelFormula[1]
          : undefined,
      a2:
        initialData && initialData.fuelFormula
          ? initialData.fuelFormula[2]
          : undefined,
      a3:
        initialData && initialData.fuelFormula
          ? initialData.fuelFormula[3]
          : undefined,
      fuelTankCapacity: initialData ? initialData.fuelTankCapacity : undefined,
    },

    validate: {
      deviceIMEI: (value) =>
        value.length < 5 ? "Device IMEI must have at least 5 letters" : null,
      devicePhone: (value) =>
        value.length < 10 ? "Device phone must have at least 10 digits" : null,
      gpsInstalled: null,
      name: (value) =>
        value.length < 3 ? "Name must have at least 3 letters" : null,
      license: (value) =>
        value.length < 5 ? "License must have at least 5 letters" : null,
      model: (value) =>
        value.length < 2 ? "Model must have at least 2 letters" : null,
      price: (value) =>
        !isNumeric(value)
          ? "Price should be number"
          : Number(value) < 0
          ? "Price should not be less than 0"
          : null,
      startAt: (value) => (!value ? "Please enter starting date" : null),
      endAt: (value) => (!value ? "Please enter ending date" : null),
      a0: (value) => (fuelTracking && !value ? "Please enter a0" : null),
      a1: (value) => (fuelTracking && !value ? "Please enter a1" : null),
      a2: (value) => (fuelTracking && !value ? "Please enter a2" : null),
      a3: (value) => (fuelTracking && !value ? "Please enter a3" : null),
      fuelTankCapacity: (value) =>
        fuelTracking && !value ? "Please enter tank capacity" : null,
    },
  });

  const checkSubsEdited = (origin, form) => {
    if (origin.name !== form.name) return true;
    if (origin.price.toString() !== form.price) return true;
    if (origin.startAt !== form.startAt) return true;
    if (origin.endAt !== form.endAt) return true;

    return false;
  };

  const handleSubmit = async (values) => {
    const payload: Vehicle = {
      deviceIMEI: values.deviceIMEI,
      devicePhone: values.devicePhone,
      gpsInstalledDate: values.gpsInstalled,
      name: values.name,
      license: values.license,
      model: values.model,
      avatar: values.avatar,
      driver: values.driver,
      trustedContacts: values.trustedContacts,
      company: companyId,
      note: values.note,
      subscription: {
        name: values.subscription,
        price: values.price,
        startAt: values.startAt,
        endAt: values.endAt,
      },
      contractStartAt: values.contractStartAt,
      contractEndAt: values.contractEndAt,
      adminNote: values.adminNote,
      hasRelay: values.hasRelay,
      vehicleType: values.vehicleType,
      speedLimit: values.speedLimit ? parseInt(values.speedLimit) : undefined,
      timeLimit: values.timeLimit ? parseInt(values.timeLimit) : undefined,
      fuelFormula: fuelTracking
        ? [values.a0, values.a1, values.a2, values.a3]
        : null,
      fuelTankCapacity: values.fuelTankCapacity,
    };

    if (initialData) {
      payload._id = initialData._id;

      if (!checkSubsEdited(initialData.subscription, payload.subscription)) {
        delete payload.subscription;
      }
    }

    setLoading(true);
    try {
      const result = initialData
        ? await vehicleService.updateVehicle(payload)
        : await vehicleService.createVehicle(payload);

      showNotification({
        title: `Successfully ${initialData ? "updated" : "created"}!`,
        message: `${values.name} is ${initialData ? "updated" : "created"}.`,

        color: "indigo",
      });

      onUpdate &&
        onUpdate({
          ...result.data,
          subscription: payload.subscription ?? initialData.subscription,
        });
      setLoading(false);
      handleClose();
    } catch (ex) {
      if (ex.response.status === 400)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      logService.log(ex);
    }
    setLoading(false);
  };

  const textInputStyle = {
    filledVariant: {
      backgroundColor: theme.colors.background[1],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
  };

  const datePickerStyle = {
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
    filledVariant: {
      backgroundColor: theme.colors.background[1],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    dropdown: {
      backgroundColor: theme.colors.background[0],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    day: {
      "&:hover": {
        backgroundColor: theme.colors.dark[8],
      },
    },
    selected: {
      backgroundColor: theme.colors.background[1],
    },
  };

  const fields =
    form.values.trustedContacts &&
    form.values.trustedContacts.map((item, index) => (
      <Group key={index} mt="xs">
        <TextInput
          styles={textInputStyle}
          placeholder="Phone number"
          radius={"xl"}
          required
          sx={{ flex: 1 }}
          value={item}
          onChange={(event) => {
            const newData = [...form.values.trustedContacts];
            newData[index] = event.currentTarget.value;
            form.setFieldValue("trustedContacts", newData);
          }}
        />
        <ActionIcon
          color="red"
          variant="hover"
          onClick={() => {
            const newData = [...form.values.trustedContacts];
            newData.splice(index, 1);
            form.setFieldValue("trustedContacts", newData);
          }}
        >
          <Trash size={16} />
        </ActionIcon>
      </Group>
    ));

  return (
    <Drawer
      opened={visible}
      onClose={handleClose}
      title={`${initialData ? "Update" : "New"} vehicle`}
      size={800}
      styles={{
        title: { fontWeight: 600 },
        header: {
          borderBottom: `0.5px solid ${theme.colors.border[0]}`,
          padding: theme.spacing.xl,
          paddingBottom: theme.spacing.xs,
        },
        drawer: {
          backgroundColor: theme.colors.background[0],
          borderRight: `1px solid ${theme.colors.border[0]}`,
        },
      }}
    >
      <div style={{ position: "relative" }}>
        <AppLoadingOverlay loading={loading} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <ScrollArea
            px="xl"
            style={{
              height: "calc(100vh - 140px)",
              flex: 1,
            }}
          >
            <SimpleGrid cols={2}>
              <Stack
                style={{ borderRight: `1px solid ${theme.colors.border[0]}` }}
              >
                <Text weight={"bold"}>Device information</Text>
                <Stack px="md">
                  <TextInput
                    radius={"xl"}
                    label="Device IMEI"
                    value={form.values.deviceIMEI}
                    placeholder="Device IMEI"
                    onChange={(event) =>
                      form.setFieldValue(
                        "deviceIMEI",
                        event.currentTarget.value
                      )
                    }
                    required
                    styles={textInputStyle}
                    {...form.getInputProps("deviceIMEI")}
                  />
                  <TextInput
                    radius={"xl"}
                    label="Device phone"
                    value={form.values.devicePhone}
                    placeholder="Device phone"
                    onChange={(event) =>
                      form.setFieldValue(
                        "devicePhone",
                        event.currentTarget.value
                      )
                    }
                    required
                    styles={textInputStyle}
                    {...form.getInputProps("devicePhone")}
                  />
                  <DatePicker
                    icon={<Calendar size={16} />}
                    placeholder={
                      form.values.gpsInstalled
                        ? moment(form.values.gpsInstalled).format("LL")
                        : "Date of GPS installed"
                    }
                    label="Date of GPS installed"
                    radius={"xl"}
                    {...form.getInputProps("gpsInstalled")}
                    value={form.values.gpsInstalled}
                    defaultValue={form.values.gpsInstalled}
                    onChange={(value) =>
                      form.setFieldValue("gpsInstalled", value)
                    }
                    styles={datePickerStyle}
                    required
                  />
                  <Checkbox
                    mt="md"
                    label={"Relay installed"}
                    checked={form.values.hasRelay}
                    color="indigo"
                    onChange={(e) => {
                      form.setFieldValue("hasRelay", e.currentTarget.checked);
                    }}
                  />
                  <Checkbox
                    mt="md"
                    label={"Fuel tracking"}
                    checked={fuelTracking}
                    color="indigo"
                    onChange={(e) => {
                      setFuelTracking(e.currentTarget.checked);
                    }}
                  />
                  {fuelTracking && (
                    <NumberInput
                      required
                      label={`Fuel tank capacity`}
                      // precision={10}
                      styles={textInputStyle}
                      radius="xl"
                      min={0}
                      {...form.getInputProps("fuelTankCapacity")}
                      value={form.values["fuelTankCapacity"]}
                      onChange={(value) =>
                        form.setFieldValue("fuelTankCapacity", value)
                      }
                      placeholder="Fuel tank capacity"
                    />
                  )}

                  {fuelTracking && (
                    <InputWrapper label="Fuel tracking formula">
                      <SimpleGrid cols={2} pt="xs">
                        {[0, 1, 2, 3].map((item) => (
                          <NumberInput
                            required
                            label={`a${item}`}
                            placeholder={`a${item}`}
                            precision={10}
                            styles={textInputStyle}
                            radius="xl"
                            //@ts-ignore
                            {...form.getInputProps(`a${item}`)}
                            value={form.values[`a${item}`]}
                            onChange={(value) =>
                              //@ts-ignore
                              form.setFieldValue(`a${item}`, value)
                            }
                          />
                        ))}
                      </SimpleGrid>
                    </InputWrapper>
                  )}
                </Stack>

                <Text weight={"bold"} pt="xl">
                  Trusted contact
                </Text>

                <Stack px="md">
                  {fields}
                  {trustedContacts.length > 0 && (
                    <Checkbox
                      label="Use company contacts as trusted contact"
                      color="indigo"
                      checked={checked}
                      onChange={(event) => {
                        setChecked(event.currentTarget.checked);
                        if (event.currentTarget.checked) {
                          const filteredData =
                            form.values.trustedContacts.filter(
                              (v) => !trustedContacts.includes(v)
                            );
                          const newData = [...filteredData, ...trustedContacts];
                          form.setFieldValue("trustedContacts", newData);
                        }
                      }}
                    />
                  )}
                  <Button
                    size="xs"
                    radius={"lg"}
                    color="indigo"
                    variant="filled"
                    leftIcon={<Plus size={14} />}
                    onClick={() => {
                      const newData = [...form.values.trustedContacts];
                      newData.push("");
                      form.setFieldValue("trustedContacts", newData);
                    }}
                    style={{ zIndex: 10 }}
                  >
                    Add trusted contact
                  </Button>
                </Stack>
              </Stack>

              <Stack>
                <Text weight={"bold"}>Vehicle information</Text>
                <Stack px="md">
                  <TextInput
                    radius={"xl"}
                    label="Name"
                    value={form.values.name}
                    placeholder="Name"
                    onChange={(event) =>
                      form.setFieldValue("name", event.currentTarget.value)
                    }
                    required
                    styles={textInputStyle}
                    {...form.getInputProps("name")}
                  />
                  <TextInput
                    radius={"xl"}
                    label="License"
                    value={form.values.license}
                    placeholder="License"
                    onChange={(event) =>
                      form.setFieldValue("license", event.currentTarget.value)
                    }
                    required
                    styles={textInputStyle}
                    {...form.getInputProps("license")}
                  />
                  <TextInput
                    radius={"xl"}
                    label="Model"
                    value={form.values.model}
                    placeholder="Model"
                    onChange={(event) =>
                      form.setFieldValue("model", event.currentTarget.value)
                    }
                    required
                    styles={textInputStyle}
                    {...form.getInputProps("model")}
                  />
                  <Select
                    label="Vehicle type"
                    placeholder="Vehicle type"
                    value={form.values.vehicleType}
                    radius="xl"
                    {...form.getInputProps("vehicleType")}
                    onChange={(
                      value: "bike" | "car" | "truck" | "excavator"
                    ) => {
                      form.setFieldValue("vehicleType", value);
                    }}
                    styles={textInputStyle}
                    clearable
                    required={true}
                    data={[
                      { value: "bike", label: "Bike" },
                      { value: "car", label: "Car" },
                      { value: "truck", label: "Truck" },
                      { value: "excavator", label: "Excavator" },
                    ]}
                  />

                  <ImageInput
                    // label="Image"
                    onDrop={(file) => form.setFieldValue("avatar", file)}
                    value={form.values.avatar}
                    text="Vehicle image, click to upload"
                    {...form.getInputProps("avatar")}
                  />
                  <Textarea
                    radius={"lg"}
                    label="Note for user"
                    value={form.values.note}
                    placeholder="Note"
                    onChange={(event) =>
                      form.setFieldValue("note", event.currentTarget.value)
                    }
                    styles={textInputStyle}
                    {...form.getInputProps("note")}
                  />
                  <Select
                    label="Driver"
                    placeholder="Choose driver"
                    value={form.values.driver}
                    radius="xl"
                    onChange={(value) => form.setFieldValue("driver", value)}
                    styles={textInputStyle}
                    clearable
                    data={drivers}
                    nothingFound={"No driver data"}
                    {...form.getInputProps("driver")}
                  />
                </Stack>
              </Stack>
            </SimpleGrid>

            <SimpleGrid cols={2} pt="xl">
              <Stack
                style={{ borderRight: `1px solid ${theme.colors.border[0]}` }}
              >
                <Text weight={"bold"} pt="xl">
                  Subscription
                </Text>
                <Stack px="md" mb={50}>
                  <Select
                    label="Subscription"
                    placeholder="Subscription"
                    value={form.values.subscription}
                    radius="xl"
                    {...form.getInputProps("subscription")}
                    onChange={(value) => {
                      form.setFieldValue("subscription", value);
                      form.setFieldValue("price", subsPrice[value]);
                    }}
                    styles={textInputStyle}
                    clearable
                    required={true}
                    data={subsList}
                  />
                  <TextInput
                    radius={"xl"}
                    label="Price"
                    value={form.values.price}
                    placeholder="Price"
                    {...form.getInputProps("price")}
                    onChange={(event) =>
                      form.setFieldValue("price", event.currentTarget.value)
                    }
                    required={true}
                    styles={textInputStyle}
                  />
                  <DatePicker
                    placeholder={
                      form.values.startAt
                        ? moment(form.values.startAt).format("LL")
                        : "Start date"
                    }
                    icon={<Calendar size={16} />}
                    {...form.getInputProps("startAt")}
                    label="Start date"
                    radius={"xl"}
                    value={form.values.startAt}
                    onChange={(value) => form.setFieldValue("startAt", value)}
                    styles={datePickerStyle}
                    required
                  />
                  <DatePicker
                    placeholder={
                      form.values.endAt
                        ? moment(form.values.endAt).format("LL")
                        : "End date"
                    }
                    icon={<Calendar size={16} />}
                    label="End date"
                    {...form.getInputProps("endAt")}
                    radius={"xl"}
                    value={form.values.endAt}
                    minDate={form.values.startAt}
                    onChange={(value) => form.setFieldValue("endAt", value)}
                    styles={datePickerStyle}
                    disabled={duration !== "0"}
                    required
                  />
                  <SegmentedControl
                    value={duration}
                    disabled={!form.values.startAt}
                    onChange={(d) => {
                      setDuration(d);
                      if (d !== "0") {
                        const date = moment(form.values.startAt)
                          .add(d, "months")
                          .toDate();
                        form.setFieldValue("endAt", date);
                      }
                    }}
                    color="indigo"
                    styles={{
                      root: {
                        backgroundColor: theme.colors.background[1],
                        border: `0.5px solid ${theme.colors.border[0]}`,
                      },
                    }}
                    size="xs"
                    radius="xl"
                    data={[
                      { label: "Calendar", value: "0" },
                      { label: "1m", value: "1" },
                      { label: "3m", value: "3" },
                      { label: "6m", value: "6" },
                      { label: "12m", value: "12" },
                    ]}
                  />
                </Stack>
              </Stack>
              <Stack>
                <Text weight={"bold"} pt="xl">
                  Notification settings
                </Text>
                <Stack px="md" mb={50}>
                  <Select
                    label="Speed limit"
                    placeholder="Off"
                    value={form.values.speedLimit}
                    radius="xl"
                    {...form.getInputProps("speedLimit")}
                    onChange={(value: string | undefined) => {
                      form.setFieldValue("speedLimit", value);
                    }}
                    styles={textInputStyle}
                    clearable
                    data={speedList}
                  />
                  <Select
                    label="Time limit"
                    placeholder="Off"
                    value={form.values.timeLimit}
                    radius="xl"
                    {...form.getInputProps("timeLimit")}
                    onChange={(value: string | undefined) => {
                      form.setFieldValue("timeLimit", value);
                    }}
                    styles={textInputStyle}
                    clearable
                    data={timeList}
                  />
                </Stack>
                {/* <Text weight={"bold"} pt="xl">
                  Contract
                </Text>
                <Stack px="md" mb={50}>
                  <DatePicker
                    placeholder={
                      form.values.contractStartAt
                        ? moment(form.values.contractStartAt).format("LL")
                        : "Start date"
                    }
                    icon={<Calendar size={16} />}
                    label="Start date"
                    radius={"xl"}
                    value={form.values.contractStartAt}
                    onChange={(value) =>
                      form.setFieldValue("contractStartAt", value)
                    }
                    styles={datePickerStyle}
                    {...form.getInputProps("contractStartAt")}
                  />
                  <DatePicker
                    minDate={form.values.contractStartAt}
                    icon={<Calendar size={16} />}
                    placeholder={
                      form.values.contractEndAt
                        ? moment(form.values.contractEndAt).format("LL")
                        : "End date"
                    }
                    label="End date"
                    radius={"xl"}
                    value={form.values.contractEndAt}
                    onChange={(value) =>
                      form.setFieldValue("contractEndAt", value)
                    }
                    styles={datePickerStyle}
                    disabled={contractDuration !== "0"}
                    {...form.getInputProps("contractEndAt")}
                  />
                  <SegmentedControl
                    disabled={!form.values.contractStartAt}
                    value={contractDuration}
                    onChange={(d) => {
                      setContractDuration(d);
                      if (d !== "0") {
                        form.setFieldValue(
                          "contractEndAt",
                          moment(form.values.contractStartAt)
                            .add(d, "years")
                            .toDate()
                        );
                      }
                    }}
                    color="indigo"
                    styles={{
                      root: {
                        backgroundColor: theme.colors.background[1],
                        border: `0.5px solid ${theme.colors.border[0]}`,
                      },
                    }}
                    size="xs"
                    radius="xl"
                    data={[
                      { label: "Calendar", value: "0" },
                      { label: "1y", value: "1" },
                      { label: "2y", value: "2" },
                      { label: "3y", value: "3" },
                    ]}
                  />
                </Stack> */}
              </Stack>
            </SimpleGrid>

            <Stack>
              <Text weight={"bold"} pt="xl">
                Note for admins
              </Text>
              <Stack px="md" mb={50}>
                <Textarea
                  radius={"lg"}
                  value={form.values.adminNote}
                  placeholder="Note"
                  onChange={(event) =>
                    form.setFieldValue("adminNote", event.currentTarget.value)
                  }
                  styles={textInputStyle}
                  {...form.getInputProps("adminNote")}
                />
              </Stack>
            </Stack>
          </ScrollArea>
          <Box
            p="sm"
            px="lg"
            style={{ borderTop: `1px solid ${theme.colors.border[0]}` }}
          >
            <Button type="submit" color="indigo" radius={"lg"} fullWidth>
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </Drawer>
  );
};

export default NewVehicleDrawer;

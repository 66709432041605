import {
  Badge,
  Container,
  Group,
  Loader,
  Pagination,
  Table,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  ArrowsSort,
  SortAscending2,
  SortDescending2,
} from "tabler-icons-react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";
import { getHeight } from "../../utility/getWindowSize";
import { useNavigate } from "react-router-dom";
import NewCompanyForm from "../../components/NewCompanyForm";
import GlobalSearch from "../../components/GlobalSearch";
import logService from "../../api/logService";
import { Company } from "../../model/Company";
import vehicleService from "../../api/vehicleService";
import moment from "moment";

interface ManagementVehiclesProps {}

const ManagementVehicles: React.FC<ManagementVehiclesProps> = ({}) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [newForm, setNewForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await vehicleService.getAdminAllVehicles();

      setData(result.data);
      setLoading(false);
    } catch (ex) {
      logService.log(ex);
    }
  };

  const handleCreateCompany = (company: Company) => {
    setData([company, ...data]);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Company",
        accessor: "company.name",
        sortType: "basic",
      },
      {
        Header: "Vehicle name",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Vehicle type",
        accessor: "vehicleType",
        sortType: "basic",
      },
      {
        Header: "Device IMEI",
        accessor: "deviceIMEI",
        sortType: "basic",
      },
      {
        Header: "Subscription until",
        accessor: "subscription.endAt",
        sortType: "basic",
        Cell: (props) => {
          if (
            !props.row.original.subscription ||
            !props.row.original.subscription.endAt
          )
            return "No data";

          const type = props.row.original.subscription.name;

          const time = moment(props.row.original.subscription.endAt);
          const endSoon = time.diff(moment(), "days") < 8;

          return (
            <Group spacing={"xs"}>
              <Text size="sm" color={endSoon ? "red" : "white"}>
                {time.format("ll")}
              </Text>
              <Badge
                color={
                  type === "Mini"
                    ? "yellow"
                    : type === "Regular"
                    ? "pink"
                    : type === "Xl"
                    ? "violet"
                    : "green"
                }
              >
                {type}
              </Badge>
            </Group>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: Math.floor((getHeight() - 180) / 50),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    pageOptions,
    gotoPage,
    state,
  } = tableInstance;

  return (
    <Container py="xl" style={{ height: "100%" }}>
      <NewCompanyForm
        visible={newForm}
        onClose={() => setNewForm(false)}
        onUpdate={handleCreateCompany}
        initialData={undefined}
      />

      <Group position="apart">
        <Title order={3}>Vehicles</Title>
      </Group>

      <GlobalSearch
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        placeholder="Search vehicles..."
      />

      {loading && (
        <Group position="center" style={{ width: "100%" }} py="xl">
          <Loader variant="bars" color={theme.colors.secondary[0]} />
        </Group>
      )}
      {!loading && (
        <Table highlightOnHover>
          <table {...getTableProps()} style={{ width: "100%" }}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Group>
                        {column.render("Header")}

                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDescending2
                              size={15}
                              color={
                                column.isSorted
                                  ? theme.colors.secondary[0]
                                  : theme.colors.white[0]
                              }
                            />
                          ) : (
                            <SortAscending2
                              size={15}
                              color={
                                column.isSorted
                                  ? theme.colors.secondary[0]
                                  : theme.colors.white[0]
                              }
                            />
                          )
                        ) : (
                          <ArrowsSort size={15} />
                        )}
                      </Group>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() =>
                      navigate(
                        `../company/${row.original.company._id}/vehicles/${row.original._id}`,
                        {
                          state: { name: row.original.company.name },
                        }
                      )
                    }
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Table>
      )}

      <Group position="center" pt="xl">
        <Pagination
          total={pageOptions.length}
          color="indigo"
          page={state.pageIndex + 1}
          onChange={(page) => gotoPage(page - 1)}
          styles={{
            item: {
              backgroundColor: theme.colors.background[1],
              border: `0.5px solid ${theme.colors.border[0]}`,
            },
          }}
        />
      </Group>
    </Container>
  );
};

export default ManagementVehicles;

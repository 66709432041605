import {
  Box,
  Group,
  Loader,
  ScrollArea,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Clock, Printer } from "tabler-icons-react";
import logService from "../api/logService";
import recordService from "../api/recordService";
import { i18n } from "../config/translation";
import { getVehicle } from "../store/vehicles";
import AppDatePicker from "./AppDatePicker";
import PrintReportButton from "./PrintReportButton";
import AppLoadingOverlay from "./AppLoadingOverlay";
import reportService from "../api/reportService";

interface DailyTimeProps {}

const DailyTime: React.FC<DailyTimeProps> = ({}) => {
  const theme = useMantineTheme();
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { id } = useParams();
  const [data, setData] =
    useState<{ duration: number; startAt: Date; endAt: Date }[]>();
  const vehicle = useSelector((state) => getVehicle(state, id));

  useEffect(() => {
    getDailyData(new Date());
  }, [id]);

  const getDailyData = async (date: Date) => {
    setDate(date);
    setLoading(true);

    try {
      const result = await recordService.getTimeRecordsByDate(id, date);

      setData(result.data);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      logService.log(ex);
    }
  };

  const getHours = (seconds: number) => {
    if (seconds === 0) return "0 hrs";

    if (seconds < 60) return i18n.t("lessThan1");

    const hour = moment.utc(1000 * seconds).format("H");
    const min = moment.utc(1000 * seconds).format("m");

    const greaterThan24 = seconds / 3600 >= 24;

    return `${
      hour !== "0" ? `${greaterThan24 ? 24 + Number(hour) : hour} hrs ` : ""
    }${min !== "0" ? `${min} min` : ""}`;
  };

  return (
    <Box
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.background[1],
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.border[0]}`,
        position: "relative",
      })}
    >
      <AppLoadingOverlay loading={downloading} />
      <Group position="apart">
        <Group spacing={"xs"}>
          <Clock size="16" color={theme.colors.secondary[0]} />
          <Text size="sm">{i18n.t("dailyHours")}</Text>
        </Group>
        <Group spacing={"xs"}>
          <AppDatePicker value={date} onChange={getDailyData} />
          <PrintReportButton
            setLoading={setDownloading}
            api={() =>
              reportService.getTimeUsageReport(vehicle.deviceIMEI, date)
            }
            title={`Time_usage_report-${moment().format("DD_MMM_YYYY")}`}
          />
        </Group>
      </Group>

      <ScrollArea
        style={{ height: "calc(100vh - 200px)" }}
        scrollbarSize={4}
        type="scroll"
      >
        {loading ? (
          <Group
            position="center"
            style={{ width: "100%", paddingTop: 200 }}
            py="xl"
          >
            <Loader variant="bars" color={theme.colors.secondary[0]} />
          </Group>
        ) : (
          <>
            <Box
              p="md"
              sx={(theme) => ({
                backgroundColor: theme.colors.background[0],
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.colors.border[0]}`,
              })}
              mb="md"
              mt="md"
            >
              <Text color={theme.colors.secondary[0]} size="xs" pb={5}>
                {i18n.t("total")}
              </Text>
              {data && (
                <Title order={3}>
                  {getHours(
                    data.reduce((prev, current) => {
                      return prev + current.duration;
                    }, 0)
                  )}
                </Title>
              )}
            </Box>

            <Box px="md">
              {data &&
                data.map((item, index) => (
                  <Group
                    key={`${index}`}
                    py="xs"
                    align={"flex-start"}
                    spacing="xl"
                  >
                    <Text
                      style={{ fontWeight: 600 }}
                      color={theme.colors.secondary[0]}
                      size="sm"
                    >
                      {index + 1}
                    </Text>

                    <Box>
                      <Text size="sm">{getHours(item.duration)}</Text>
                      <Text size="xs" color="dimmed">{`${moment(
                        item.startAt
                      ).format("h:mm A")} ~ ${moment(item.endAt).format(
                        "h:mm A"
                      )}`}</Text>
                    </Box>
                  </Group>
                ))}
            </Box>
          </>
        )}
      </ScrollArea>
    </Box>
  );
};

export default DailyTime;

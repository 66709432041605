import { Avatar, Box, Group, Stack, Text } from "@mantine/core";
import React from "react";
import { Driver } from "../model/Driver";

interface DriverCardProps {
  data: Driver;
  onClick?: () => void;
}

const DriverCard: React.FC<DriverCardProps> = ({ data, onClick }) => {
  return (
    <Box
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.background[1],
        border: `1px solid ${theme.colors.border[0]}`,
        borderRadius: theme.radius.md,
        "&:hover": {
          backgroundColor: theme.colors.primary[1],
          cursor: "pointer",
        },
      })}
      onClick={onClick}
    >
      <Group>
        <Avatar src={data.avatar} radius="md" />
        <Stack spacing={0}>
          <Text size="sm">{data.name}</Text>
          <Text size="xs" color="dimmed">
            {data.phone ? data.phone[0] : "No data"}
          </Text>
        </Stack>
      </Group>
    </Box>
  );
};

export default DriverCard;

import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Group,
  Header,
  Navbar,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useState } from "react";

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import authService from "../../api/authService";
import { ManagementNavLinks } from "../../components/ManagementNavLink";
import RouteList from "../routes";

const logo = require("../../assets/logo.png");

interface ManagementProps {}

const Management: React.FC<ManagementProps> = ({}) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = authService.getCurrentUser();

  useEffect(() => {
    // @ts-ignore:
    if (user && user.isAdmin && pathname === RouteList.MANAGEMENT_MAIN)
      navigate(RouteList.MANAGEMENT_COMPANIES);
  }, []);

  if (!user) return <Navigate replace to={`../${RouteList.AUTH_LOGIN}`} />;

  // @ts-ignore:
  if (!user.isAdmin)
    return <Navigate replace to={`../${RouteList.NOT_AUTHORIZED}`} />;

  return (
    <AppShell
      padding="md"
      header={
        <Header
          height={55}
          p="xs"
          px={"xl"}
          style={{
            backgroundColor: theme.colors.background[1],
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Group
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              spacing={0}
              onClick={() =>
                navigate(
                  `../${RouteList.MANAGEMENT_MAIN}/${RouteList.MANAGEMENT_COMPANIES}`
                )
              }
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: 110,
                }}
              />
              <Text
                inline
                size="sm"
                style={{ paddingLeft: 10 }}
                color={theme.colors.secondary[0]}
              >
                Management system
              </Text>
            </Group>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Text
                color={theme.colors.grey[0]}
                size="sm"
                style={{ paddingRight: 10 }}
              >
                {/* @ts-ignore: */}
                {user.email}
              </Text>
              <Button
                variant="subtle"
                color="dark"
                radius={"md"}
                onClick={() => {
                  authService.logout();
                  window.location.replace(`/${RouteList.AUTH_LOGIN}`);
                }}
              >
                Logout
              </Button>
            </div>
          </div>
        </Header>
      }
      navbar={
        <Navbar
          p="xs"
          width={{ base: 70 }}
          style={{
            backgroundColor: theme.colors.background[1],
          }}
        >
          <ManagementNavLinks />
        </Navbar>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.background[0]
              : theme.colors.gray[0],
          padding: 0,
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
};

export default Management;

import { useMantineTheme } from "@mantine/core";
import { Box, Group, Text } from "@mantine/core";
import React from "react";
import {
  Activity,
  Clock,
  GasStation,
  Road,
  Temperature,
} from "tabler-icons-react";
import { i18n } from "../config/translation";

export enum StatType {
  speed,
  gas,
  time,
  distance,
  temperature,
}

interface StatsBoxProps {
  type: StatType;
  isOn: boolean;
  data: number | string;
  backgroundColor?: string;
  small?: boolean;
  style?: any;
}

const StatsBox: React.FC<StatsBoxProps> = ({
  type,
  isOn,
  data,
  backgroundColor,
  small,
  style = {},
}) => {
  const theme = useMantineTheme();

  return (
    <Box
      p="md"
      sx={(theme) => ({
        backgroundColor: backgroundColor
          ? backgroundColor
          : theme.colors.background[1],
        borderRadius: 10,
        height: "100%",
        border: `1px solid ${theme.colors.border[0]}`,
        ...style,
      })}
    >
      <Group spacing={small ? 3 : "xs"} noWrap>
        {type === StatType.speed ? (
          <Activity
            size={small ? 14 : 16}
            color={isOn ? theme.colors.secondary[0] : theme.colors.dark[3]}
          />
        ) : type === StatType.gas ? (
          <GasStation
            size={small ? 14 : 16}
            color={isOn ? theme.colors.secondary[0] : theme.colors.dark[3]}
          />
        ) : type === StatType.temperature ? (
          <Temperature
            size={small ? 14 : 16}
            color={isOn ? theme.colors.secondary[0] : theme.colors.dark[3]}
          />
        ) : type === StatType.time ? (
          <Clock
            size={small ? 14 : 16}
            color={isOn ? theme.colors.secondary[0] : theme.colors.dark[3]}
          />
        ) : (
          <Road
            size={small ? 14 : 16}
            color={isOn ? theme.colors.secondary[0] : theme.colors.dark[3]}
          />
        )}

        <Text size={small ? "xs" : "sm"}>
          {type === StatType.speed
            ? i18n.t("speed")
            : type === StatType.gas
            ? i18n.t("gas")
            : type === StatType.distance
            ? i18n.t("distance")
            : type === StatType.time
            ? i18n.t("time")
            : i18n.t("temp")}
        </Text>
      </Group>
      <Group
        style={{ paddingTop: 10 }}
        spacing={small ? 5 : "xs"}
        align={"end"}
        noWrap
      >
        <Text
          style={{ fontSize: small ? 20 : 25, fontWeight: "bold" }}
          color={isOn ? theme.white : theme.colors.dark[3]}
        >
          {data}
        </Text>
        <Text color={theme.colors.dark[2]} pb={3} size={small ? "xs" : "sm"}>
          {type === StatType.speed
            ? "km/h"
            : type === StatType.gas
            ? "%"
            : type === StatType.distance
            ? "km"
            : type === StatType.time
            ? "h:m"
            : "°C"}
        </Text>
      </Group>
    </Box>
  );
};

export default StatsBox;

import { showNotification } from "@mantine/notifications";
import imageCompression from "browser-image-compression";
import { Company } from "./../model/Company";
import http from "./httpService";

const apiEndPoint = "/images";

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 200,
};

export async function uploadImage(file) {
  const data = new FormData();

  try {
    const compressedFile = await imageCompression(file, options);
    data.append("image", compressedFile);
  } catch (error) {
    showNotification({
      title: "Something went wrong while uploading image",
      message: "Plesase try again",
      color: "red",
    });
    return { data: null, response: { status: 400 } };
  }

  return http.post(`${apiEndPoint}/single`, data);
}

export default {
  uploadImage,
};

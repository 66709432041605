import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Popover,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { InfoCircle, Printer } from "tabler-icons-react";
import { i18n } from "../config/translation";
import moment from "moment";
import AppDatePicker from "./AppDatePicker";
import AppLoadingOverlay from "./AppLoadingOverlay";

interface RangePrintReportButtonProps {
  api: any;
  title: string;
}

const RangePrintReportButton: React.FC<RangePrintReportButtonProps> = ({
  api,
  title,
}) => {
  const [startAt, setStartAt] = useState(moment().subtract(6, "days").toDate());
  const [endAt, setEndAt] = useState(moment().toDate());
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useMantineTheme();

  const generatePdf = async () => {
    setLoading && setLoading(true);
    try {
      const result = await api(startAt, endAt);
      downloadPDF(result.data);
    } catch (ex) {
      if (ex.response.status >= 400 && ex.response.status < 500)
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
    }
    setLoading && setLoading(false);
  };

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${title}-${moment(startAt).format(
      "DD_MMMM_YYYY"
    )}-${moment(endAt).format("DD_MMMM_YYYY")}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <ActionIcon
        color={"indigo"}
        variant="filled"
        radius={"md"}
        onClick={() => setOpened(true)}
      >
        <Printer size={18} />
      </ActionIcon>
      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        size={"xs"}
        title="Generate report"
        styles={{
          modal: {
            backgroundColor: theme.colors.background[1],
          },
        }}
      >
        <Stack align="center" style={{ position: "relative" }}>
          <AppLoadingOverlay loading={loading} />
          <Group
            spacing={10}
            style={{
              backgroundColor: theme.colors.background[0],
              padding: 7,
              paddingLeft: 14,
              paddingRight: 14,
              borderRadius: 10,
            }}
          >
            <InfoCircle color={theme.colors.secondary[0]} size={16} />
            <Text size="xs" style={{ fontStyle: "italic" }}>
              You can generate report within one month
            </Text>
          </Group>
          <Group pt="sm">
            <Text size="sm" style={{ width: 70 }}>
              Start from
            </Text>
            <AppDatePicker value={startAt} onChange={setStartAt} />
          </Group>
          <Group>
            <Text size="sm" style={{ width: 70 }}>
              End until
            </Text>
            <AppDatePicker value={endAt} onChange={setEndAt} />
          </Group>
          <Button color="indigo" radius={"sm"} mt="md" onClick={generatePdf}>
            Generate report
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default RangePrintReportButton;

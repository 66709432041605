import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Group,
  Stack,
  Table,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  ArrowsSort,
  Settings,
  SortAscending2,
  SortDescending2,
} from "tabler-icons-react";
import {
  useTable,
  useSortBy,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";
import { showNotification } from "@mantine/notifications";
import { User } from "../model/User";
import userService from "../api/userService";
import logService from "../api/logService";
import AppLoadingOverlay from "./AppLoadingOverlay";
import NewAccountForm from "./NewAccountForm";
import { i18n } from "../config/translation";

interface ManagerDetailProps {
  id: string;
  onUpdate: (value: User) => void;
}

const ManagerDetail: React.FC<ManagerDetailProps> = ({ id, onUpdate }) => {
  const theme = useMantineTheme();
  const [form, setForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState<User>();

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await userService.getUserDetail(id);
      setData(result.data.vehicles);
      setUserInfo(result.data);
      setLoading(false);
    } catch (ex) {
      showNotification({
        title: i18n.t("somethingWrong"),
        message: i18n.t("tryAgain"),
        color: "red",
      });
      logService.log(ex);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: i18n.t("name"),
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: i18n.t("license"),
        accessor: "license",
        sortType: "basic",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Box style={{ position: "relative", minHeight: 400 }}>
      <AppLoadingOverlay loading={loading} />
      {data && userInfo && (
        <>
          {form && (
            <NewAccountForm
              visible={form}
              onClose={() => setForm(false)}
              initialData={userInfo}
              onUpdate={(user: User) => {
                setUserInfo(user);
                setData(user.vehicles);
                onUpdate({ ...user, vehicles: user.vehicles });
              }}
              companyId={userInfo.company}
            />
          )}
          <Group position="apart" noWrap align={"flex-start"}>
            <Title order={3}>{userInfo.userName}</Title>

            <ActionIcon radius="xl" onClick={() => setForm(true)}>
              <Settings size={20} />
            </ActionIcon>
          </Group>

          <Stack
            spacing={"xs"}
            mt="xl"
            mb="xs"
            p="md"
            style={{
              border: `1px solid ${theme.colors.border[0]}`,
              borderRadius: theme.radius.md,
            }}
          >
            <Group position="apart">
              <Text size="sm">{i18n.t("email")}</Text>
              <Text size="sm" weight={"bold"}>
                {userInfo.email}
              </Text>
            </Group>
            <Group position="apart">
              <Text size="sm">{i18n.t("username")}</Text>
              <Text size="sm" weight={"bold"}>
                {userInfo.userName}
              </Text>
            </Group>
            <Group position="apart" align={"flex-start"}>
              <Text size="sm">{i18n.t("contacts")}</Text>
              <Stack spacing={0}>
                {userInfo.phones.length === 0 && (
                  <Text size="sm" weight={"bold"}>
                    {i18n.t("noData")}
                  </Text>
                )}
                {userInfo.phones.map((item) => (
                  <Text size="sm" weight={"bold"} key={item}>
                    {item}
                  </Text>
                ))}
              </Stack>
            </Group>
            <Group position="apart">
              <Text size="sm">{i18n.t("roles")}</Text>
              <Text size="sm" weight={"bold"}>
                {userInfo.roles &&
                  userInfo.roles.map((r) => (
                    <Badge key={r} color={r === "admin" ? "green" : "indigo"}>
                      {r}
                    </Badge>
                  ))}
              </Text>
            </Group>
          </Stack>

          <Title order={5} pt="xl" mb="sm">
            {i18n.t("assignedVehicles")}{" "}
            <span style={{ fontWeight: 400 }}>{`(${data.length})`}</span>
          </Title>

          <div
            style={{
              maxHeight: "calc(100vh - 380px)",
              overflow: "scroll",
              border: `1px solid ${theme.colors.border[0]}`,
              borderRadius: 5,
            }}
          >
            <Table highlightOnHover>
              <table {...getTableProps()} style={{ width: "100%" }}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: theme.colors.background[1],
                      }}
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <Group>
                            {column.render("Header")}

                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SortDescending2
                                  size={15}
                                  color={
                                    column.isSorted
                                      ? theme.colors.secondary[0]
                                      : theme.colors.white[0]
                                  }
                                />
                              ) : (
                                <SortAscending2
                                  size={15}
                                  color={
                                    column.isSorted
                                      ? theme.colors.secondary[0]
                                      : theme.colors.white[0]
                                  }
                                />
                              )
                            ) : (
                              <ArrowsSort size={15} />
                            )}
                          </Group>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.length === 0 ? (
                    <Text p="md">{i18n.t("noData")}</Text>
                  ) : (
                    rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </Table>
          </div>
        </>
      )}
    </Box>
  );
};

export default ManagerDetail;

import { Box, Stack, Text, useMantineTheme } from "@mantine/core";
import React from "react";
import { FileSearch } from "tabler-icons-react";
import { i18n } from "../config/translation";

interface EmptyProps {
  title?: string;
  height?: any;
}

const Empty: React.FC<EmptyProps> = ({ title = i18n.t("noData"), height }) => {
  const theme = useMantineTheme();
  return (
    <Stack
      style={{ width: "100%", height: height ?? "100%" }}
      justify="center"
      align={"center"}
      py="xl"
    >
      <FileSearch size={30} color={theme.colors.primary[0]} />
      <Text size="sm" color="dimmed">
        {title}
      </Text>
    </Stack>
  );
};

export default Empty;

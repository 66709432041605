import {
  Box,
  Button,
  Center,
  Group,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Clock, GasStation, Road, Search } from "tabler-icons-react";
import { i18n } from "../config/translation";
import { getAllVehicles, getVehicle } from "../store/vehicles";

interface StatsMainProps {}

const segmentData = [
  {
    value: "time",
    label: (
      <Center px="xl">
        <Clock size={16} />
        <Box ml={"xs"}>Time</Box>
      </Center>
    ),
  },
  {
    value: "distance",
    label: (
      <Center px="xl">
        <Road size={18} />
        <Box ml={"xs"}>Distance</Box>
      </Center>
    ),
  },
  // {
  //   value: "fuel",
  //   label: (
  //     <Center px="xl">
  //       <GasStation size={18} />
  //       <Box ml={"xs"}>Fuel</Box>
  //     </Center>
  //   ),
  // },
];

const StatsMain: React.FC<StatsMainProps> = ({}) => {
  const theme = useMantineTheme();
  const navigator = useNavigate();
  const { id, type } = useParams();
  const vehicles = useSelector(getAllVehicles);
  const vehicle = useSelector((state) => getVehicle(state, id));
  const subsOver = vehicle && moment(vehicle.subscription.endAt) < moment();
  const handleSearch = (id) => {
    if (id) navigator(`${id}/${type ?? "time"}`);
  };

  return (
    <Stack p="lg" spacing={0} style={{ height: "100vh" }}>
      <Title order={3} mb="xs">
        {i18n.t("statistics")}
      </Title>

      <Group position="apart" mb={"xs"}>
        <Stack spacing={0}>
          <Select
            /* @ts-ignore */
            data={
              vehicles &&
              vehicles.map((v) => ({
                /* @ts-ignore */
                label: v.name,
                /* @ts-ignore */
                value: v.deviceIMEI,
              }))
            }
            onChange={handleSearch}
            value={vehicle && vehicle.deviceIMEI}
            styles={{
              input: {
                padding: 0,
                backgroundColor: "transparent",
                color: theme.colors.secondary[0],
                fontWeight: 600,
                fontSize: theme.fontSizes.lg,
                "&::placeholder": {
                  color: theme.colors.grey,
                },
              },
            }}
          />
        </Stack>
        <SegmentedControl
          value={type ?? "time"}
          onChange={(value) => navigator(`${id}/${value}`)}
          radius={"md"}
          data={segmentData}
          sx={(theme) => ({
            backgroundColor: theme.colors.background[1],
            border: `1px solid ${theme.colors.border[0]}`,
          })}
          size="xs"
          color="indigo"
        />
      </Group>
      {subsOver ? (
        <Stack align={"center"} justify="center" style={{ height: "100%" }}>
          <Text pb="xl" mb="xl">
            Subscription is Over. Please contact Bugitrack.
          </Text>
        </Stack>
      ) : (
        <Outlet />
      )}
    </Stack>
  );
};

export default StatsMain;

import { useMantineTheme } from "@mantine/core";
import React from "react";

export enum StatusSize {
  extraSmall = 11,
  small = 17,
  medium = 25,
}

interface StatusSignProps {
  isOn: boolean;
  size?: StatusSize;
  warn?: boolean;
}

const StatusSign: React.FC<StatusSignProps> = ({
  isOn,
  size = StatusSize.small,
  warn,
}) => {
  const theme = useMantineTheme();

  return (
    <div style={{ position: "absolute" }}>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: size,
          height: size,
          border: `1px solid ${
            warn
              ? theme.colors.red[8]
              : isOn
              ? theme.colors.secondary[0]
              : theme.colors.grey[0]
          }`,
          borderRadius: 20,
          opacity: 0.5,
        }}
      />

      <div
        style={{
          width: (size / 3) * 2,
          height: (size / 3) * 2,
          borderRadius: 20,
          backgroundColor: warn
            ? theme.colors.red[8]
            : isOn
            ? theme.colors.secondary[0]
            : theme.colors.grey[0],
          position: "absolute",
          top: (size - (size / 3) * 2) / 2,
          left: (size - (size / 3) * 2) / 2,
        }}
      />
    </div>
  );
};

export default StatusSign;

import React from "react";
import { Truck, Users, Key, Map2, ChartBar } from "tabler-icons-react";
import { ThemeIcon, UnstyledButton, Group, Text } from "@mantine/core";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import RouteList from "../routes/routes";
import { useSelector } from "react-redux";
import user, { getUser } from "../store/user";
import { i18n } from "../config/translation";
import { Vehicle } from "../model/Vehicle";
import vehicles, { getAllVehicles } from "../store/vehicles";

interface MainLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  path: string;
}

function NavLink({ icon, color, label, path }: MainLinkProps) {
  let resolved = useLocation();
  let isMatched = resolved.pathname.includes(path);

  return (
    <Link to={path} style={{ all: "unset" }}>
      <UnstyledButton
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.white,
          backgroundColor: isMatched
            ? // ? theme.colors.background[0]
              theme.colors.indigo
            : "transparent",
          "&:hover": {
            backgroundColor: theme.colors.background[0],
          },
        })}
      >
        <Group>
          <ThemeIcon
            color={color}
            variant={isMatched ? "filled" : "outline"}
            radius={"md"}
          >
            {icon}
          </ThemeIcon>

          <Text size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    </Link>
  );
}

export function NavLinks() {
  const user = useSelector(getUser);
  const vehicles = useSelector<Vehicle[]>(getAllVehicles);

  const data = [
    {
      icon: <Map2 size={16} />,
      color: "yellow",
      label: i18n.t("map"),
      path: RouteList.DASH_MAP,
    },
    {
      icon: <ChartBar size={16} />,
      color: "lime",
      label: i18n.t("statistics"),
      path: `${RouteList.STATS_MAIN}/${
        vehicles && vehicles[0] && vehicles[0].deviceIMEI
      }/time`,
    },
    {
      icon: <Truck size={16} />,
      color: "teal",
      label: i18n.t("vehicles"),
      path: RouteList.VEHICLE_LIST,
    },
    {
      icon: <Users size={16} />,
      color: "orange",
      label: i18n.t("drivers"),
      path: RouteList.DRIVER_LIST,
    },
  ];

  if (user && user.roles && user.roles.includes("admin")) {
    data.push({
      icon: <Key size={16} />,
      color: "pink",
      label: i18n.t("managers"),
      path: RouteList.MANAGER_LIST,
    });
  }

  const links = data.map((link) => <NavLink {...link} key={link.label} />);
  return <div>{links}</div>;
}

import {
  Group,
  ScrollArea,
  Space,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Box, Container, Grid } from "@mantine/core";
import moment from "moment";
import React from "react";
import { Note } from "tabler-icons-react";
import { i18n } from "../config/translation";
import { Vehicle } from "../model/Vehicle";
import { getDistance, getHours } from "../utility/recordFormatter";
import StatsBox, { StatType } from "./StatsBox";

interface SummaryBoxLiveProps {
  currentVehicle: Vehicle;
}

const SummaryBoxLive: React.FC<SummaryBoxLiveProps> = ({ currentVehicle }) => {
  const theme = useMantineTheme();
  const isOn = currentVehicle.latestRecord.ignition === "Ignition On";

  return (
    <Container
      p={"md"}
      pt="lg"
      sx={(theme) => ({
        backgroundColor: theme.colors.background[0],
        height: "55vh",
      })}
    >
      <ScrollArea type="scroll">
        <Grid pb={"xs"} align="stretch">
          <Grid.Col span={6}>
            <StatsBox
              type={StatType.speed}
              data={currentVehicle.latestRecord.speed}
              isOn={isOn}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <StatsBox
              type={StatType.distance}
              data={getDistance(currentVehicle)}
              isOn={isOn}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={4}>
            <StatsBox type={StatType.gas} data={"N/A"} isOn={isOn} />
          </Grid.Col>

          <Grid.Col span={4}>
            <StatsBox type={StatType.temperature} data={"N/A"} isOn={isOn} />
          </Grid.Col>
          <Grid.Col span={4}>
            <StatsBox
              type={StatType.time}
              data={getHours(currentVehicle)}
              isOn={isOn}
            />
          </Grid.Col>
        </Grid>
        {currentVehicle.note && (
          <Box pt="xs">
            <Group
              spacing={"xs"}
              p={"sm"}
              sx={(theme) => ({
                // backgroundColor:theme.colors.background[1],
              })}
            >
              <Note size={16} color={theme.colors.secondary[0]} />
              <Text size="sm">{i18n.t("note")}</Text>
            </Group>
            <Text px={"sm"} pb={"lg"} size="sm">
              {currentVehicle.note}
            </Text>
          </Box>
        )}

        <Text
          size="xs"
          color="dimmed"
          pt="sm"
          style={{ textAlign: "center" }}
        >{`${i18n.t("lastUpdate")}: ${moment(
          currentVehicle.latestRecord.timestamp
        ).format("Do MMMM YYYY, h:mm:ss a")}`}</Text>
      </ScrollArea>
    </Container>
  );
};

export default SummaryBoxLive;

import {
  Box,
  Button,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import moment from "moment";
import React, { useState } from "react";
import { Settings } from "tabler-icons-react";
import theme from "../config/theme";
import { i18n } from "../config/translation";
import { Vehicle } from "../model/Vehicle";
import { getDistance, getHours } from "../utility/recordFormatter";
import ImageWithPreview from "./ImageWithPreview";
import StatsBox, { StatType } from "./StatsBox";

interface VehicleDetailInfoProps {
  vehicle?: Vehicle;
}

const VehicleDetailInfo: React.FC<VehicleDetailInfoProps> = ({ vehicle }) => {
  const isOn =
    vehicle &&
    vehicle.latestRecord &&
    vehicle.latestRecord.ignition === "Ignition On";

  return (
    <Box pt={"md"}>
      <Group>
        <ImageWithPreview
          source={vehicle && vehicle.avatar}
          width={180}
          height={180}
          radius="md"
        />

        <Box
          sx={(theme) => ({
            backgroundColor: theme.colors.background[0],
            borderRadius: theme.radius.md,
            border: `1px solid ${theme.colors.border[0]}`,
            width: "calc(100% - 200px)",
          })}
        >
          <Stack spacing={0}>
            <Box p="xs" px="md">
              <SimpleGrid cols={2}>
                <Stack spacing={0}>
                  <Text color="dimmed" size="xs">
                    {i18n.t("name")}
                  </Text>
                  <Text size="sm">{vehicle && vehicle.name}</Text>
                </Stack>
                <Stack spacing={0}>
                  <Text color="dimmed" size="xs">
                    {i18n.t("license")}
                  </Text>
                  <Text size="sm">{vehicle && vehicle.license}</Text>
                </Stack>
              </SimpleGrid>
            </Box>

            <Box
              style={{ backgroundColor: theme.colors.background[1] }}
              p="xs"
              px="md"
            >
              <SimpleGrid cols={2}>
                <Stack spacing={0}>
                  <Text color="dimmed" size="xs">
                    {i18n.t("model")}
                  </Text>
                  <Text size="sm">{vehicle && vehicle.model}</Text>
                </Stack>
                <Stack spacing={0}>
                  <Text color="dimmed" size="xs">
                    {i18n.t("gpsInstalledAt")}
                  </Text>
                  <Text size="sm">
                    {vehicle
                      ? moment(vehicle.gpsInstalledDate).format("ll")
                      : ""}
                  </Text>
                </Stack>
              </SimpleGrid>
            </Box>
            <Box p="xs" px="md">
              <Text color="dimmed" size="xs">
                {i18n.t("note")}
              </Text>
              <Text size="sm">
                {vehicle && vehicle.note ? vehicle.note : i18n.t("noNote")}
              </Text>
            </Box>
          </Stack>
        </Box>
      </Group>
      {vehicle && vehicle.latestRecord && (
        <SimpleGrid cols={5} pt="md" spacing={"xs"}>
          <StatsBox
            small
            type={StatType.speed}
            data={vehicle.latestRecord.speed}
            isOn={isOn}
            backgroundColor={theme.colors.background[0]}
          />
          <StatsBox
            small
            type={StatType.distance}
            data={vehicle && getDistance(vehicle)}
            isOn={isOn}
            backgroundColor={theme.colors.background[0]}
          />
          <StatsBox
            small
            type={StatType.time}
            data={vehicle && getHours(vehicle)}
            isOn={isOn}
            backgroundColor={theme.colors.background[0]}
          />
          <StatsBox
            small
            type={StatType.gas}
            data={"N/A"}
            isOn={isOn}
            backgroundColor={theme.colors.background[0]}
          />
          <StatsBox
            small
            type={StatType.temperature}
            data={"N/A"}
            isOn={isOn}
            backgroundColor={theme.colors.background[0]}
          />
        </SimpleGrid>
      )}
    </Box>
  );
};

export default VehicleDetailInfo;

import {
  Badge,
  Card,
  Group,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from "@mantine/core";
import moment from "moment";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Dashboard, MapPin, Road } from "tabler-icons-react";
import { i18n } from "../config/translation";
import { Vehicle } from "../model/Vehicle";
import { getDistance } from "../utility/recordFormatter";
import PlaceText from "./PlaceText";

interface VehicleCardProps {
  data: Vehicle;
}

const VehicleCard: React.FC<VehicleCardProps> = ({ data }) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isOn = data.latestRecord.ignition === "Ignition On";
  const subsOver = useRef(moment(data.subscription.endAt) < moment());

  return (
    <Card
      onClick={() => navigate(`${data.deviceIMEI}`)}
      shadow="sm"
      p="md"
      radius={"md"}
      sx={(theme) => ({
        backgroundColor: theme.colors.background[1],
        border: `1px solid ${theme.colors.border[0]}`,

        "&:hover": {
          backgroundColor: theme.colors.background[0],
        },
      })}
    >
      <Group
        position="apart"
        style={{
          marginBottom: 5,
        }}
      >
        <Text weight={"bold"} size="sm">
          {data.name}
        </Text>
        {!subsOver.current && (
          <Badge variant="filled" color={isOn ? "indigo" : "dark"}>
            {isOn ? i18n.t("on") : i18n.t("off")}
          </Badge>
        )}
      </Group>
      {subsOver.current ? (
        <Text
          color="indigo"
          size="sm"
          style={{
            paddingTop: 5,
            fontStyle: "italic",
          }}
        >
          Subsciption is over. Please contact Bugitrack to renew.
        </Text>
      ) : (
        <>
          <Group spacing={5} mb="xs">
            <MapPin
              size={14}
              color={isOn ? theme.colors.secondary[0] : theme.colors.grey[0]}
            />
            <PlaceText
              coords={{
                latitude: data.latestRecord.latitude,
                longitude: data.latestRecord.longitude,
              }}
            >
              {(text) => (
                <Text size="xs" color="dimmed">
                  {text}
                </Text>
              )}
            </PlaceText>
          </Group>

          <Stack
            sx={(theme) => ({
              borderTop: `0.5px solid ${theme.colors.border[0]}`,
            })}
            pt="xs"
            pb="xs"
            spacing="xs"
          >
            <Group spacing={"xs"}>
              <ThemeIcon
                variant="outline"
                color={isOn ? "indigo" : "gray"}
                radius={"md"}
              >
                <Dashboard size={16} />
              </ThemeIcon>
              <Text size="xs">{data.latestRecord.speed} km/h</Text>
            </Group>

            <Group spacing={"xs"}>
              <ThemeIcon
                variant="outline"
                color={isOn ? "green" : "gray"}
                radius={"md"}
                sx={(theme) => ({
                  // backgroundColor: theme.colors.secondary[1],
                })}
              >
                <Road size={16} />
              </ThemeIcon>
              <Text size="xs">{getDistance(data)} km</Text>
            </Group>

            {data.hasRelay && data.latestRecord.DigitalOutput1 === 1 && (
              <Text
                color="red"
                style={{
                  paddingTop: 5,
                  fontStyle: "italic",
                }}
                size="sm"
              >
                {i18n.t("activated")}
              </Text>
            )}
          </Stack>
          {moment(data.subscription.endAt).diff(moment(), "days") < 7 && (
            <Text
              size="sm"
              style={{
                paddingTop: 5,
                fontStyle: "italic",
              }}
              color="red"
            >
              {i18n.t("subscriptionOverAt", {
                date: moment(data.subscription.endAt).format("ll"),
              })}
            </Text>
          )}
        </>
      )}
    </Card>
  );
};

export default VehicleCard;

import {
  Box,
  Container,
  Group,
  ScrollArea,
  Text,
  ThemeIcon,
  Timeline,
  useMantineTheme,
} from "@mantine/core";
import moment from "moment";
import React from "react";
import {
  CarCrash,
  GitBranch,
  LetterA,
  LetterB,
  LetterP,
  Parking,
  PlayerStop,
  Power,
  TruckDelivery,
  TruckOff,
  Video,
} from "tabler-icons-react";
import { i18n } from "../config/translation";
import { Coordinate } from "../model/Coordinate";
import { StopPoint } from "../model/Record";
import AppDatePicker from "./AppDatePicker";
import Empty from "./Empty";
import NetworkError from "./NetworkError";
import PlaceText from "./PlaceText";

interface VehicleSummaryTimelineProps {
  data?: StopPoint[];
  onDateChange: (date: Date) => void;
  onPressRouteInfo: (coords: Coordinate) => void;
  networkError?: boolean;
}

const VehicleSummaryTimeline: React.FC<VehicleSummaryTimelineProps> = ({
  data,
  onDateChange,
  onPressRouteInfo,
  networkError,
}) => {
  const theme = useMantineTheme();

  const renderTime = (start: Date, end?: Date) => {
    const diff = end && moment.duration(moment(end).diff(start));
    /* @ts-ignore */
    const minutes = Math.floor(diff / 60000);
    const formatToHour = (min: number) => {
      const hour = Math.floor(min / 60);
      const minute = min % 60;

      return `${hour} ${i18n.t("hours")} ${minute}`;
    };

    return end
      ? `${moment(start).format("H:mm")} ~ ${moment(end).format("H:mm")} (${
          minutes > 59 ? formatToHour(minutes) : minutes
        } ${i18n.t("minutes")})`
      : moment(start).format("H:mm");
  };

  return (
    <ScrollArea
      style={{ height: "55vh", paddingLeft: 15 }}
      scrollbarSize={4}
      type="scroll"
    >
      <Group mt="md" position="left">
        <AppDatePicker onChange={onDateChange} />
      </Group>
      {networkError && <NetworkError height={300} />}

      {data && data.length > 0 ? (
        <Timeline
          bulletSize={20}
          lineWidth={2}
          p="md"
          mx="lg"
          styles={{
            itemTitle: { color: theme.white, fontSize: 14 },
          }}
        >
          {data.map((item, index) => (
            <Timeline.Item
              key={index.toString()}
              onClick={() => onPressRouteInfo(item.coords)}
              active
              sx={{
                "&:hover": {
                  curser: "pointer",
                },
              }}
              bullet={
                <ThemeIcon
                  size={25}
                  radius="xl"
                  sx={(theme) => ({
                    border: `3px solid ${theme.colors.primary[0]}`,
                    backgroundColor:
                      item.type === "park"
                        ? theme.white
                        : theme.colors.primary[0],
                  })}
                >
                  {item.type === "start" ? (
                    <LetterA size={14} />
                  ) : item.type === "end" ? (
                    <LetterB size={14} />
                  ) : (
                    <LetterP size={14} color={theme.colors.primary[0]} />
                  )}
                </ThemeIcon>
              }
              title={
                item.type === "start"
                  ? i18n.t("depart")
                  : item.type === "end"
                  ? i18n.t("arrive")
                  : i18n.t("park")
              }
            >
              <PlaceText coords={item.coords}>
                {(text) => (
                  <Text color="dimmed" size="xs">
                    {text}
                  </Text>
                )}
              </PlaceText>

              <Text size="xs" mt={4}>
                {renderTime(item.startAt, item.endAt)}
              </Text>
            </Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <Empty height={300} />
      )}
    </ScrollArea>
  );
};

export default VehicleSummaryTimeline;

import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Drawer,
  Group,
  LoadingOverlay,
  ScrollArea,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useContext, useState } from "react";
import { Plus, Trash } from "tabler-icons-react";
import companyService from "../api/companyService";
import logService from "../api/logService";
import { CompanyContext } from "../context/CompanyContext";
import { Company } from "../model/Company";
import AppLoadingOverlay from "./AppLoadingOverlay";
import DeleteButton from "./DeleteButton";
import ImageInput from "./ImageInput";

interface NewCompanyFormProps {
  visible: boolean;
  onClose: () => void;
  onUpdate?: (company: Company) => void;
  initialData?: Company;
}

const NewCompanyForm: React.FC<NewCompanyFormProps> = ({
  visible,
  onClose,
  onUpdate,
  initialData,
}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const { setCompanyName, setTrustedContacts } = useContext(CompanyContext);

  const textInputStyle = {
    filledVariant: {
      backgroundColor: theme.colors.background[1],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
  };

  const form = useForm({
    initialValues: {
      name: initialData ? initialData.name : "",
      trustedContacts: initialData ? initialData.trustedContacts : [],
      note: initialData ? initialData.note : "",
      avatar: initialData ? initialData.avatar : undefined,
      isOperating: initialData ? initialData.isOperating : true,
    },

    validate: {
      name: (value) =>
        value.length < 3 ? "Name must have at least 3 letters" : null,
      // trustedContactName: (value) =>
      //   value.length < 3 ? "Name must have at least 3 letters" : null,
      // trustedContactNumber: (value) =>
      //   value.length < 10 ? "Wrong format" : null,
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    setLoading(true);

    try {
      const result = initialData
        ? await companyService.updateCompany({
            ...values,
            _id: initialData._id,
          })
        : await companyService.createCompany(values);

      showNotification({
        title: `Successfully ${initialData ? "updated" : "created"}!`,
        message: `${values.name} is ${initialData ? "updated" : "created"}.`,
        color: "indigo",
      });

      onUpdate && onUpdate(result.data);
      setCompanyName(result.data.name);
      setTrustedContacts(result.data.trustedContacts);
      setLoading(false);
      form.reset();
      onClose();
    } catch (ex) {
      if (ex.response.status === 400)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      setLoading(false);
      logService.log(ex);
    }
  };

  const fields =
    form.values.trustedContacts &&
    form.values.trustedContacts.map((item, index) => (
      <Group key={index} mt="xs">
        <TextInput
          styles={textInputStyle}
          placeholder="Phone number"
          radius={"xl"}
          required
          sx={{ flex: 1 }}
          value={item}
          onChange={(event) => {
            const newData = [...form.values.trustedContacts];
            newData[index] = event.currentTarget.value;
            form.setFieldValue("trustedContacts", newData);
          }}
        />
        <ActionIcon
          color="red"
          variant="hover"
          onClick={() => {
            const newData = [...form.values.trustedContacts];
            newData.splice(index, 1);
            form.setFieldValue("trustedContacts", newData);
          }}
        >
          <Trash size={16} />
        </ActionIcon>
      </Group>
    ));

  return (
    <Drawer
      opened={visible}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title={`${initialData ? "Update" : "Create"} company`}
      size="xl"
      styles={{
        title: { fontWeight: 600 },
        header: {
          borderBottom: `0.5px solid ${theme.colors.border[0]}`,
          padding: theme.spacing.xl,
          paddingBottom: theme.spacing.xs,
        },
        drawer: {
          backgroundColor: theme.colors.background[0],
          borderRight: `1px solid ${theme.colors.border[0]}`,
        },
      }}
    >
      <div style={{ position: "relative" }}>
        <AppLoadingOverlay loading={loading} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <ScrollArea
            px="xl"
            style={{
              height: "calc(100vh - 140px)",
            }}
          >
            <Stack>
              <Text weight={"bold"}>Company information</Text>
              <Stack px="md">
                <TextInput
                  radius={"xl"}
                  label="Name"
                  value={form.values.name}
                  placeholder="Company name"
                  onChange={(event) =>
                    form.setFieldValue("name", event.currentTarget.value)
                  }
                  required
                  styles={textInputStyle}
                  {...form.getInputProps("name")}
                />
                <ImageInput
                  onDrop={(file) => form.setFieldValue("avatar", file)}
                  value={form.values.avatar}
                  text="Company avatar, click to upload"
                />
                <Textarea
                  radius={"lg"}
                  label="Note"
                  value={form.values.note}
                  placeholder="Note"
                  onChange={(event) =>
                    form.setFieldValue("note", event.currentTarget.value)
                  }
                  styles={textInputStyle}
                  {...form.getInputProps("note")}
                />
              </Stack>
              <Text weight={"bold"} pt="lg">
                Trusted contacts
              </Text>
              <Stack px="md">
                {fields}
                <Button
                  mt={"xs"}
                  size="xs"
                  radius={"lg"}
                  color="indigo"
                  variant="filled"
                  leftIcon={<Plus size={14} />}
                  onClick={() => {
                    const newData = [...form.values.trustedContacts];
                    newData.push("");
                    form.setFieldValue("trustedContacts", newData);
                  }}
                  style={{ zIndex: 10 }}
                >
                  Add trusted contact
                </Button>
              </Stack>

              <Text weight={"bold"} pt="lg">
                Operate status
              </Text>
              <Stack px="md" mb={50}>
                <Switch
                  checked={form.values.isOperating}
                  onChange={(e) =>
                    form.setFieldValue("isOperating", e.currentTarget.checked)
                  }
                  color="indigo"
                  label={`This company is ${
                    form.values.isOperating ? "" : "not"
                  } operating currently`}
                  {...form.getInputProps("isOperating")}
                />
              </Stack>
            </Stack>
          </ScrollArea>
          <Box
            p="sm"
            px="lg"
            style={{ borderTop: `1px solid ${theme.colors.border[0]}` }}
          >
            <Button type="submit" color="indigo" radius={"lg"} fullWidth>
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </Drawer>
  );
};

export default NewCompanyForm;

import {
  ActionIcon,
  CloseButton,
  Group,
  Image,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Photo, Trash } from "tabler-icons-react";
import imageService from "../api/imageService";
import logService from "../api/logService";
import { i18n } from "../config/translation";
import AppLoadingOverlay from "./AppLoadingOverlay";

interface ImageInputProps {
  onDrop: (any) => void;
  onReject?: () => void;
  value?: any;
  text?: string;
}

const ImageInput: React.FC<ImageInputProps> = ({
  onDrop,
  onReject,
  value,
  text,
}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);

  const handleUpload = async (file) => {
    if (!file) return onDrop(undefined);

    setLoading(true);
    try {
      const result = await imageService.uploadImage(file);
      console.log(result.data);
      onDrop(result.data);
    } catch (ex) {
      if (ex.response.status === 401)
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      logService.log(ex);
    }

    setLoading(false);
  };

  const dropzoneChildren = () => (
    <Group
      position="center"
      spacing="md"
      style={{ minHeight: 100, pointerEvents: "none", position: "relative" }}
    >
      <AppLoadingOverlay loading={loading} />
      <Photo size={30} />

      <div>
        <Text size="md" inline>
          {text ?? i18n.t("dragImage")}
        </Text>
      </div>
    </Group>
  );

  return value ? (
    <Stack>
      <Group>
        <img
          alt=""
          src={value}
          style={{
            maxHeight: 100,
            width: 100,
            overflow: "hidden",
            objectFit: "cover",
            borderRadius: 10,
          }}
        />
      </Group>

      <Stack spacing={"xs"}>
        <Group spacing="sm">
          <Photo style={{ color: theme.colors.secondary[0] }} size={18} />
          <Text size="xs">{i18n.t("imageUploaded")}</Text>
          <ActionIcon color="indigo" onClick={() => handleUpload(undefined)}>
            <Trash />
          </ActionIcon>
        </Group>

        <Text size="xs" color="dimmed">
          {value.name}
        </Text>
      </Stack>
    </Stack>
  ) : (
    <Dropzone
      multiple={false}
      onDrop={(files) => handleUpload(files[0])}
      onReject={(files) => console.log("rejected files", files)}
      maxSize={3 * 1024 ** 2}
      accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
      radius={"lg"}
      disabled={value}
      sx={(theme) =>
        value && {
          backgroundColor: theme.colors.background[0],
          borderColor: theme.colors.dark[5],
          cursor: "not-allowed",
          "&:hover": { backgroundColor: theme.colors.background[0] },
        }
      }
      styles={{
        root: {
          backgroundColor: theme.colors.background[1],
          borderColor: theme.colors.dark[4],
          "&:hover": { backgroundColor: theme.colors.primary[1] },
        },
      }}
    >
      {() => dropzoneChildren()}
    </Dropzone>
  );
};

export default ImageInput;

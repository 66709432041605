import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { Driver } from "../model/Driver";
import { Vehicle } from "../model/Vehicle";

interface VehiclesState {
  [deviceIMEI: string]: Vehicle;
}

const initialState = {} as VehiclesState;

const slice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    vehiclesAdded: (vehicles, action) => {
      const result = {} as VehiclesState;
      const { records } = action.payload;
      const { vehicles: vehicleList } = action.payload;

      for (let index = 0; index < vehicleList.length; index++) {
        result[vehicleList[index].deviceIMEI] = { ...vehicleList[index] };
      }

      for (let index = 0; index < records.length; index++) {
        const element = records[index][0];
        result[element.deviceIMEI] = {
          ...result[element.deviceIMEI],
          latestRecord: { ...element },
        };
      }

      //   console.log("VEHICLE RECORDS: ", result);
      return result;
    },
    driverUpdated: (vehicles, action) => {
      vehicles[action.payload.deviceIMEI].driver = action.payload.driver;
    },
    latestRecordUpdated: (vehicles, action) => {
      vehicles[action.payload.deviceIMEI].latestRecord = { ...action.payload };
      // console.log(`record for ${action.payload.deviceIMEI} updated!`);
    },
    infoUpdated: (vehicles, action) => {
      vehicles[action.payload.deviceIMEI] = {
        ...vehicles[action.payload.deviceIMEI],
        ...action.payload.data,
      };
    },
    initialRecordAdded: (vehicles, action) => {
      action.payload.forEach((record: any) => {
        if (!record.length) return;
        vehicles[record[0].deviceIMEI].initialRecord = { ...record[0] };
      });
    },
    vehicleReset: (vehicles) => {
      vehicles = {};
    },
  },
});

export const {
  vehiclesAdded,
  driverUpdated,
  infoUpdated,
  latestRecordUpdated,
  initialRecordAdded,
  vehicleReset,
} = slice.actions;
export default slice.reducer;

// Action creators

export const addVehicles = (data: { vehicles: Vehicle[]; records: any[] }) =>
  vehiclesAdded(data);

export const assignDriver = (deviceIMEI: string, driver: Driver | null) =>
  driverUpdated({ deviceIMEI, driver });

export const updateLatestRecord = (record: any) => latestRecordUpdated(record);

export const addInitialRecord = (records: any) => initialRecordAdded(records);

export const updateInfo = (
  deviceIMEI: string,
  data: { name: string; avatar?: string; note?: string }
) => infoUpdated({ deviceIMEI, data });

export const updateLimit = (
  deviceIMEI: string,
  data: { speedLimit: number; timeLimit: number }
) => infoUpdated({ deviceIMEI, data });

export const resetVehicle = () => vehicleReset();

// Selectors

export const getAllVehicles = createSelector(
  (state: any) => state.entities.vehicles,
  (vehicles) => Object.values(vehicles)
);

export const getOnVehicles = createSelector(
  (state: any) => state.entities.vehicles,
  (vehicles) => {
    let result: Vehicle[] = [];
    Object.values(vehicles).forEach((item) => {
      // @ts-ignore
      if (item.latestRecord.ignition === "Ignition On") result.push(item);
    });

    return result;
  }
);

export const getOffVehicles = createSelector(
  (state: any) => state.entities.vehicles,
  (vehicles) => {
    let result: Vehicle[] = [];
    Object.values(vehicles).forEach((item) => {
      // @ts-ignore
      if (item.latestRecord.ignition === "Ignition Off") result.push(item);
    });

    return result;
  }
);

export const getVehicle = createSelector(
  [
    (state: any) => state.entities.vehicles,
    (vehicles, deviceIMEI) => deviceIMEI,
  ],
  (items, deviceIMEI) => items[deviceIMEI]
);

import {
  Drawer,
  Group,
  MantineTheme,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
  Image,
  Button,
  createStyles,
  Box,
  Title,
  CloseButton,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import logService from "../api/logService";
import vehicleService from "../api/vehicleService";
import { i18n } from "../config/translation";
import { Vehicle } from "../model/Vehicle";
import { updateInfo } from "../store/vehicles";
import ImageInput from "./ImageInput";

interface EditVehicleDrawerProps {
  onClose: () => void;
  initialData?: Vehicle;
}

const EditVehicleDrawer: React.FC<EditVehicleDrawerProps> = ({
  onClose,
  initialData,
}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const form = useForm({
    initialValues: {
      name: initialData && initialData.name,
      note: initialData && initialData.note,
      avatar: initialData && initialData.avatar,
    },

    validate: {
      name: (value) =>
        value.length < 3
          ? i18n.t("formMin", { field: i18n.t("name"), num: 3 })
          : null,
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await vehicleService.updateVehicleForUser(initialData._id, values);
      dispatch(updateInfo(initialData.deviceIMEI, values));
      showNotification({
        title: i18n.t("updatedSuccess"),
        message: i18n.t("isUpdated", { name: values.name }),
        color: "indigo",
      });
      onClose();
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 404) {
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      }
      logService.log(error);
    }
    setLoading(false);
  };

  const textInputStyle = {
    filledVariant: {
      backgroundColor: theme.colors.background[1],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
  };

  return (
    <div>
      <Group position="apart" pb="md">
        <Title order={3}>{i18n.t("editVehicle")}</Title>

        <CloseButton onClick={onClose} />
      </Group>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput
            radius={"xl"}
            label={i18n.t("name")}
            value={form.values.name}
            placeholder={i18n.t("name")}
            onChange={(event) =>
              form.setFieldValue("name", event.currentTarget.value)
            }
            required
            styles={textInputStyle}
            {...form.getInputProps("name")}
          />

          <Textarea
            radius={"lg"}
            label={i18n.t("note")}
            value={form.values.note}
            placeholder={i18n.t("note")}
            onChange={(event) =>
              form.setFieldValue("note", event.currentTarget.value)
            }
            styles={textInputStyle}
            {...form.getInputProps("note")}
          />
          <Box>
            <Group pb="xs" position="apart">
              <Text size="sm">{i18n.t("vehicleImage")}</Text>
            </Group>
            <ImageInput
              onDrop={(file) => form.setFieldValue("avatar", file)}
              value={form.values.avatar}
            />
          </Box>
        </Stack>
        <Group position="right" mt="xl">
          <Button
            type="submit"
            color="indigo"
            radius={"lg"}
            fullWidth
            loading={loading}
          >
            {i18n.t("save")}
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default EditVehicleDrawer;

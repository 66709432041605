import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { RootState } from "./entities";

export interface UserState {
  _id: string;
  userName: string;
  email: string;
  company: string;
  dateJoined: string;
  isAdmin: boolean;
  avatar?: string;
  phone?: string;
  roles?: string[];
}

const initialState = { userName: "Someone" } as Partial<UserState>;

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userAdded: (user, action) => {
      return action.payload;
    },
    userNameUpdated: (user, action) => {
      user.userName = action.payload;
    },
    userReset: (user) => {
      user = initialState;
    },
  },
});

export const { userAdded, userNameUpdated, userReset } = slice.actions;
export default slice.reducer;

// Action creators

export const addUser = (data: UserState) => {
  console.log("get user action dispatched");
  return userAdded(data);
};

export const updateUserName = (userName: string) => userNameUpdated(userName);

// Selectors

export const getUser = createSelector(
  (state: any) => state.entities.user,
  (user) => user
);

// actions
export const userLoggedOut = createAction("user/loggedOut");
export const resetUser = () => userReset();

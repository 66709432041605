import {
  ActionIcon,
  Box,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  AlertCircle,
  Clock,
  SteeringWheel,
  Truck,
  X,
} from "tabler-icons-react";
import { i18n } from "../config/translation";
import { Notification } from "../model/Notification";
import { getVehicle } from "../store/vehicles";
import { historyStyle } from "../utility/timeFormatter";

interface NotificationCardProps {
  item: Notification;
  onDelete: (id: string) => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  item,
  onDelete,
}) => {
  const theme = useMantineTheme();
  const vehicle = useSelector((state) => getVehicle(state, item.deviceIMEI));

  return (
    <Box style={{ position: "relative" }}>
      <ActionIcon
        style={{
          position: "absolute",
          top: -10,
          right: -5,
        }}
        variant="filled"
        radius={"xl"}
        size="sm"
        onClick={() => onDelete(item._id)}
      >
        <X size={16} />
      </ActionIcon>
      <Box
        p="xs"
        sx={(theme) => ({
          backgroundColor: theme.colors.background[1],
          borderRadius: theme.radius.sm,
          border: `1px solid ${theme.colors.border[0]}`,
        })}
      >
        <Group spacing={"xs"} align="flex-start">
          {item.notificationType === "speed" ? (
            <AlertCircle size={20} color={theme.colors.secondary[0]} />
          ) : (
            <Clock size={20} color={theme.colors.secondary[0]} />
          )}
          <Stack style={{ width: "calc(100% - 30px)" }} spacing="xs">
            <Group position="apart">
              <Text size="sm">
                {item.notificationType === "speed"
                  ? i18n.t("overSpeed")
                  : i18n.t("overTime")}
              </Text>
              <Text size="xs" color="dimmed">
                {historyStyle(item.timestamp)}
              </Text>
            </Group>
            <Text size="xs" color="dimmed">
              {item.notificationType === "speed"
                ? i18n.t("overSpeedDesc", {
                    vehicle: vehicle.name,
                    speedLimit: item.speedLimit,
                    bySpeed: item.speed - item.speedLimit,
                  })
                : i18n.t("overTimeDesc", {
                    vehicle: vehicle.name,
                    timeLimit: item.timeLimit,
                  })}
            </Text>
            <Group spacing={"lg"}>
              <Group spacing={5}>
                <Truck size={14} />
                <Text size="xs">{vehicle.name}</Text>
              </Group>

              <Group spacing={5}>
                <SteeringWheel size={14} />
                <Text size="xs">
                  {vehicle.driver ? vehicle.driver.name : "Not assigned"}
                </Text>
              </Group>
            </Group>
          </Stack>
        </Group>
      </Box>
    </Box>
  );
};

function shouldUpdate(prevProps, nextProps) {
  return prevProps._id === nextProps._id;
}

export default memo(NotificationCard, shouldUpdate);

import http from "./httpService";

const apiEndPoint = "/reports";

function getTimeUsageReport(deviceIMEI: string, timestamp: Date) {
  return http.get(`${apiEndPoint}/timeUsage/${deviceIMEI}/${timestamp}`);
}

const getDrivingDistanceReport = (deviceIMEI: string, timestamp: Date) =>
  http.get(`${apiEndPoint}/drivingDistance/${deviceIMEI}/${timestamp}`);

const getDrivingSummaryReport = (deviceIMEI: string, start: Date, end: Date) =>
  http.get(`${apiEndPoint}/distanceSummary/${deviceIMEI}/${start}/${end}`);

const getTimeSummaryReport = (deviceIMEI: string, start: Date, end: Date) =>
  http.get(`${apiEndPoint}/timeSummary/${deviceIMEI}/${start}/${end}`);

export default {
  getTimeUsageReport,
  getDrivingDistanceReport,
  getDrivingSummaryReport,
  getTimeSummaryReport,
};

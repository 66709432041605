import { Box, Stack, Text, useMantineTheme } from "@mantine/core";
import React from "react";
import { FileSearch, WifiOff } from "tabler-icons-react";

interface NetworkErrorProps {
  title?: string;
  height?: any;
}

const NetworkError: React.FC<NetworkErrorProps> = ({
  title = "Network error",
  height,
}) => {
  const theme = useMantineTheme();
  return (
    <Stack
      style={{ width: "100%", height: height ?? "100%" }}
      justify="center"
      align={"center"}
      py="xl"
    >
      <WifiOff size={30} color={theme.colors.primary[0]} />
      <Text size="sm" color="dimmed">
        {title}
      </Text>
    </Stack>
  );
};

export default NetworkError;

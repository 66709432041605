import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { RootState } from "./entities";

export interface UiControlsState {
  socketStatus: "connected" | "disconnected";
}

const initialState = { socketStatus: "disconnected" } as UiControlsState;

const slice = createSlice({
  name: "uiControls",
  initialState,
  reducers: {
    socketConnected: (uiControls) => {
      uiControls.socketStatus = "connected";
    },
    socketDisconnected: (uiControls, action) => {
      uiControls.socketStatus = "disconnected";
    },
  },
});

export const { socketConnected, socketDisconnected } = slice.actions;
export default slice.reducer;

// Action creators

export const connectSocket = () => socketConnected();
export const disconnectSocket = socketDisconnected;

// Selectors

export const getSocketStatus = createSelector(
  (state: any) => state.entities.uiControls,
  (uiControls) => uiControls.socketStatus
);

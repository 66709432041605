import http from "./httpService";

const apiEndPoint = "/notifications";

export function getNotifications(skip) {
  return http.get(`${apiEndPoint}/all/${skip}`);
}

export function deleteNotification(id: string) {
  return http.delete(`${apiEndPoint}/${id}`);
}

export default {
  getNotifications,
  deleteNotification,
};

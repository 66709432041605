import { MantineThemeOverride } from "@mantine/core";

const theme: MantineThemeOverride = {
  colorScheme: "dark",

  colors: {
    primary: ["#3D64FF", "#1D2C5E"],
    secondary: ["#2DFFD0", "#194B51"],
    background: ["#181D34", "#1E2746"],
    grey: ["#b2b2b2"],
    border: ["rgba(255,255,255, 0.2)"],
  },
  primaryColor: "primary",

  fontFamily: "Open sans, sans-serif",
};

export const routeColors = [
  "#fa5252",
  "#e64980",
  "#be4bdb",
  "#7950f2",
  "#4c6ef5",
  "#228be6",
  "#15aabf",
  "#12b886",
  "#40c057",
  "#82c91e",
  "#fab005",
  "#fd7e14",
];

export default theme;

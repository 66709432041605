import { Avatar, Button, Group, Stack, Title, Text } from "@mantine/core";
import { Phone, Settings } from "tabler-icons-react";
import { i18n } from "../config/translation";
import { Driver } from "../model/Driver";

interface EmployeeDetailProps {
  data: Driver;
  onEdit: () => void;
}

const EmployeeDetail: React.FC<EmployeeDetailProps> = ({ data, onEdit }) => {
  return (
    <>
      <Group position="apart">
        <Title order={3}>{i18n.t("detail")}</Title>
        <Button
          size="xs"
          radius={"xl"}
          leftIcon={<Settings size={14} />}
          color="indigo"
          variant="outline"
          onClick={onEdit}
        >
          {i18n.t("edit")}
        </Button>
      </Group>
      <Stack align={"center"} mt="xl" pt="xl">
        <Avatar
          src={data.avatar}
          sx={(theme) => ({
            height: 150,
            width: 150,
            borderRadius: 100,
          })}
          color="indigo"
        />
        <Title>{data.name}</Title>
        <Stack spacing={"xs"} mt={"md"}>
          {data.phone && data.phone.length > 0 ? (
            data.phone.map((item, index) => (
              <Group key={index.toString()}>
                <Phone size={15} />
                <Text color={"gray"}>{item}</Text>
              </Group>
            ))
          ) : (
            <Text color={"gray"}>{i18n.t("noPhoneData")}</Text>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default EmployeeDetail;

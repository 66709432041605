import { Button, Box, Group, Modal, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import logService from "../api/logService";

interface DeleteButtonProps {
  style?: any;
  api: () => void;
  cb?: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ style, api, cb }) => {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const CODE = "Bugitrack.";

  const form = useForm({
    initialValues: { code: undefined },
    validate: {
      code: (value) => (value !== CODE ? "Wrong confirmation code" : null),
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await api();
      cb && cb();
      setOpened(false);
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      logService.log(ex);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Modal
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        title="Please enter confirmation code."
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            radius={"xl"}
            mb="md"
            placeholder="Confirmation code"
            value={form.values.code}
            onChange={(event) =>
              form.setFieldValue("code", event.currentTarget.value)
            }
            required
            {...form.getInputProps("code")}
          />
          <Button
            radius={"xl"}
            color="indigo"
            style={{ float: "right" }}
            type="submit"
            loading={loading}
          >
            Delete
          </Button>
        </form>
      </Modal>
      <Group position="right" style={style}>
        <Button
          radius={"lg"}
          color="red"
          mb={"xl"}
          variant="outline"
          onClick={() => setOpened(true)}
        >
          Delete
        </Button>
      </Group>
    </Box>
  );
};

export default DeleteButton;

import { Box, useMantineTheme } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState } from "react";

interface AppDatePickerProps {
  placeholder?: string;
  label?: string;
  required?: boolean;
  value?: Date;
  onChange?: (Date) => void;
  color?: string;
}

const AppDatePicker: React.FC<AppDatePickerProps> = ({
  placeholder,
  label,
  required,
  value,
  onChange,
  color,
}) => {
  const theme = useMantineTheme();
  const minDate = new Date();
  minDate.setMonth(minDate.getMonth() - 1);
  const [date, setDate] = useState(value ?? new Date());

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: color ? color : theme.colors.background[0],
        fontWeight: 400,
        height: 33,
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.border[0]}`,
      })}
    >
      <DatePicker
        placeholder={placeholder}
        label={label}
        variant="unstyled"
        required={required}
        value={value ?? date}
        onChange={(date) => {
          onChange(date);
          !value && setDate(date);
        }}
        size="xs"
        clearable={false}
        inputFormat={"MMM D"}
        maxDate={new Date()}
        minDate={minDate}
        firstDayOfWeek="sunday"
        initialLevel={"date"}
        styles={{
          input: {
            color: theme.white,
            width: 70,
            textAlign: "center",
          },
          dropdown: {
            backgroundColor: theme.colors.background[0],
            border: `1px solid ${theme.colors.border[0]}`,
          },
          day: {
            "&:hover": {
              backgroundColor: theme.colors.dark[8],
            },
          },
          selected: {
            backgroundColor: theme.colors.background[1],
          },
        }}
      />
    </Box>
  );
};

export default AppDatePicker;

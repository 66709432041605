import React, { useEffect, useState } from "react";
import { MyAppShell } from "../components/MyAppShell";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import RouteList from "./routes";
import FetchingScreen from "../components/FetchingScreen";
import { useSelector } from "react-redux";
import authService from "../api/authService";
import { getCompany } from "../store/company";

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = ({}) => {
  const [fetching, setFetching] = useState(false);
  const company = useSelector(getCompany);
  const user = authService.getCurrentUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && JSON.stringify(company) === JSON.stringify({})) {
      setFetching(true);
    }
    if (user && pathname === "/" + RouteList.DASH_MAIN)
      navigate(RouteList.DASH_MAP, { replace: true });
  }, []);

  if (!user) return <Navigate replace to={`../${RouteList.AUTH_LOGIN}`} />;

  return (
    <div style={{ position: "relative" }}>
      {fetching && <FetchingScreen doCb={false} />}
      <MyAppShell>
        <Outlet />
      </MyAppShell>
    </div>
  );
};

export default Dashboard;

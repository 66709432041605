import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Drawer,
  Group,
  ScrollArea,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
  TransferList,
  TransferListItemComponentProps,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Plus, Trash } from "tabler-icons-react";
import logService from "../api/logService";
import userService from "../api/userService";
import vehicleService from "../api/vehicleService";
import { i18n } from "../config/translation";
import { User } from "../model/User";
import AppLoadingOverlay from "./AppLoadingOverlay";
import ImageInput from "./ImageInput";

interface NewAccountFormProps {
  visible: boolean;
  onClose: () => void;
  companyId: string;
  initialData?: User;
  onUpdate?: (values: User) => void;
}

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const vehicleListFactory = (list) => {
  return list.map((d) => ({
    value: d._id,
    label: d.name,
    ...d,
  }));
};

const NewAccountForm: React.FC<NewAccountFormProps> = ({
  visible,
  onClose,
  companyId,
  initialData,
  onUpdate,
}) => {
  const theme = useMantineTheme();
  const [checked, setChecked] = useState(false);
  const [vehicleList, setVehicleList] = useState([
    [],
    initialData ? vehicleListFactory(initialData.vehicles) : [],
  ]);
  const [admin, setAdmin] = useState(false);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVehicles();
    if (initialData) setAdmin(initialData.roles.includes("admin"));
  }, [initialData]);

  const getVehicles = async () => {
    setVehicleLoading(true);
    try {
      const result = await vehicleService.getVehiclesToAssign(companyId);
      setVehicleList([
        initialData
          ? vehicleListFactory(
              result.data.filter(
                (d) =>
                  // @ts-expect-error
                  initialData.vehicles.findIndex((v) => v._id === d._id) < 0
              )
            )
          : vehicleListFactory(result.data),
        initialData ? vehicleListFactory(initialData.vehicles) : [],
      ]);
      setVehicleLoading(false);
    } catch (ex) {}
  };

  const textInputStyle = {
    filledVariant: {
      backgroundColor: theme.colors.background[1],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
  };

  const form = useForm({
    initialValues: {
      email: undefined,
      password: undefined,
      userName: initialData ? initialData.userName : undefined,
      phones: initialData ? initialData.phones : [""],
      avatar: initialData ? initialData.avatar : undefined,
      roles: initialData ? initialData.roles : ["manager"],
    },

    validate: initialData
      ? {
          userName: (value) =>
            value.length < 3
              ? i18n.t("formMin", { field: i18n.t("username"), num: 3 })
              : null,
        }
      : {
          password: (value) =>
            value.length < 8
              ? i18n.t("formMin", { field: i18n.t("password"), num: 8 })
              : null,
          userName: (value) =>
            value.length < 3
              ? i18n.t("formMin", { field: i18n.t("username"), num: 3 })
              : null,
          email: (value) =>
            !validateEmail(value) ? i18n.t("invalidEmail") : null,
        },
  });

  const handleClose = () => {
    setAdmin(initialData ? initialData.roles.includes("admin") : false);
    setVehicleList([
      [...vehicleList[0]],
      initialData ? vehicleListFactory(initialData.vehicles) : [],
    ]);
    setChecked(false);
    form.reset();
    onClose();
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const payload = {
      ...values,
      vehicles: vehicleList[1].map((v) => v._id),
      company: companyId,
    };

    if (initialData) {
      payload._id = initialData._id;
      delete payload.email;
      delete payload.password;
    }

    try {
      const result = initialData
        ? await userService.updateUser(payload)
        : await userService.createUser(payload);
      onUpdate &&
        onUpdate(
          initialData
            ? {
                ...payload,
                email: initialData.email,
                vehicles: vehicleList[1],
              }
            : { ...payload, _id: result.data.user._id }
        );

      showNotification({
        title: !initialData
          ? i18n.t("createdSuccessfully")
          : i18n.t("updatedSuccess"),
        message: initialData
          ? i18n.t("isUpdated", { name: values.userName })
          : i18n.t("isCreated", { name: values.userName }),
        color: "indigo",
      });

      setLoading(false);
      handleClose();
    } catch (ex) {
      if (ex.response.status === 400 || ex.response.status === 404) {
        if (!initialData && ex.response.status === 400) {
          form.setFieldError("email", i18n.t("registeredEmail"));

          showNotification({
            title: i18n.t("registeredEmail"),
            message: i18n.t("tryAnotherEmail"),
            color: "red",
          });
        } else
          showNotification({
            title: i18n.t("somethingWrong"),
            message: i18n.t("tryAgain"),
            color: "red",
          });
      }

      logService.log(ex);
    }
    setLoading(false);
  };

  const fields = form.values.phones.map((item, index) => (
    <Group key={index} mt="xs">
      <TextInput
        styles={textInputStyle}
        placeholder={i18n.t("phone")}
        radius={"xl"}
        required
        sx={{ flex: 1 }}
        value={item}
        onChange={(event) => {
          const newData = [...form.values.phones];
          newData[index] = event.currentTarget.value;
          form.setFieldValue("phones", newData);
        }}
      />
      <ActionIcon
        color="red"
        variant="hover"
        onClick={() => {
          if (form.values.phones.length > 1) {
            const newData = [...form.values.phones];
            newData.splice(index, 1);
            form.setFieldValue("phones", newData);
          } else {
            form.setFieldValue("phones", [""]);
          }
        }}
      >
        <Trash size={16} />
      </ActionIcon>
    </Group>
  ));

  return (
    <Drawer
      opened={visible}
      onClose={handleClose}
      title={initialData ? i18n.t("updateAccount") : i18n.t("newAccount")}
      // padding="xl"
      size={800}
      styles={{
        title: { fontWeight: 600 },
        header: {
          borderBottom: `0.5px solid ${theme.colors.border[0]}`,
          padding: theme.spacing.xl,
          paddingBottom: theme.spacing.xs,
        },
        drawer: {
          backgroundColor: theme.colors.background[0],
          borderRight: `1px solid ${theme.colors.border[0]}`,
        },
      }}
    >
      <div style={{ position: "relative" }}>
        <AppLoadingOverlay loading={loading} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <ScrollArea
            px="xl"
            style={{
              height: "calc(100vh - 140px)",
            }}
          >
            <Stack>
              <Text weight={"bold"}>{i18n.t("accountInfo")}</Text>
              <SimpleGrid cols={2}>
                {!initialData && (
                  <Stack px="md">
                    <TextInput
                      radius={"xl"}
                      label={i18n.t("email")}
                      value={form.values.email}
                      placeholder={i18n.t("email")}
                      onChange={(event) =>
                        form.setFieldValue("email", event.currentTarget.value)
                      }
                      required
                      styles={textInputStyle}
                      {...form.getInputProps("email")}
                    />

                    <TextInput
                      radius={"xl"}
                      label={i18n.t("defaultPassword")}
                      value={form.values.password}
                      placeholder={i18n.t("defaultPassword")}
                      onChange={(event) =>
                        form.setFieldValue(
                          "password",
                          event.currentTarget.value
                        )
                      }
                      required
                      styles={textInputStyle}
                      disabled={checked}
                      {...form.getInputProps("password")}
                    />
                    <Checkbox
                      label={i18n.t("useRandomPassword")}
                      checked={checked}
                      color="indigo"
                      onChange={(e) => {
                        setChecked(e.currentTarget.checked);

                        if (e.currentTarget.checked) {
                          const randomstring = Math.random()
                            .toString(36)
                            .slice(-8);
                          form.setFieldValue("password", randomstring);
                        } else {
                          form.setFieldValue("password", undefined);
                        }
                      }}
                    />

                    <Text size="sm" pt="sm">
                      {i18n.t("role")}
                    </Text>
                    <Checkbox
                      label={i18n.t("asAdmin")}
                      checked={admin}
                      color="indigo"
                      onChange={(e) => {
                        setAdmin(e.currentTarget.checked);
                        if (e.currentTarget.checked) {
                          form.setFieldValue("roles", ["admin"]);
                        } else {
                          form.setFieldValue("roles", ["manager"]);
                        }
                      }}
                    />
                  </Stack>
                )}
                <Stack px="md">
                  <TextInput
                    radius={"xl"}
                    label={i18n.t("username")}
                    value={form.values.userName}
                    placeholder={i18n.t("username")}
                    onChange={(event) =>
                      form.setFieldValue("userName", event.currentTarget.value)
                    }
                    required
                    styles={textInputStyle}
                    {...form.getInputProps("userName")}
                  />
                  <Box>
                    <Text size="sm">
                      {i18n.t("contacts")}{" "}
                      <span style={{ color: theme.colors.red[5] }}>*</span>
                    </Text>
                    {fields}
                    <Button
                      mt={"xs"}
                      size="xs"
                      radius={"lg"}
                      color="indigo"
                      variant="filled"
                      leftIcon={<Plus size={14} />}
                      {...form.getInputProps("phones")}
                      onClick={() => {
                        const newData = [...form.values.phones];
                        newData.push("");
                        form.setFieldValue("phones", newData);
                      }}
                      style={{ zIndex: 10 }}
                    >
                      {i18n.t("addContact")}
                    </Button>
                  </Box>
                  {!initialData && (
                    <ImageInput
                      onDrop={(file) => form.setFieldValue("avatar", file)}
                      value={form.values.avatar}
                      text={i18n.t("avatarUpload")}
                    />
                  )}
                </Stack>
                {initialData && (
                  <Stack px="md">
                    <ImageInput
                      onDrop={(file) => form.setFieldValue("avatar", file)}
                      value={form.values.avatar}
                      text={i18n.t("avatarUpload")}
                    />
                    <Text size="sm" pt="sm">
                      {i18n.t("role")}
                    </Text>
                    <Checkbox
                      label={i18n.t("asAdmin")}
                      checked={admin}
                      color="indigo"
                      onChange={(e) => {
                        setAdmin(e.currentTarget.checked);
                        if (e.currentTarget.checked) {
                          form.setFieldValue("roles", ["admin"]);
                        } else {
                          form.setFieldValue("roles", ["manager"]);
                        }
                      }}
                    />
                  </Stack>
                )}
              </SimpleGrid>
            </Stack>

            <Stack pt="xl" pb={50}>
              <Text weight={"bold"}>{i18n.t("assignedVehicles")}</Text>
              <Stack px="md" style={{ position: "relative" }}>
                <AppLoadingOverlay loading={vehicleLoading} />
                <TransferList
                  //  @ts-ignore: Unreachable code error
                  value={vehicleList}
                  //  @ts-ignore: Unreachable code error
                  onChange={setVehicleList}
                  searchPlaceholder="Search..."
                  nothingFound="Nothing here"
                  listHeight={300}
                  titles={[i18n.t("allVehicles"), i18n.t("assignToAccount")]}
                  itemComponent={({
                    data,
                    selected,
                  }: TransferListItemComponentProps) => (
                    <Group noWrap>
                      <Checkbox
                        checked={selected}
                        onChange={() => {}}
                        tabIndex={-1}
                        sx={{ pointerEvents: "none" }}
                        color="indigo"
                      />

                      <Text size="sm">{data.label}</Text>
                      <Text size="xs" color="dimmed">
                        {data.license}
                      </Text>
                    </Group>
                  )}
                  filter={(query, item) =>
                    item.label
                      .toLowerCase()
                      .includes(query.toLowerCase().trim()) ||
                    item.license
                      .toLowerCase()
                      .includes(query.toLowerCase().trim())
                  }
                  styles={{
                    transferListTitle: {
                      fontSize: theme.fontSizes.sm,
                    },
                    transferListBody: {
                      backgroundColor: theme.colors.background[0],
                      border: `1px solid ${theme.colors.border[0]}`,
                    },

                    transferListSearch: {
                      backgroundColor: theme.colors.background[0],
                      "&::placeholder": {
                        color: "white",
                      },
                    },
                    transferListControl: {
                      backgroundColor: theme.colors.background[0],
                      "&:hover": {
                        backgroundColor: theme.colors.background[1],
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
          </ScrollArea>
          <Box
            p="sm"
            px="lg"
            style={{ borderTop: `1px solid ${theme.colors.border[0]}` }}
          >
            <Button type="submit" color="indigo" radius={"lg"} fullWidth>
              {i18n.t("save")}
            </Button>
          </Box>
        </form>
      </div>
    </Drawer>
  );
};

export default NewAccountForm;

import React from "react";
import { Truck, Users, Key, Map2, ChartBar, Tir } from "tabler-icons-react";
import { ThemeIcon, UnstyledButton, Group, Text } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import RouteList from "../routes/routes";

interface CompanyDetailNavLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  path: string;
}

function CompanyDetailNavLink({
  icon,
  color,
  label,
  path,
}: CompanyDetailNavLinkProps) {
  let resolved = useLocation();
  let isMatched = resolved.pathname.includes(path);

  return (
    <Link to={path} style={{ all: "unset" }}>
      <UnstyledButton
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.white,
          backgroundColor: isMatched
            ? // ? theme.colors.background[0]
              theme.colors.background[1]
            : "transparent",
          "&:hover": {
            backgroundColor: theme.colors.background[1],
          },
        })}
      >
        <Group>
          <ThemeIcon
            color={color}
            variant={isMatched ? "filled" : "light"}
            radius={"md"}
          >
            {icon}
          </ThemeIcon>

          <Text size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    </Link>
  );
}

const data = [
  {
    icon: <Tir size={16} />,
    color: "gray",
    label: "Vehicles",
    path: RouteList.MANAGEMENT_COMPANY_VEHICLES,
  },
  {
    icon: <Users size={16} />,
    color: "gray",
    label: "Accounts",
    path: RouteList.MANAGEMENT_COMPANY_ACCOUNT,
  },
];

export function CompanyDetailNavLinks() {
  const links = data.map((link) => (
    <CompanyDetailNavLink {...link} key={link.label} />
  ));
  return <div>{links}</div>;
}

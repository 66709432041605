import http from "./httpService";

const apiEndPoint = "/init";

export function getInitialData() {
  return http.get(apiEndPoint);
}

export default {
  getInitialData,
};

import { useState, useEffect } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { addUser } from "../store/user";
import { connectSocket } from "../store/uiControls";
import { addCompany } from "../store/company";
import { addInitialRecord, addVehicles } from "../store/vehicles";
import mapAVLObjectToRecord from "../utility/mapAVLObjectToRecord";
import { updateLatestRecord } from "../store/vehicles";
import authService from "../api/authService";

interface SocketData {
  error: boolean;
  data: any;
}

export default function useSocket() {
  const [isConnected, setIsConnected] = useState(false);
  const dispatch = useDispatch();
  const socketUrl = "https://track.bugilabs.com";
  // const socketUrl = "http://localhost:3903";

  useEffect(() => {
    (async () => {
      const user: any = authService.getCurrentUser();

      let socket = io(socketUrl + `?userId=${user._id}&version=${2}`);
      console.log(socketUrl);

      socket.on("connect", () => {
        console.log("socket is connected...");
        setIsConnected(true);
      });

      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });

      // socket.on("init", (response: SocketData) => {
      //   if (response.error) {
      //     // TODO: handle error
      //     console.warn(response.data);
      //     return;
      //   }

      //   dispatch(addUser(response.data.user));
      //   dispatch(addCompany(response.data.company));
      //   dispatch(
      //     addVehicles({
      //       vehicles: response.data.vehicles,
      //       records: response.data.records,
      //     })
      //   );
      //   dispatch(addInitialRecord(response.data.initialRecord));

      //   setTimeout(() => {
      //     dispatch(connectSocket());
      //   }, 0);

      //   console.log("Initial data loaded....");
      // });

      socket.on("data", (data: any) => {
        const record = mapAVLObjectToRecord(data);
        // console.log("RECORD RECEIVED: ", record);
        console.log("data received...");
        dispatch(updateLatestRecord(record));
      });

      socket.on("disconnect", () => console.log("Socket disconnected..."));
    })();
  }, []);
}

import { Vehicle } from "./../model/Vehicle";
import http from "./httpService";

const apiEndPoint = "/vehicles";

export function assignDriver(vehicleId: string, driverId: string) {
  return http.put(`${apiEndPoint}/driver/${vehicleId}`, { driverId });
}

export function updateVehicleForUser(
  vehicleId: string,
  data: { name: string; avatar?: string; note?: string }
) {
  return http.put(`${apiEndPoint}/web/${vehicleId}`, data);
}
export function updateVehicleLimits(
  vehicleId: string,
  data: { speedLimit?: number; timeLimit?: number }
) {
  return http.put(`${apiEndPoint}/timeSpeed/${vehicleId}`, data);
}

//management
export function getAdminAllVehicles() {
  return http.get(`${apiEndPoint}/admin/all`);
}

export function getAdminVehicles(companyId: String) {
  return http.get(`${apiEndPoint}/${companyId}/admin`);
}

export function getAdminVehicle(vehicleId: string) {
  return http.get(`${apiEndPoint}/${vehicleId}/admin/detail`);
}

export function getVehiclesToAssign(vehicleId: string) {
  return http.get(`${apiEndPoint}/${vehicleId}/assignList`);
}

export function getVehicle(id: string) {
  return http.get(`${apiEndPoint}/${id}`);
}

export function createVehicle(vehicle: Vehicle) {
  return http.post(apiEndPoint, vehicle);
}

export function updateVehicle(vehicle: Vehicle) {
  return http.put(`${apiEndPoint}/admin/${vehicle._id}`, vehicle);
}

export function updateVehicles(data, ids) {
  return http.put(`${apiEndPoint}/together`, { data, ids });
}

export function deleteVehicle(id) {
  return http.delete(`${apiEndPoint}/${id}`);
}

export default {
  getAdminAllVehicles,
  getAdminVehicles,
  getAdminVehicle,
  getVehiclesToAssign,
  createVehicle,
  updateVehicle,
  updateVehicles,
  assignDriver,
  updateVehicleForUser,
  updateVehicleLimits,
  deleteVehicle,
};

import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

interface CompanyState {
  _id: string;
  name: string;
  dateCreated: string;
  vehicles?: string[];
  avatar?: string;
  admins?: string[];
}

const slice = createSlice({
  name: "uiControls",
  initialState: {},
  reducers: {
    companyAdded: (company, action) => {
      return action.payload;
    },
    companyReset: (company) => {
      company = {};
    },
  },
});

export const { companyAdded, companyReset } = slice.actions;
export default slice.reducer;

// Action creators

export const addCompany = (company: CompanyState) => companyAdded(company);
export const resetCompany = () => companyReset();
// Selectors

export const getCompany = createSelector(
  (state: any) => state.entities.company,
  (company) => company
);

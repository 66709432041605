import { Box, Container, Group, useMantineTheme } from "@mantine/core";
import { Text, Tooltip } from "@mantine/core";
import React from "react";
import { Vehicle } from "../model/Vehicle";
import StatusSign, { StatusSize } from "./StatusSign";

const marker = require("../assets/marker.png");
const markerOff = require("../assets/markerOff.png");

const carOn = require("../assets/CarOn.png");
const carOff = require("../assets/CarOff.png");

const bikeOn = require("../assets/scooterOn.png");
const bikeOff = require("../assets/scooterOff.png");

const excavatorOn = require("../assets/ExcavatorOn.png");
const excavatorOff = require("../assets/ExcavatorOff.png");

interface MapMarkerProps {
  data: Vehicle;
  showTitle?: boolean;
  angle?: number;
  type?: "bike" | "car" | "truck" | "excavator";
}

const MapMarker: React.FC<MapMarkerProps> = ({
  data,
  showTitle = false,
  angle,
  type,
}) => {
  const theme = useMantineTheme();

  const vehicleImage = (
    type: "bike" | "car" | "truck" | "excavator" | undefined,
    isOn: boolean
  ) => {
    if (type === "bike") {
      if (isOn) return bikeOn;
      else return bikeOff;
    }

    if (type === "car") {
      if (isOn) return carOn;
      else return carOff;
    }

    if (type === "excavator") {
      if (isOn) return excavatorOn;
      else return excavatorOff;
    }

    if (isOn) return marker;
    else return markerOff;
  };

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "&:hover ": {
          cursor: "pointer",
        },
      })}
    >
      {showTitle && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 5,
          }}
        >
          <div
            style={{
              padding: 5,
              paddingRight: 8,
              paddingLeft: 8,
              backgroundColor: theme.colors.primary[1],
              borderRadius: 15,
              marginBottom: 0,
              border: `1px solid ${theme.colors.primary[0]}`,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Group spacing={0}>
              <StatusSign
                size={StatusSize.extraSmall}
                isOn={data.latestRecord.ignition === "Ignition On"}
                warn={data.hasRelay && data.latestRecord.DigitalOutput1 === 1}
              />
              <Text size="xs" pl={"md"} pb={2} color={theme.colors.primary[0]}>
                {data.name}
              </Text>
            </Group>
          </div>
          <div
            style={{
              width: 8,
              height: 8,
              transform: "rotate(45deg)",
              position: "relative",
              top: -4,
              zIndex: -1,
              backgroundColor: theme.colors.primary[1],
              border: `1px solid ${theme.colors.primary[0]}`,
            }}
          />
        </div>
      )}

      <img
        src={vehicleImage(type, data.latestRecord.ignition === "Ignition On")}
        style={{
          width: 60,
          objectFit: "contain",
          zIndex: -2,
          transform: `rotate(${angle ? angle + 90 : 90}deg)`,
        }}
        alt={data.name}
      />
    </Box>
  );
};

export default MapMarker;

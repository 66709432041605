import { ActionIcon, Tooltip, useMantineTheme } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { Printer } from "tabler-icons-react";
import { i18n } from "../config/translation";

interface PrintReportButtonProps {
  setLoading?: (loading: boolean) => void;
  api: any;
  title: string;
}

const PrintReportButton: React.FC<PrintReportButtonProps> = ({
  setLoading,
  api,
  title,
}) => {
  const generatePdf = async () => {
    setLoading && setLoading(true);
    try {
      const result = await api();
      downloadPDF(result.data);
    } catch (ex) {
      if (ex.response.status >= 400 && ex.response.status < 500)
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
    }
    setLoading && setLoading(false);
  };

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${title}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <Tooltip label={i18n.t("savePdf")}>
        <ActionIcon
          color={"indigo"}
          variant="filled"
          radius={"md"}
          onClick={generatePdf}
        >
          <Printer size={18} />
        </ActionIcon>
      </Tooltip>
    </>
  );
};

export default PrintReportButton;

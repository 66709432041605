import { LoadingOverlay, useMantineTheme } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSocket from "../hooks/useSocket";
import RouteList from "../routes/routes";
import initService from "../api/initService";
import logService from "../api/logService";
import { useDispatch } from "react-redux";
import { addUser } from "../store/user";
import { addCompany } from "./../store/company";
import { addInitialRecord, addVehicles } from "./../store/vehicles";
import { addCount } from "../store/notifications";

interface FetchingScreenProps {
  doCb: boolean;
}

const FetchingScreen: React.FC<FetchingScreenProps> = ({ doCb }) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const getInitialData = async () => {
    try {
      const result = await initService.getInitialData();

      dispatch(addCount(result.data.numOfNotifications));
      dispatch(addUser(result.data.user));
      dispatch(addCompany(result.data.company));
      dispatch(
        addVehicles({
          vehicles: result.data.vehicles,
          records: result.data.records,
        })
      );
      dispatch(addInitialRecord(result.data.initialRecord));

      handleSuccess();
    } catch (ex) {
      if (ex.response.status !== 200) {
        // TODO:
        // Show some visual feedback about the error
        // i.e navigate to error 500 page
        console.warn(ex);
        logService.log(ex);
      }
    }
  };

  const handleSuccess = () => {
    setLoading(false);
    doCb &&
      navigation(`../${RouteList.DASH_MAIN}/${RouteList.DASH_MAP}`, {
        replace: true,
      });
  };

  useSocket();

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <LoadingOverlay
      visible={loading}
      loaderProps={{ color: theme.colors.secondary[0], variant: "bars" }}
      overlayOpacity={0.7}
      overlayColor={theme.colors.background[0]}
      title="Getting data..."
    />
  );
};

export default FetchingScreen;

import { Image, Modal, MantineNumberSize } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React, { useState } from "react";

interface ImageWithPreviewProps {
  source?: string;
  width?: number | string;
  height?: number | string;
  radius?: MantineNumberSize;
}

const ImageWithPreview: React.FC<ImageWithPreviewProps> = ({
  source,
  width,
  height,
  radius = "md",
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        size="lg"
        centered
      >
        <Image width={"100%"} src={source} color="indigo" />
      </Modal>
      <Image
        radius={radius}
        width={width}
        height={height}
        src={source}
        withPlaceholder
        color="indigo"
        onClick={() => {
          if (source) setOpened(true);
        }}
        sx={() => ({
          "&hover": { cursor: "pointer" },
        })}
      />
    </>
  );
};

export default ImageWithPreview;

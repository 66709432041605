import {
  Button,
  Grid,
  Group,
  Stack,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { Settings } from "tabler-icons-react";
import StatusSign, { StatusSize } from "../components/StatusSign";
import VehicleDetailInfo from "../components/VehicleDetailInfo";
import VehicleDetailDriver from "../components/VehicleDetailDriver";
import { useSelector } from "react-redux";
import { getVehicle } from "../store/vehicles";
import { useParams } from "react-router-dom";
import EditVehicleDrawer from "../components/EditVehicleDrawer";
import { i18n } from "../config/translation";
import VehicleRouteMap from "../components/VehicleRouteMap";
import VehicleLocationMap from "../components/VehicleLocationMap";
import VehicleNotifications from "../components/VehicleNotifications";
import moment from "moment";

interface VehicleDetailProps {}

const VehicleDetail: React.FC<VehicleDetailProps> = ({}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [editOpened, setEditOpened] = useState(false);
  const theme = useMantineTheme();
  const { id } = useParams();
  const vehicle = useSelector((state) => getVehicle(state, id));
  const subsOver = vehicle && moment(vehicle.subscription.endAt) < moment();
  const isOn =
    vehicle &&
    vehicle.latestRecord &&
    vehicle.latestRecord.ignition === "Ignition On";

  useEffect(() => {
    setActiveTab(0);
  }, [id]);

  return (
    <Grid>
      {editOpened ? (
        <Grid.Col span={12} style={{ height: "95vh" }}>
          <EditVehicleDrawer
            onClose={() => setEditOpened(false)}
            initialData={vehicle}
          />
        </Grid.Col>
      ) : (
        <Grid.Col span={12} style={{ height: "95vh" }}>
          <Group position="apart">
            <Group>
              <Title order={3}>{vehicle && vehicle.name}</Title>
              {!subsOver && (
                <Group>
                  <StatusSign
                    isOn={isOn}
                    size={StatusSize.extraSmall}
                    warn={
                      vehicle &&
                      vehicle.hasRelay &&
                      vehicle.latestRecord.DigitalOutput1 === 1
                    }
                  />
                  <Text
                    size="xs"
                    pl="md"
                    sx={(theme) => ({
                      color:
                        vehicle &&
                        vehicle.hasRelay &&
                        vehicle.latestRecord.DigitalOutput1 === 1
                          ? theme.colors.red[8]
                          : isOn
                          ? theme.colors.secondary[0]
                          : theme.colors.gray[6],
                    })}
                  >
                    {vehicle &&
                    vehicle.hasRelay &&
                    vehicle.latestRecord.DigitalOutput1 === 1
                      ? i18n.t("activated")
                      : isOn
                      ? i18n.t("inTransit")
                      : i18n.t("noDriving")}
                  </Text>
                </Group>
              )}
            </Group>
            {!subsOver && activeTab === 0 && (
              <Button
                size="xs"
                color="indigo"
                variant="outline"
                radius={"xl"}
                leftIcon={<Settings size="14" />}
                onClick={() => setEditOpened(true)}
              >
                {i18n.t("edit")}
              </Button>
            )}
          </Group>
          {subsOver ? (
            <Stack justify={"center"} align="center" style={{ height: "100%" }}>
              <Text>Subscription is over. Please contact Bugitrack</Text>
            </Stack>
          ) : (
            <Tabs
              color={"indigo"}
              sx={(theme) => ({
                overflow: "hidden",
              })}
              active={activeTab}
              onTabChange={setActiveTab}
              tabPadding={0}
              styles={{
                tabControl: {
                  fontSize: theme.fontSizes.xs,
                },
              }}
            >
              <Tabs.Tab label={i18n.t("detail")}>
                <Stack spacing={"xl"}>
                  <VehicleDetailInfo vehicle={vehicle} />
                  <VehicleDetailDriver vehicle={vehicle} />
                </Stack>
              </Tabs.Tab>

              <Tabs.Tab label="Location">
                {activeTab === 1 && <VehicleLocationMap vehicle={vehicle} />}
              </Tabs.Tab>

              <Tabs.Tab label={i18n.t("route")}>
                {activeTab === 2 && <VehicleRouteMap vehicle={vehicle} />}
              </Tabs.Tab>

              <Tabs.Tab label={i18n.t("notifications")}>
                {activeTab === 3 && <VehicleNotifications vehicle={vehicle} />}
              </Tabs.Tab>
            </Tabs>
          )}
        </Grid.Col>
      )}
    </Grid>
  );
};

export default VehicleDetail;

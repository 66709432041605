import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Header,
  Navbar,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useContext, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CompanyDetailNavLinks } from "../../components/CompanyDetailNavLink";
import { CompanyContext } from "../../context/CompanyContext";

interface CompanyDetailProps {}

type LocationState = { name: string };

const CompanyDetail: React.FC<CompanyDetailProps> = () => {
  const theme = useMantineTheme();
  const location = useLocation();
  const state = location.state as LocationState;
  const { companyName } = useContext(CompanyContext);

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          p="xs"
          width={{ base: 150 }}
          style={{
            backgroundColor: theme.colors.background[0],
          }}
        >
          <Box
            py="xs"
            style={{ borderBottom: `0.5px solid ${theme.colors.border[0]}` }}
            mb="md"
          >
            <Text px="sm" weight={"bold"} pb="xs">
              {companyName ? companyName : state && state.name}
            </Text>
          </Box>
          <CompanyDetailNavLinks />
        </Navbar>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.background[0]
              : theme.colors.gray[0],
          width: "100%",
          padding: 0,
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
};

export default CompanyDetail;

import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { Clock, Printer } from "tabler-icons-react";
import Chart from "react-apexcharts";

import recordService from "../api/recordService";
import { useParams } from "react-router-dom";
import moment from "moment";
import { showNotification } from "@mantine/notifications";
import { i18n } from "../config/translation";
import logService from "../api/logService";
import RangePrintReportButton from "./RangePrintReportButton";
import reportService from "../api/reportService";
import { useSelector } from "react-redux";
import { getVehicle } from "../store/vehicles";

interface TimeGraphProps {}

const TimeGraph: React.FC<TimeGraphProps> = ({}) => {
  const theme = useMantineTheme();
  const [weekData, setWeekData] = useState<{
    labels: string[];
    data: number[];
    summary: {
      today: string;
      average: string;
      total: string;
      highest: string;
    };
  }>();

  const { id } = useParams();
  const vehicle = useSelector((state) => getVehicle(state, id));

  const [loading, setLoading] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    getWeekData();
  }, [id]);

  const getHours = (seconds: number) => {
    if (seconds === 0) return "0 hrs";

    if (seconds < 60) return "Less than 1 min";

    const hour = moment.utc(1000 * seconds).format("H");
    const min = moment.utc(1000 * seconds).format("m");

    const greaterThan24 = seconds / 3600 >= 24;

    return `${
      hour !== "0" ? `${greaterThan24 ? 24 + Number(hour) : hour} h ` : ""
    }${min !== "0" ? `${min} m` : ""}`;
  };

  const getWeekData = async () => {
    setLoading(true);
    try {
      const result = await recordService.getTimeRecordsForWeek(
        id,
        moment().toDate()
      );

      let days = [];
      for (let i = 1; i <= 7; i++) {
        let day = moment().add(i, "days");
        days.push(day.format("ddd"));
      }

      const sum = result.data.reduce((prev, cur) => prev + cur, 0);

      const data = {
        labels: days,

        data: result.data.map((d) => {
          if (d < 0) return 0;
          return (d / 3600).toFixed(1);
        }),

        summary: {
          today: getHours(result.data[6]),
          average: getHours(sum / 7),
          total: getHours(sum),
          highest: getHours(Math.max(...result.data)),
        },
      };

      setWeekData(data);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      logService.log(ex);
    }

    // setWeekData(data);
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.background[1],
        borderRadius: theme.radius.md,
        height: "100%",
        border: `1px solid ${theme.colors.border[0]}`,
      })}
      p="md"
    >
      <Group position="apart">
        <Group spacing={5}>
          <Clock size={"20px"} color={theme.colors.secondary[0]} />
          <Text weight={"bold"}>{i18n.t("time")}</Text>
        </Group>
        <Group spacing={"xs"}>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.background[0],
              border: `1px solid ${theme.colors.border[0]}`,
              fontWeight: 400,
              height: 33,
              borderRadius: theme.radius.md,
              display: "flex",
              alignItems: "center",
            })}
            px="xs"
          >
            <Text size="xs">{`${moment()
              .subtract(7, "day")
              .format("MMM D")} - ${moment().format("MMM D")} `}</Text>
          </Box>

          <SegmentedControl
            data={[{ label: i18n.t("last7Days"), value: "w" }]}
            size="xs"
            defaultValue="d"
            value={"w"}
            color="indigo"
            radius={"md"}
            sx={(theme) => ({
              backgroundColor: theme.colors.background[0],
              border: `1px solid ${theme.colors.border[0]}`,
            })}
            onChange={() => {}}
          />
          <RangePrintReportButton
            title="Time_usage_report"
            api={(startAt, endAt) =>
              reportService.getTimeSummaryReport(
                vehicle && vehicle.deviceIMEI,
                startAt,
                endAt
              )
            }
          />
        </Group>
      </Group>

      {loading && (
        <Group
          position="center"
          style={{ width: "100%", paddingTop: 200 }}
          py="xl"
        >
          <Loader variant="bars" color={theme.colors.secondary[0]} />
        </Group>
      )}

      {!loading && (
        <div style={{ height: "calc(100vh - 350px)" }}>
          <Chart
            ref={chartRef}
            options={{
              theme: {
                mode: "dark",
              },
              grid: {
                show: false,
              },
              chart: {
                id: "chart",
                height: "100%",
                type: "area",
                background: "transparent",
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                // width: 0,
                curve: "smooth",
                colors: [theme.colors.primary[0]],
              },
              yaxis: {
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
              },
              xaxis: {
                type: "category",
                categories: weekData && weekData.labels,
              },
              tooltip: {
                x: {
                  format: "dd/MM/yy HH:mm",
                },
                theme: "dark",
                style: {
                  fontSize: "12px",
                  fontFamily: theme.fontFamily,
                },
              },
              fill: {
                colors: [
                  theme.colors.primary[0],
                  theme.colors.secondary[0],
                  "transparent",
                ],
              },
            }}
            series={[
              {
                name: "Time",
                data: weekData && weekData.data,
              },
            ]}
            type={"bar"}
            height={"100%"}
          />
        </div>
      )}
      {!loading && (
        <SimpleGrid cols={4} pt="xl">
          {["today", "average", "total", "highest"].map((item) => (
            <Box
              key={item}
              p="md"
              sx={(theme) => ({
                backgroundColor: theme.colors.background[0],
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.colors.border[0]}`,
              })}
            >
              <Text color={theme.colors.secondary[0]} size="xs" pb={5}>
                {i18n.t(item)}
              </Text>
              <Group align={"end"} spacing={"xs"}>
                <Title order={3}>{weekData && weekData.summary[item]}</Title>
                {/* <Text color="dimmed">{"hours"}</Text> */}
              </Group>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default TimeGraph;

import {
  Box,
  Button,
  Center,
  Container,
  Grid,
  Group,
  ScrollArea,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Title,
} from "@mantine/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Bulb, BulbOff, Eye, Plus } from "tabler-icons-react";
import NewVehicleDrawer from "../components/NewVehicleDrawer";
import VehicleCard from "../components/VehicleCard";
import { i18n } from "../config/translation";
import { Vehicle } from "../model/Vehicle";
import {
  getAllVehicles,
  getOffVehicles,
  getOnVehicles,
} from "../store/vehicles";
import { getHeight } from "../utility/getWindowSize";

interface VehiclesListProps {}

const VehiclesList: React.FC<VehiclesListProps> = ({}) => {
  const [currentFilter, setCurrentFilter] = useState("All");
  const vehicles = useSelector<Vehicle[]>(
    currentFilter === "All"
      ? getAllVehicles
      : currentFilter === "On"
      ? getOnVehicles
      : getOffVehicles
  );

  const segmentData = [
    {
      value: "All",
      label: (
        <Center px="xl">
          <Eye size={16} />
          <Box ml={"xs"}>{i18n.t("all")}</Box>
        </Center>
      ),
    },
    {
      value: "On",
      label: (
        <Center px="xl">
          <Bulb size={18} />
          <Box ml={"xs"}>{i18n.t("on")}</Box>
        </Center>
      ),
    },
    {
      value: "Off",
      label: (
        <Center px="xl">
          <BulbOff size={18} />
          <Box ml={"xs"}>{i18n.t("off")}</Box>
        </Center>
      ),
    },
  ];

  const handleFilter = (filter) => {
    setCurrentFilter(filter);
  };

  return (
    <>
      <Grid style={{ width: "100%", height: "100vh" }} gutter={0}>
        <Grid.Col span={6} p={"lg"}>
          <Group position="apart" mb="md">
            <Title order={3}>{i18n.t("vehicles")}</Title>
          </Group>

          <SegmentedControl
            size="xs"
            defaultValue="All"
            value={currentFilter}
            data={segmentData}
            color="indigo"
            radius={"md"}
            sx={(theme) => ({
              backgroundColor: theme.colors.background[1],
              border: `1px solid ${theme.colors.border[0]}`,
            })}
            onChange={handleFilter}
          />
          <ScrollArea
            style={{ height: "calc(100vh - 120px)" }}
            scrollbarSize={4}
            type="scroll"
          >
            <SimpleGrid cols={2} pt={"lg"}>
              {/* @ts-ignore  */}
              {vehicles.map((item) => (
                <VehicleCard data={item} key={item._id} />
              ))}
            </SimpleGrid>
          </ScrollArea>
        </Grid.Col>
        <Grid.Col
          span={6}
          sx={(theme) => ({
            backgroundColor: theme.colors.background[1],
            borderLeft: `1px solid ${theme.colors.border[0]}`,
          })}
          p={"lg"}
        >
          <Outlet />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default VehiclesList;

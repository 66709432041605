import { Badge } from "@mantine/core";
import React from "react";

interface CountBadgeProps {
  count?: number;
}

const CountBadge: React.FC<CountBadgeProps> = ({ count }) => {
  if (!count) return null;

  return (
    <Badge
      size="xs"
      color={"red"}
      variant="filled"
      style={{ position: "absolute", top: -3, right: -8 }}
    >
      {count > 99 ? "99+" : count}
    </Badge>
  );
};

export default CountBadge;

import {
  Accordion,
  ActionIcon,
  Badge,
  Box,
  Group,
  Image,
  ScrollArea,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Settings } from "tabler-icons-react";
import logService from "../../api/logService";
import vehicleService from "../../api/vehicleService";
import AppLoadingOverlay from "../../components/AppLoadingOverlay";
import DeleteButton from "../../components/DeleteButton";
import NewVehicleDrawer from "../../components/NewVehicleDrawer";
import { Vehicle } from "../../model/Vehicle";

interface ManagementVehicleDetailProps {}

const ManagementVehicleDetail: React.FC<
  ManagementVehicleDetailProps
> = ({}) => {
  const theme = useMantineTheme();
  const { id } = useParams();
  const [data, setData] = useState<Vehicle>();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await vehicleService.getAdminVehicle(id);

      setData(result.data);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      logService.log(ex);
    }
  };

  return (
    <Box style={{ position: "relative", minHeight: 400 }}>
      <AppLoadingOverlay loading={loading} />

      {data && (
        <>
          {showForm && (
            <NewVehicleDrawer
              visible={showForm}
              onClose={() => setShowForm(false)}
              initialData={data}
              // onUpdate={setData}
              onUpdate={() => {
                window.location.reload();
              }}
              companyId={data.company}
            />
          )}
          <Group position="apart">
            <Group spacing={"xs"}>
              <Title order={3}>{data.name}</Title>
              {data.subscription && (
                <Badge
                  color={
                    data.subscription.name === "Mini"
                      ? "yellow"
                      : data.subscription.name === "Regular"
                      ? "pink"
                      : data.subscription.name === "Xl"
                      ? "violet"
                      : "green"
                  }
                >
                  {data.subscription.name}
                </Badge>
              )}
            </Group>
            <ActionIcon radius="xl" onClick={() => setShowForm(true)}>
              <Settings size={20} />
            </ActionIcon>
          </Group>

          <ScrollArea
            mt="lg"
            style={{
              height: "calc( 100vh - 170px )",
              borderRadius: theme.radius.md,
            }}
          >
            <Box mb="xl">
              <Accordion
                iconPosition="right"
                multiple
                styles={{
                  item: {
                    backgroundColor: theme.colors.background[1],
                  },
                  content: {
                    backgroundColor: theme.colors.background[0],
                  },
                  label: {
                    fontSize: theme.fontSizes.sm,
                    fontWeight: "bold",
                  },
                }}
              >
                <Accordion.Item label="Device information">
                  <Stack spacing={"xs"} pt="md" pb="xs">
                    <Group position="apart">
                      <Text size="sm">Device IMEI</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.deviceIMEI}
                      </Text>
                    </Group>
                    <Group position="apart">
                      <Text size="sm">Device phone</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.devicePhone}
                      </Text>
                    </Group>

                    <Group position="apart">
                      <Text size="sm">GPS installed at</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.gpsInstalledDate
                          ? `${moment(data.gpsInstalledDate).format("ll")}`
                          : "Yet installed"}
                      </Text>
                    </Group>
                    <Group position="apart">
                      <Text size="sm">Relay</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.hasRelay ? `Installed` : "Not installed"}
                      </Text>
                    </Group>

                    <Group position="apart">
                      <Text size="sm">Fuel tracking</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.fuelFormula ? `Installed` : "Not installed"}
                      </Text>
                    </Group>
                    <Group position="apart">
                      <Text size="sm">Fuel tank capacity</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.fuelTankCapacity ?? "N/A"}
                      </Text>
                    </Group>
                  </Stack>
                </Accordion.Item>

                <Accordion.Item label="Vehicle information">
                  <Stack spacing={"xs"} pt="md" pb="xs">
                    <Group position="apart">
                      <Text size="sm">Name</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.name}
                      </Text>
                    </Group>
                    <Group position="apart">
                      <Text size="sm">License</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.license}
                      </Text>
                    </Group>

                    <Group position="apart">
                      <Text size="sm">Model</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.model}
                      </Text>
                    </Group>
                    <Group position="apart">
                      <Text size="sm">Vehicle type</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.vehicleType
                          ? data.vehicleType.charAt(0).toUpperCase() +
                            data.vehicleType.slice(1)
                          : "Truck"}
                      </Text>
                    </Group>
                    <Group position="apart" align={"flex-start"}>
                      <Text size="sm">Current driver</Text>
                      {data.driver && typeof data.driver !== "string" ? (
                        <Stack spacing={0} align="flex-end">
                          <Text size="sm" weight={"bold"}>
                            {data.driver.name}
                          </Text>

                          <Text size="sm" weight={"bold"}>
                            {data.driver.phone && data.driver.phone.join(",")}
                          </Text>
                        </Stack>
                      ) : (
                        <Text size="sm" weight={"bold"}>
                          No driver assigned
                        </Text>
                      )}
                    </Group>
                    <Group position="apart" align={"flex-start"}>
                      <Text size="sm">Image</Text>
                      <Image
                        withPlaceholder
                        width={120}
                        height={120}
                        radius="sm"
                        src={data.avatar}
                      />
                    </Group>
                  </Stack>
                </Accordion.Item>

                <Accordion.Item label="Notification settings">
                  <Stack spacing={"xs"} pt="md" pb="xs">
                    <Group position="apart">
                      <Text size="sm">Speed limit</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.speedLimit ? `${data.speedLimit} km/h` : "Off"}
                      </Text>
                    </Group>
                    <Group position="apart">
                      <Text size="sm">Time limit</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.timeLimit ? `${data.timeLimit} hours` : "Off"}
                      </Text>
                    </Group>
                  </Stack>
                </Accordion.Item>

                <Accordion.Item label="Trusted contact">
                  <Stack spacing={"xs"} pt="md" pb="xs">
                    {data.trustedContacts.length === 0 && (
                      <Text size="sm">No trusted contact</Text>
                    )}
                    {data.trustedContacts && (
                      <Stack spacing={"xs"}>
                        <Text size="sm">{data.trustedContacts.join(", ")}</Text>
                      </Stack>
                    )}
                  </Stack>
                </Accordion.Item>

                <Accordion.Item label="Subscription">
                  {!data.subscription && (
                    <Text size="sm" pt="md" pb="xs">
                      No subscription data
                    </Text>
                  )}
                  {data.subscription && (
                    <Stack spacing={"xs"} pt="md" pb="xs">
                      <Group position="apart">
                        <Text size="sm">Subscription</Text>
                        <Text size="sm" weight={"bold"}>
                          {data.subscription.name}
                        </Text>
                      </Group>
                      <Group position="apart">
                        <Text size="sm">Price</Text>
                        <Text size="sm" weight={"bold"}>
                          {data.subscription.price}
                        </Text>
                      </Group>
                      <Group position="apart">
                        <Text size="sm">Started at</Text>
                        <Text size="sm" weight={"bold"}>
                          {data.subscription.startAt
                            ? `${moment(data.subscription.startAt).format(
                                "ll"
                              )}`
                            : "Not set"}
                        </Text>
                      </Group>
                      <Group position="apart">
                        <Text size="sm">End at</Text>
                        <Text size="sm" weight={"bold"}>
                          {data.subscription.endAt
                            ? `${moment(data.subscription.endAt).format("ll")}`
                            : "Not set"}
                        </Text>
                      </Group>
                    </Stack>
                  )}
                </Accordion.Item>

                <Accordion.Item label="Contract information">
                  <Stack spacing={"xs"} pt="md" pb="xs">
                    <Group position="apart">
                      <Text size="sm">Start date</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.contractStartAt
                          ? `${moment(data.contractStartAt).format("ll")}`
                          : "Not set"}
                      </Text>
                    </Group>

                    <Group position="apart">
                      <Text size="sm">End date</Text>
                      <Text size="sm" weight={"bold"}>
                        {data.contractEndAt
                          ? `${moment(data.contractEndAt).format("ll")}`
                          : "Not set"}
                      </Text>
                    </Group>
                  </Stack>
                </Accordion.Item>
              </Accordion>

              {data.adminNote && (
                <Text pt="xl" size="sm" color={"gray"}>
                  Note for admins : {data.adminNote}
                </Text>
              )}
            </Box>

            <DeleteButton
              api={() => vehicleService.deleteVehicle(id)}
              cb={() => {
                window.location.replace(location.pathname.replace(id, ""));
              }}
            />
          </ScrollArea>
        </>
      )}
    </Box>
  );
};

export default ManagementVehicleDetail;

import { ThemeIcon, useMantineTheme } from "@mantine/core";
import React from "react";
import { LetterA, LetterB, LetterP } from "tabler-icons-react";

export enum MarkerType {
  start,
  end,
  park,
  crush,
}

interface RouteMarkerProps {
  type: MarkerType;
}

const RouteMarker: React.FC<RouteMarkerProps> = ({ type }) => {
  const theme = useMantineTheme();
  return (
    // <View style={[styles.container, { backgroundColor: type === MarkerType.park ? colors.secondary : type === MarkerType.crush ? colors.red : colors.primary }]}>
    <ThemeIcon
      size={25}
      radius="xl"
      sx={(theme) => ({
        border: `3px solid ${theme.colors.primary[0]}`,
        backgroundColor:
          type === MarkerType.park ? theme.white : theme.colors.primary[0],
      })}
    >
      {type === MarkerType.start ? (
        <LetterA size={14} />
      ) : type === MarkerType.end ? (
        <LetterB size={14} />
      ) : (
        <LetterP size={14} color={theme.colors.primary[0]} />
      )}
    </ThemeIcon>
    // </View>
  );
};

export default RouteMarker;

import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Grid,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { At, ShieldLock } from "tabler-icons-react";
import * as Yup from "yup";
import authService from "../api/authService";
import logService from "../api/logService";

import RouteList from "./routes";

const welcome = require("../assets/welcome.png");
const logo = require("../assets/logo.png");

interface LoginProps {}

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .label("Email"),
  password: Yup.string().required("Password is required").label("Password"),
  termsOfService: Yup.boolean()
    .required("You need to agree with terms")
    .label("Terms of service"),
});

const Login: React.FC<LoginProps> = ({}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  // const [fetching, setFetching] = useState(false);

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      email: "",
      password: "",
      termsOfService: false,
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      await authService.login(values.email, values.password);
      // @ts-ignore:
      if (authService.getCurrentUser().isAdmin) {
        navigation(
          `../${RouteList.MANAGEMENT_MAIN}/${RouteList.MANAGEMENT_COMPANIES}`,
          {
            replace: true,
          }
        );
      } else {
        // setFetching(true);
        navigation(`../${RouteList.DASH_MAIN}/${RouteList.DASH_MAP}`, {
          replace: true,
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        form.setFieldError("email", "Invalid email or password");
        form.setFieldError("password", "Invalid email or password");
      }

      logService.log(ex);
    }
    setLoading(false);
  };

  // @ts-ignore:
  // if (authService.getCurrentUser() && authService.getCurrentUser().isAdmin)
  //   return (
  //     <Navigate
  //       replace
  //       to={`../${RouteList.MANAGEMENT_MAIN}/${RouteList.MANAGEMENT_COMPANIES}`}
  //     />
  //   );

  if (authService.getCurrentUser())
    return (
      // @ts-ignore:
      authService.getCurrentUser().isAdmin ? (
        <Navigate
          replace
          to={`../${RouteList.MANAGEMENT_MAIN}/${RouteList.MANAGEMENT_COMPANIES}`}
        />
      ) : (
        <Navigate
          replace
          to={`../${RouteList.DASH_MAIN}/${RouteList.DASH_MAP}`}
        />
      )
    );

  const textInputStyle = {
    filledVariant: { backgroundColor: theme.colors.background[1] },
  };

  return (
    <div style={{ position: "relative" }}>
      {/* {fetching && <FetchingScreen doCb={true} />} */}
      <Container size={"xl"}>
        <Grid
          style={{
            width: "100%",
            height: "100vh",
          }}
          align="center"
        >
          <Grid.Col span={5}>
            <Box
              m={30}
              ml={50}
              p="xl"
              sx={(theme) => ({
                backgroundColor: theme.colors.background[0],
                borderRadius: theme.radius.lg,
                height: `calc(100% - 50px)`,
              })}
            >
              <img src={logo} alt="logo" style={{ width: 200 }} />

              <Stack>
                <Box p={"sm"} pt="xl">
                  <Title order={3} style={{ fontWeight: 500 }}>
                    Track and manage my vehicles
                  </Title>
                  <Title order={3} style={{ fontWeight: 500 }}>
                    in very simple ways
                  </Title>
                  <Text color="dimmed" size="sm" pt="md">
                    Currently available in web and app
                  </Text>
                </Box>

                <Box p={"sm"}>
                  <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Stack>
                      <TextInput
                        styles={textInputStyle}
                        icon={<At size={14} />}
                        required
                        // label="Email"
                        placeholder="Email"
                        {...form.getInputProps("email")}
                      />

                      <PasswordInput
                        styles={textInputStyle}
                        placeholder="Password"
                        icon={<ShieldLock size={14} />}
                        required
                        {...form.getInputProps("password")}
                      />

                      <Checkbox
                        mt="md"
                        label="Remember me"
                        {...form.getInputProps("termsOfService", {
                          type: "checkbox",
                        })}
                        color="indigo"
                      />

                      <Group position="right" mt="md">
                        <Button
                          type="submit"
                          color="indigo"
                          radius={"lg"}
                          loading={loading}
                        >
                          Login
                        </Button>
                      </Group>
                    </Stack>
                  </form>
                </Box>
              </Stack>
            </Box>
            {/* </Center> */}
          </Grid.Col>
          <Grid.Col span={7}>
            <Center
              style={{
                height: "98vh",
              }}
              pt="xl"
            >
              <img
                src={welcome}
                alt="bugitrack"
                style={{ width: "70%", height: "90vh", objectFit: "contain" }}
              />
            </Center>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;

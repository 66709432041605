import React, { useEffect, useState } from "react";
import { Global, MantineProvider } from "@mantine/core";
import theme from "./config/theme";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import RouteList from "./routes/routes";
import Dashboard from "./routes/Dashboard";
import VehiclesList from "./routes/VehiclesList";
import MapList from "./routes/MapList";
import VehicleDetail from "./routes/VehicleDetail";
import DriverList from "./routes/DriverList";
import ManagerList from "./routes/ManagerList";
import Login from "./routes/Login";
import Management from "./routes/management/Management";
import CompanyList from "./routes/management/CompanyList";
import CompanyDetail from "./routes/management/CompanyDetail";
import CompanyVehicles from "./routes/management/CompanyVehicles";
import ManagementVehicleDetail from "./routes/management/ManagementVehicleDetail";
import CompanyAccount from "./routes/management/CompanyAccount";
import CompanyAccountDetail from "./routes/management/CompanyAccountDetail";
import { NotificationsProvider } from "@mantine/notifications";
import NotAuth from "./routes/NotAuth";
import NotFound from "./routes/NotFound";
import { CompanyContext } from "./context/CompanyContext";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { setLanguage, i18n } from "./config/translation";
import StatsMain from "./routes/StatsMain";
import StatsDetail from "./routes/StatsDetail";
import ManagementVehicles from "./routes/management/ManagementVehicles";
import TimeStatisctics from "./routes/TimeStatistics";
import FuelStatisctics from "./routes/FuelStatisctics";
import DistanceStatisctics from "./routes/DistanceStatisctics";

function App() {
  const [companyName, setCompanyName] = useState();
  const [trustedContacts, setTrustedContacts] = useState([]);
  const store = configureStore();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLanguage(localStorage.getItem("language"));
  };

  return (
    <Provider store={store}>
      <MantineProvider theme={theme} withGlobalStyles>
        <NotificationsProvider>
          <Global
            styles={(theme) => ({
              "*, *::before, *::after": {
                boxSizing: "border-box",
              },

              body: {
                ...theme.fn.fontStyles(),
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.background[0]
                    : theme.white,
                color: theme.colorScheme === "dark" ? theme.white : theme.black,
                lineHeight: theme.lineHeight,
              },
            })}
          />
          <CompanyContext.Provider
            value={{
              companyName,
              setCompanyName,
              trustedContacts,
              setTrustedContacts,
            }}
          >
            <BrowserRouter>
              <Routes>
                <Route path={RouteList.AUTH_LOGIN} element={<Login />} />

                <Route
                  path={RouteList.MANAGEMENT_MAIN}
                  element={<Management />}
                >
                  <Route
                    path={RouteList.MANAGEMENT_COMPANIES}
                    element={<CompanyList />}
                  />
                  <Route
                    path={RouteList.MANAGEMENT_COMPANY_DETAIL}
                    element={<CompanyDetail />}
                  >
                    <Route
                      path={RouteList.MANAGEMENT_COMPANY_VEHICLES}
                      element={<CompanyVehicles />}
                    >
                      <Route
                        path={RouteList.MANAGEMENT_COMPANY_VEHICLE_DETAIL}
                        element={<ManagementVehicleDetail />}
                      />
                    </Route>

                    <Route
                      path={RouteList.MANAGEMENT_COMPANY_ACCOUNT}
                      element={<CompanyAccount />}
                    >
                      <Route
                        path={RouteList.MANAGEMENT_COMPANY_ACCOUNT_DETAIL}
                        element={<CompanyAccountDetail />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path={RouteList.MANAGEMENT_VEHICLES}
                    element={<ManagementVehicles />}
                  />
                </Route>

                <Route path={RouteList.DASH_MAIN} element={<Dashboard />}>
                  <Route path={RouteList.DASH_MAP} element={<MapList />} />
                  <Route
                    path={RouteList.VEHICLE_LIST}
                    element={<VehiclesList />}
                  >
                    <Route
                      path={RouteList.VEHICLE_DETAIL}
                      element={<VehicleDetail />}
                    />
                  </Route>
                  <Route
                    path={RouteList.DRIVER_LIST}
                    element={<DriverList />}
                  />
                  <Route
                    path={RouteList.MANAGER_LIST}
                    element={<ManagerList />}
                  />
                  <Route path={RouteList.STATS_MAIN} element={<StatsMain />}>
                    <Route
                      path={RouteList.STATS_DETAIL}
                      element={<StatsDetail />}
                    >
                      <Route
                        path={RouteList.STATS_TYPE}
                        element={<TimeStatisctics />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route path={RouteList.NOT_AUTHORIZED} element={<NotAuth />} />
                <Route path={RouteList.NOT_FOUNT} element={<NotFound />} />
                <Route
                  path={"/"}
                  element={
                    <Navigate replace to={`../${RouteList.AUTH_LOGIN}`} />
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </CompanyContext.Provider>
        </NotificationsProvider>
      </MantineProvider>
    </Provider>
  );
}

export default App;

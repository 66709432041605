import { showNotification } from "@mantine/notifications";
import imageCompression from "browser-image-compression";
import { Company } from "./../model/Company";
import http from "./httpService";

const apiEndPoint = "/companies";

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 200,
};

//management

export function getCompanies() {
  return http.get(`${apiEndPoint}`);
}

export function getCompany(id: string) {
  return http.get(`${apiEndPoint}/${id}`);
}

export function getCompanyForm(id: string) {
  return http.get(`${apiEndPoint}/${id}/form`);
}

export async function createCompany(company: Company) {
  return http.post(apiEndPoint, company);
}

export async function updateCompany(company: Company) {
  return http.put(`${apiEndPoint}/${company._id}`, company);
}

export async function deleteCompany(id: string) {
  return http.delete(`${apiEndPoint}/${id}`);
}

export default {
  getCompany,
  getCompanies,
  getCompanyForm,
  createCompany,
  updateCompany,
  deleteCompany,
};

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

interface NotificationState {
  count: number;
}

const slice = createSlice({
  name: "notifications",
  initialState: {},
  reducers: {
    notificationUpdated: (notifications, action) => {
      return action.payload;
    },
  },
});

export const { notificationUpdated } = slice.actions;
export default slice.reducer;

// Action creators

export const addCount = (count: NotificationState) => {
  return notificationUpdated({ count });
};

// Selectors

export const getCount = createSelector(
  (state: any) => state.entities.notifications,
  (notifications) => notifications.count
);

import { Grid } from "@mantine/core";
import React from "react";

import TimeGraph from "../components/TimeGraph";
import DailyTime from "../components/DailyTime";
import { Outlet, useParams } from "react-router-dom";
import DistanceStatisctics from "./DistanceStatisctics";
import FuelStatisctics from "./FuelStatisctics";
import TimeStatisctics from "./TimeStatistics";

interface StatsDetailProps {}

const StatsDetail: React.FC<StatsDetailProps> = ({}) => {
  const { type } = useParams();

  return type === "distance" ? (
    <DistanceStatisctics />
  ) : type === "fuel" ? (
    <FuelStatisctics />
  ) : (
    <TimeStatisctics />
  );
};

export default StatsDetail;

import { Grid, LoadingOverlay } from "@mantine/core";
import React, { useEffect, useState } from "react";

interface FuelStatiscticsProps {}

const FuelStatisctics: React.FC<FuelStatiscticsProps> = ({}) => {
  return (
    <Grid style={{ position: "relative", minHeight: "80vh" }}>
      <Grid.Col span={8}></Grid.Col>
      <Grid.Col span={4}></Grid.Col>
    </Grid>
  );
};

export default FuelStatisctics;

import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducer";
// import socketMiddleware from "./middleware/socket";

export default function () {
  return configureStore({
    reducer: reducers,
    // middleware: [socketMiddleware],
  });
}

import {
  Drawer,
  Group,
  MantineTheme,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
  Image,
  Button,
  createStyles,
  Box,
  ActionIcon,
  ScrollArea,
  Popover,
  Alert,
  Title,
  CloseButton,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AlertCircle, Plus, Trash } from "tabler-icons-react";
import driverService from "../api/driverService";
import logService from "../api/logService";
import { i18n } from "../config/translation";
import { Driver } from "../model/Driver";
import { getCompany } from "../store/company";
import DeleteConfirm from "./DeleteConfirm";
import ImageInput from "./ImageInput";

interface EditDriverFormProps {
  initialData?: Driver;
  onClose: () => void;
  onUpdate: (driver: Driver) => void;
  onDelete: (id: string) => void;
}

const EditDriverForm: React.FC<EditDriverFormProps> = ({
  initialData,
  onClose,
  onUpdate,
  onDelete,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const theme = useMantineTheme();
  const form = useForm({
    initialValues: {
      name: initialData && initialData.name,
      phone: initialData
        ? initialData.phone && initialData.phone.length > 0
          ? initialData.phone
          : [""]
        : [""],
      avatar: initialData && initialData.avatar,
    },

    validate: {
      name: (value) =>
        value.length < 3 ? "Name must have at least 3 letters" : null,
    },
  });

  useEffect(() => {
    initialData &&
      form.setValues({
        name: initialData.name,
        phone:
          initialData.phone && initialData.phone.length > 0
            ? initialData.phone
            : [""],
        avatar: initialData.avatar,
      });
  }, [initialData]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const result = await driverService.updateDriver({
        ...values,
        _id: initialData._id,
      });

      onUpdate(result.data);
      showNotification({
        title: i18n.t("updatedSuccess"),
        message: i18n.t("isUpdated", { name: values.name }),
        color: "indigo",
      });
      onClose();
    } catch (ex) {
      if (ex.response.status === 400 || ex.response.status === 404) {
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      }
      logService.log(ex);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await driverService.deleteDriver(initialData._id);
      onDelete(initialData._id);

      showNotification({
        title: i18n.t("deletedSuccess"),
        message: i18n.t("isDeleted", { name: initialData.name }),
        color: "indigo",
      });
      onClose();
    } catch (ex) {
      if (ex.response.status === 400 || ex.response.status === 404) {
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      }
      logService.log(ex);
    }
    setDeleting(false);
  };

  const textInputStyle = {
    filledVariant: {
      backgroundColor: theme.colors.background[0],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
  };

  const fields = form.values.phone.map((item, index) => (
    <Group key={index} mt="xs">
      <TextInput
        styles={textInputStyle}
        placeholder={i18n.t("phone")}
        radius={"xl"}
        required
        sx={{ flex: 1 }}
        value={item}
        onChange={(event) => {
          const newData = [...form.values.phone];
          newData[index] = event.currentTarget.value;
          form.setFieldValue("phone", newData);
        }}
      />
      <ActionIcon
        color="red"
        variant="hover"
        onClick={() => {
          if (form.values.phone.length > 1) {
            const newData = [...form.values.phone];
            newData.splice(index, 1);
            form.setFieldValue("phone", newData);
          } else {
            form.setFieldValue("phone", [""]);
          }
        }}
      >
        <Trash size={16} />
      </ActionIcon>
    </Group>
  ));

  return (
    <>
      <Group mb="lg" position="apart">
        <Title order={3}>{i18n.t("editDriver")}</Title>
        <CloseButton onClick={onClose} />
      </Group>
      <ScrollArea
        style={{ height: "calc(100vh - 100px)" }}
        scrollbarSize={4}
        type="scroll"
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack spacing={"xl"}>
            <TextInput
              radius={"xl"}
              label={i18n.t("name")}
              value={form.values.name}
              placeholder={i18n.t("name")}
              onChange={(event) =>
                form.setFieldValue("name", event.currentTarget.value)
              }
              required
              styles={textInputStyle}
            />
            <Box>
              {/* <Group pb="xs" position="apart"> */}
              <Text size="sm">{i18n.t("contacts")}</Text>
              {/* </Group> */}
              {fields}
              <Button
                mt={"xs"}
                size="xs"
                radius={"lg"}
                color="indigo"
                variant="filled"
                leftIcon={<Plus size={14} />}
                onClick={() => {
                  const newData = [...form.values.phone];
                  newData.push("");
                  form.setFieldValue("phone", newData);
                }}
                style={{ zIndex: 10 }}
              >
                {i18n.t("addContact")}
              </Button>
            </Box>

            <Box>
              <Group pb="xs" position="apart">
                <Text size="sm">{i18n.t("image")}</Text>
              </Group>
              <ImageInput
                onDrop={(file) => form.setFieldValue("avatar", file)}
                value={form.values.avatar}
              />
            </Box>
          </Stack>
          <Stack align={"stretch"}>
            <Button
              type="submit"
              color="indigo"
              radius={"lg"}
              fullWidth
              mt="xl"
              loading={loading}
            >
              {i18n.t("save")}
            </Button>
            <Popover
              opened={showPopup}
              onClose={() => setShowPopup(false)}
              target={
                <Button
                  type="button"
                  color="red"
                  radius={"lg"}
                  variant="subtle"
                  onClick={() => setShowPopup(true)}
                  fullWidth
                  loading={deleting}
                >
                  {i18n.t("deleteDriver")}
                </Button>
              }
              width={"100%"}
              position="top"
              withArrow
              styles={{
                popover: { backgroundColor: theme.colors.background[0] },
                arrow: { backgroundColor: theme.colors.background[0] },
              }}
            >
              <Stack spacing={"xs"} align="center">
                <Text size="sm">{i18n.t("sureDeleteDriver")}</Text>
                <Group spacing={"xs"}>
                  <Button
                    size="xs"
                    color="gray"
                    radius={"lg"}
                    onClick={() => setShowPopup(false)}
                    loading={deleting}
                  >
                    {i18n.t("cancel")}
                  </Button>
                  <Button
                    size="xs"
                    color="red"
                    radius={"lg"}
                    onClick={handleDelete}
                    loading={deleting}
                  >
                    {i18n.t("delete")}
                  </Button>
                </Group>
              </Stack>
            </Popover>
          </Stack>
        </form>
      </ScrollArea>
    </>
  );
};

export default EditDriverForm;

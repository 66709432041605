import {
  Box,
  Group,
  ScrollArea,
  Stack,
  Text,
  Timeline,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl";
import logService from "../api/logService";
import recordService from "../api/recordService";
import { defaultCoords } from "../config/mapStyle";
import { i18n } from "../config/translation";
import { Coordinate } from "../model/Coordinate";
import { StopPoint } from "../model/Record";
import { Vehicle } from "../model/Vehicle";
import AppDatePicker from "./AppDatePicker";
import AppLoadingOverlay from "./AppLoadingOverlay";
import Empty from "./Empty";
import NetworkError from "./NetworkError";
import PlaceText from "./PlaceText";
import RouteMarker, { MarkerType } from "./RouteMarker";

interface VehicleRouteMapProps {
  vehicle?: Vehicle;
}

const API_KEY =
  "pk.eyJ1IjoiaHlleWVhaCIsImEiOiJjbDUzcHl3YWgwOHc2M2twb3V2bnBhbmZrIn0.THwmy8ODR-5qavK87Nwv6w";

const VehicleRouteMap: React.FC<VehicleRouteMapProps> = ({ vehicle }) => {
  const mapRef = useRef<MapRef>();
  const [routeData, setRouteData] = useState<number[][] | undefined>();
  const [routeInfo, setRouteInfo] = useState<StopPoint[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [networkError, setNetworkError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const theme = useMantineTheme();

  const toDefaultLocation = () => {
    mapRef.current &&
      mapRef.current.fitBounds([38.99404, -6.579978, 39.53649, -6.958068], {
        padding: 200,
        duration: 500,
      });
  };

  const applyToArray = (func, array) => func.apply(Math, array);

  const fitToRoute = (points: Coordinate[]) => {
    if (points.length === 0) {
      toDefaultLocation();
      return;
    }

    const pointsLong = points.map((point) => point.longitude);
    const pointsLat = points.map((point) => point.latitude);
    const cornersLongLat: [number, number][] = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];
    mapRef.current.fitBounds(
      [
        cornersLongLat[0][0],
        cornersLongLat[0][1],
        cornersLongLat[1][0],
        cornersLongLat[1][1],
      ],
      {
        padding: {
          top: 100,
          left: 100,
          right: 100,
          bottom: 150,
        },
        duration: 500,
      }
    );
  };

  const fitToPoint = (coords: Coordinate) => {
    mapRef.current.flyTo({
      center: [coords.longitude, coords.latitude],
      essential: true,
      zoom: 15,
      duration: 500,
    });
  };

  const getRouteData = async (deviceIMEI: string, date: Date) => {
    setNetworkError(false);
    setLoading(true);

    try {
      const result = await recordService.getRecordsByDate(
        vehicle.deviceIMEI,
        date
      );
      handleRoute(result.data.routeCoords);
      handleRouteInfo(result.data.pointList);
    } catch (error) {
      showNotification({
        title: i18n.t("somethingWrong"),
        message: i18n.t("tryAgain"),
        color: "red",
      });
      setNetworkError(true);
      logService.log(error);
    }

    setLoading(false);
  };

  const handleRouteInfo = (data: StopPoint[]) => {
    let info = [...data];

    if (data.length < 3) return setRouteInfo(info);

    if (
      info[0].coords.latitude.toString() ===
        info[1].coords.latitude.toString() &&
      info[0].coords.longitude.toString() ===
        info[1].coords.longitude.toString()
    ) {
      info.shift();
      info[0] = {
        ...info[0],
        startAt: info[0].endAt,
        endAt: undefined,
        type: "start",
      };
    }

    if (
      info[info.length - 1].coords.latitude.toString() ===
        info[info.length - 2].coords.latitude.toString() &&
      info[info.length - 1].coords.longitude.toString() ===
        info[info.length - 2].coords.longitude.toString()
    ) {
      info.pop();
      info[info.length - 1] = {
        ...info[info.length - 1],
        endAt: undefined,
        type: "end",
      };
    }

    setRouteInfo(info);
  };

  const handleRoute = (routeData: Coordinate[]) => {
    setRouteData(routeData.map((r) => [r.longitude, r.latitude]));
    fitToRoute(routeData);
  };

  const handleDate = (date: Date) => {
    setDate(date);
    getRouteData(vehicle.deviceIMEI, date);
  };

  useEffect(() => {
    setDate(new Date());
    getRouteData(vehicle.deviceIMEI, new Date());
  }, [vehicle.deviceIMEI]);

  const renderTime = (start: Date, end?: Date) => {
    const diff = end && moment.duration(moment(end).diff(start));
    /* @ts-ignore */
    const minutes = Math.floor(diff / 60000);
    const formatToHour = (min: number) => {
      const hour = Math.floor(min / 60);
      const minute = min % 60;

      return `${hour} h ${minute}`;
    };

    return end
      ? `${moment(start).format("H:mm")} ~ ${moment(end).format("H:mm")} 
      (${minutes > 59 ? formatToHour(minutes) : minutes} mins)`
      : moment(start).format("H:mm");
  };

  return (
    <Box pt="sm" style={{ position: "relative" }}>
      <AppLoadingOverlay loading={loading || !loaded} />
      <Group style={{ position: "absolute", zIndex: 1 }} mt="sm" ml="sm">
        <AppDatePicker
          color={theme.colors.primary[0]}
          onChange={handleDate}
          value={date}
        />
      </Group>
      <Map
        ref={mapRef}
        mapboxAccessToken={API_KEY}
        initialViewState={{
          longitude: defaultCoords.longitude,
          latitude: defaultCoords.latitude,
          zoom: 10.5,
          bearing: 0,
          pitch: 0,
        }}
        style={{
          width: "100%",
          height: "calc(100vh - 120px)",
          border: `1px solid ${theme.colors.border[0]}`,
          borderRadius: 7,
        }}
        onLoad={() => {
          if (routeData)
            // @ts-ignore
            fitToRoute(routeData.map((r) => [r.longitude, r.latitude]));
          setLoaded(true);
        }}
        mapStyle="mapbox://styles/hyeyeah/cl53rdrq2001o15s79uolijli"
      >
        <GeolocateControl position="top-right" />
        <FullscreenControl position="top-right" />
        <NavigationControl position="top-right" />
        <ScaleControl position="bottom-right" />
        {routeData && (
          <Source
            id="polylineLayer"
            type="geojson"
            data={{
              type: "Feature",
              properties: {
                color: theme.colors.secondary[0],
              },
              geometry: {
                type: "LineString",
                coordinates: routeData,
              },
            }}
          >
            <Layer
              id="polylineLayer"
              type="line"
              source="my-data"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": ["get", "color"],
                "line-width": 5,
                "line-opacity": 1,
              }}
            />
          </Source>
        )}
        {routeInfo &&
          routeInfo.map((item, index) => (
            <Marker
              key={index.toString()}
              longitude={item.coords.longitude}
              latitude={item.coords.latitude}
            >
              <RouteMarker
                type={
                  item.type === "start"
                    ? MarkerType.start
                    : item.type === "end"
                    ? MarkerType.end
                    : MarkerType.park
                }
              />
            </Marker>
          ))}
      </Map>
      <ScrollArea
        p="md"
        m="md"
        style={{
          backgroundColor: theme.colors.background[0],
          position: "absolute",
          bottom: 0,
          width: "95%",
          alignSelf: "center",
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.border}`,
        }}
      >
        <Group noWrap align={"flex-start"}>
          {networkError ? (
            <NetworkError />
          ) : routeInfo && routeInfo.length === 0 ? (
            <Empty />
          ) : (
            routeInfo &&
            routeInfo.map((item, index) => (
              <Stack
                spacing={0}
                key={index.toString()}
                onClick={() => fitToPoint(item.coords)}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Group
                  spacing={"xs"}
                  style={{ width: 150 }}
                  align="flex-start"
                  noWrap
                >
                  <RouteMarker
                    type={
                      item.type === "start"
                        ? MarkerType.start
                        : item.type === "end"
                        ? MarkerType.end
                        : MarkerType.park
                    }
                  />
                  <Stack spacing={0} style={{ width: "100%" }}>
                    {index !== routeInfo.length - 1 && (
                      <div
                        style={{
                          borderTop: `1px solid ${theme.colors.border}`,
                          position: "relative",
                          top: 10,
                          width: "100%",
                          zIndex: 0,
                        }}
                      />
                    )}
                    <Text
                      size="xs"
                      style={{
                        backgroundColor: theme.colors.background[0],
                        width: 50,
                        zIndex: 1,
                      }}
                    >
                      {item.type === "start"
                        ? i18n.t("depart")
                        : item.type === "end"
                        ? i18n.t("arrive")
                        : i18n.t("park")}
                    </Text>
                    <PlaceText coords={item.coords}>
                      {(text) => (
                        <Text color="dimmed" size="xs">
                          {text}
                        </Text>
                      )}
                    </PlaceText>

                    <Text size="xs" mt={4} style={{ whiteSpace: "pre-line" }}>
                      {renderTime(item.startAt, item.endAt)}
                    </Text>
                  </Stack>
                </Group>
              </Stack>
            ))
          )}
        </Group>
      </ScrollArea>
    </Box>
  );
};

export default VehicleRouteMap;

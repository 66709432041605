import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import moment from "moment";
import React, { useState } from "react";
import { SteeringWheel } from "tabler-icons-react";
import theme from "../config/theme";
import { i18n } from "../config/translation";
import { Vehicle } from "../model/Vehicle";
import ChangeDriverDrawer from "./ChangeDriverDrawer";

interface VehicleDetailDriverProps {
  vehicle: Vehicle;
}

const VehicleDetailDriver: React.FC<VehicleDetailDriverProps> = ({
  vehicle,
}) => {
  const [show, setShow] = useState(false);

  return (
    <Grid
      pt={"md"}
      gutter="lg"
      style={{ borderTop: `1px solid ${theme.colors.border[0]}` }}
    >
      <Grid.Col span={4}>
        {vehicle && vehicle.subscription && (
          <Stack
            spacing={0}
            style={{
              border: `1px solid ${theme.colors.border[0]}`,
              borderRadius: 10,
              backgroundColor: theme.colors.background[0],
            }}
            p="md"
            pb="lg"
          >
            <Title order={5} mb="xs">
              {i18n.t("subscription")}
            </Title>
            <Badge
              size="lg"
              color={
                vehicle.subscription.name === "Mini"
                  ? "yellow"
                  : vehicle.subscription.name === "Regular"
                  ? "pink"
                  : vehicle.subscription.name === "Xl"
                  ? "violet"
                  : "green"
              }
              mb="xl"
            >
              {vehicle.subscription.name}
            </Badge>
            {vehicle.subscription.startAt && (
              <Text size="sm" color={"dimmed"}>{`${moment(
                vehicle.subscription.startAt
              ).format("ll")}`}</Text>
            )}
            {vehicle.subscription.endAt && (
              <Text size="sm" color={"dimmed"}>{`~ ${moment(
                vehicle.subscription.endAt
              ).format("ll")}`}</Text>
            )}
            {vehicle.subscription.endAt &&
              moment(vehicle.subscription.endAt).diff(moment(), "days") < 7 && (
                <Text size="xs" color={"red"} style={{ fontStyle: "italic" }}>
                  {i18n.t("subscriptionOverInDays", {
                    number: moment(vehicle.subscription.endAt).diff(
                      moment(),
                      "days"
                    ),
                  })}
                </Text>
              )}
          </Stack>
        )}
      </Grid.Col>
      <Grid.Col span={8}>
        {show && (
          <ChangeDriverDrawer
            visible={show}
            onClose={() => setShow(false)}
            // @ts-ignore
            currentDriverId={vehicle.driver ? vehicle.driver._id : null}
            vehicle={vehicle}
          />
        )}
        <Group position="apart" pb="sm">
          <Title order={5}>{i18n.t("driver")}</Title>
          <Button
            size="xs"
            color="indigo"
            variant="outline"
            radius={"xl"}
            leftIcon={<SteeringWheel size="14" />}
            onClick={() => setShow(true)}
          >
            {i18n.t("assignDriver")}
          </Button>
        </Group>
        {vehicle && vehicle.driver && typeof vehicle.driver !== "string" ? (
          <Group spacing="lg" align={"flex-start"}>
            <Avatar
              src={vehicle.driver.avatar}
              style={{
                width: 60,
                height: 60,
                borderRadius: 100,
              }}
            />
            <Stack spacing="xs">
              <Text>{vehicle.driver.name}</Text>
              {vehicle.driver.phone && vehicle.driver.phone.length > 0 ? (
                <Box>
                  {vehicle.driver.phone.map((p) => (
                    <Text size="sm" color="dimmed" key={p}>
                      {p}
                    </Text>
                  ))}
                </Box>
              ) : (
                <Text size="sm" color="dimmed">
                  {i18n.t("noPhoneData")}
                </Text>
              )}
            </Stack>
          </Group>
        ) : (
          <Text size="sm" color="dimmed">
            {i18n.t("noAssign")}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default VehicleDetailDriver;

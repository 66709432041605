import { LoadingOverlay, useMantineTheme } from "@mantine/core";
import React from "react";

interface AppLoadingOverlayProps {
  loading: boolean;
}

const AppLoadingOverlay: React.FC<AppLoadingOverlayProps> = ({ loading }) => {
  const theme = useMantineTheme();
  return (
    <LoadingOverlay
      visible={loading}
      loaderProps={{ color: theme.colors.secondary[0], variant: "bars" }}
      overlayOpacity={0.7}
      overlayColor={theme.colors.background[0]}
    />
  );
};

export default AppLoadingOverlay;

import {
  Box,
  Button,
  Col,
  Grid,
  Group,
  Select,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useState } from "react";
import { Settings } from "tabler-icons-react";
import { Vehicle } from "../model/Vehicle";
import { useForm } from "@mantine/form";
import NotificationSelector from "./NotificationSelector";
import vehicleService from "../api/vehicleService";
import { showNotification } from "@mantine/notifications";
import { i18n } from "../config/translation";
import { useDispatch } from "react-redux";
import logService from "../api/logService";
import { updateInfo, updateLimit } from "../store/vehicles";
import AppLoadingOverlay from "./AppLoadingOverlay";

interface VehicleNotificationsProps {
  vehicle: Vehicle;
}

const speedList = [undefined, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120].map(
  (speed) => ({
    label: speed ? `${speed} km/h` : "Off",
    value: speed ? `${speed}` : undefined,
  })
);

const timeList = [
  undefined,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
].map((time) => ({
  label: time ? i18n.t("overHour", { time }) : "Off",
  value: time ? `${time}` : undefined,
}));

const VehicleNotifications: React.FC<VehicleNotificationsProps> = ({
  vehicle,
}) => {
  const theme = useMantineTheme();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const form = useForm({
    initialValues: {
      speedLimit: vehicle.speedLimit
        ? vehicle.speedLimit?.toString()
        : undefined,
      timeLimit: vehicle.speedLimit ? vehicle.timeLimit?.toString() : undefined,
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    const payload = {
      timeLimit: values.timeLimit ? parseInt(values.timeLimit) : undefined,
      speedLimit: values.speedLimit ? parseInt(values.speedLimit) : undefined,
    };
    try {
      await vehicleService.updateVehicleLimits(vehicle._id, payload);
      dispatch(updateLimit(vehicle.deviceIMEI, values));
      showNotification({
        title: i18n.t("updatedSuccess"),
        message: i18n.t("isUpdated", { name: vehicle.name }),
        color: "indigo",
      });
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 404) {
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
      }
      logService.log(error);
    }
    setLoading(false);
  };

  return (
    <Box pt="sm" style={{ position: "relative", height: "87vh" }}>
      <AppLoadingOverlay loading={loading} />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group position="right" mb="md" spacing={"sm"}>
          <Button
            size="xs"
            color="indigo"
            variant="outline"
            radius={"xl"}
            leftIcon={edit ? null : <Settings size="14" />}
            onClick={() => {
              if (edit) {
                form.setFieldValue(
                  "speedLimit",
                  vehicle.speedLimit
                    ? vehicle.speedLimit?.toString()
                    : undefined
                );
                form.setFieldValue(
                  "timeLimit",
                  vehicle.timeLimit ? vehicle.timeLimit?.toString() : undefined
                );

                setEdit(false);
              } else setEdit(true);
            }}
          >
            {edit ? i18n.t("cancel") : i18n.t("setting")}
          </Button>
          {edit && (
            <Button
              size="xs"
              color="indigo"
              variant="filled"
              radius={"xl"}
              type="submit"
            >
              {i18n.t("save")}
            </Button>
          )}
        </Group>
        <Grid>
          <Col span={6}>
            <NotificationSelector
              label={i18n.t("speed")}
              value={form.values.speedLimit}
              onChange={(value) => form.setFieldValue("speedLimit", value)}
              data={speedList}
              disabled={!edit}
              valueLabel={
                speedList.find((item) => item.value === form.values.speedLimit)
                  .label
              }
            />
          </Col>
          <Col span={6}>
            <NotificationSelector
              label={i18n.t("time")}
              value={form.values.timeLimit}
              onChange={(value) => form.setFieldValue("timeLimit", value)}
              data={timeList}
              disabled={!edit}
              valueLabel={
                timeList.find((item) => item.value === form.values.timeLimit)
                  .label
              }
            />
          </Col>
        </Grid>
      </form>
    </Box>
  );
};

export default VehicleNotifications;

import { User } from "./../model/User";
import { Company } from "./../model/Company";
import http from "./httpService";

const apiEndPoint = "/users";

//management

export function getCompanyUsers(id: string) {
  return http.get(`${apiEndPoint}/${id}/company`);
}

export function getUserDetail(id: string) {
  return http.get(`${apiEndPoint}/${id}/detail`);
}

export function createUser(user: User) {
  return http.post(apiEndPoint, user);
}

export function updateUser(user: User) {
  return http.put(`${apiEndPoint}/admin/${user._id}`, user);
}

export default {
  getCompanyUsers,
  createUser,
  getUserDetail,
  updateUser,
};

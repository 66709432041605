import {
  ActionIcon,
  Avatar,
  Box,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Settings } from "tabler-icons-react";
import companyService from "../api/companyService";
import logService from "../api/logService";
import { CompanyContext } from "../context/CompanyContext";
import { Company } from "../model/Company";
import RouteList from "../routes/routes";
import AppLoadingOverlay from "./AppLoadingOverlay";
import DeleteButton from "./DeleteButton";
import NewCompanyForm from "./NewCompanyForm";

interface CompanyProfileProps {}

const CompanyProfile: React.FC<CompanyProfileProps> = () => {
  const theme = useMantineTheme();
  const [formVisible, setFormVisible] = useState(false);
  const [data, setData] = useState<Company>();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { setCompanyName, setTrustedContacts } = useContext(CompanyContext);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await companyService.getCompany(id);
      setData(result.data);
      setCompanyName(result.data.name);
      setTrustedContacts(result.data.trustedContacts);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      logService.log(ex);
    }
  };

  return (
    <Stack spacing={0} justify="space-between" style={{ height: "100%" }}>
      <Box style={{ position: "relative" }}>
        {data && (
          <NewCompanyForm
            initialData={data}
            visible={formVisible}
            onClose={() => setFormVisible(false)}
            onUpdate={setData}
          />
        )}
        <AppLoadingOverlay loading={loading} />
        <Group
          style={{ width: "100%", flexWrap: "nowrap" }}
          align="flex-start"
          position="apart"
        >
          <Stack spacing={0}>
            <Title order={3}>{data && data.name}</Title>
            <Text size="xs" color="gray">{`Joined date: ${moment(
              data && data.dateCreated
            ).format("ll")}`}</Text>
          </Stack>
          <Avatar
            size={"lg"}
            radius="xl"
            color="indigo"
            src={data && data.avatar}
          >
            {data && data.name[0]}
          </Avatar>
        </Group>

        <Box
          mt="xl"
          p="md"
          style={{
            border: `1px solid ${theme.colors.border[0]}`,
            borderRadius: theme.radius.md,
          }}
        >
          <Title order={5}>Trusted contact</Title>
          <Box pt="xs">
            {data && data.trustedContacts && data.trustedContacts.length > 0 ? (
              data.trustedContacts.map((item) => (
                <Text size="sm" key={item}>
                  {item}
                </Text>
              ))
            ) : (
              <Text size="sm" color={"gray"}>
                No trusted contact data
              </Text>
            )}
          </Box>
        </Box>

        {data && data.note && (
          <Text pt="sm" size="sm">
            Note: {data.note}
          </Text>
        )}

        <Group mt="xl" position="right">
          <ActionIcon
            radius={"xl"}
            size="lg"
            onClick={() => setFormVisible(true)}
          >
            <Settings size={20} />
          </ActionIcon>
        </Group>
      </Box>
      <DeleteButton
        api={() => companyService.deleteCompany(id)}
        cb={() =>
          navigate(
            `/${RouteList.MANAGEMENT_MAIN}/${RouteList.MANAGEMENT_COMPANIES}`,
            { replace: true }
          )
        }
      />
    </Stack>
  );
};

export default CompanyProfile;

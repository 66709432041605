import {
  Button,
  Container,
  Stack,
  Title,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const image = require("../assets/404.png");
const logo = require("../assets/logo.png");

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = ({}) => {
  const theme = useMantineTheme();
  const navigation = useNavigate();

  return (
    <Container size={"xl"} style={{ height: "100vh" }}>
      <img
        src={logo}
        alt="logo"
        style={{ width: 120, height: 70, objectFit: "contain" }}
      />
      <Stack
        justify={"center"}
        align={"center"}
        style={{ height: "calc( 100% - 100px)" }}
      >
        <img
          src={image}
          style={{ width: 300, height: 300, objectFit: "contain" }}
          alt="404"
        />
        <Title>Not found</Title>
        <Text style={{ color: theme.colors.grey[0] }}>
          This page is not found
        </Text>

        <Button
          radius={"xl"}
          size="lg"
          color={"indigo"}
          mt="xl"
          onClick={() => navigation(-1)}
        >
          Go back
        </Button>
      </Stack>
    </Container>
  );
};

export default NotFound;

import { Driver } from "./../store/driver";

import http from "./httpService";

const apiEndPoint = "/drivers";

export function updateDriver(driver: Driver) {
  return http.put(`${apiEndPoint}/${driver._id}/web`, driver);
}

export function createDriver(driver: Driver) {
  return http.post(`${apiEndPoint}/web`, driver);
}

export function deleteDriver(driverId: string) {
  return http.delete(`${apiEndPoint}/${driverId}`);
}

//management

export function getDrivers(companyId: String) {
  return http.get(`${apiEndPoint}/${companyId}`);
}

export default {
  getDrivers,
  updateDriver,
  createDriver,
  deleteDriver,
};

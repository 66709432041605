import http from "./httpService";

const apiEndPoint = "/records";

//management

export function getRecordsByDate(deviceIMEI: string, timestamp: Date) {
  return http.get(`${apiEndPoint}/${deviceIMEI}/${timestamp}`);
}

const getTimeRecordsByDate = (deviceIMEI: string, timestamp: Date) =>
  http.get(`${apiEndPoint}/${deviceIMEI}/${timestamp}/timeDay`);

const getTimeRecordsForWeek = (deviceIMEI: string, timestamp: Date) =>
  http.get(`${apiEndPoint}/${deviceIMEI}/${timestamp}/timeWeek`);

const getRecordForSpecificTime = (deviceIMEI: string, timestamp: Date) =>
  http.get(`${apiEndPoint}/${deviceIMEI}/${timestamp}/specificTime`);

const getDistanceRecordsForWeek = (deviceIMEI: string, timestamp: Date) =>
  http.get(`${apiEndPoint}/${deviceIMEI}/${timestamp}/distanceWeek`);

const getDistanceRecordsByDate = (deviceIMEI: string, timestamp: Date) =>
  http.get(`${apiEndPoint}/${deviceIMEI}/${timestamp}/distanceDay`);

export default {
  getRecordsByDate,
  getTimeRecordsByDate,
  getTimeRecordsForWeek,
  getRecordForSpecificTime,
  getDistanceRecordsByDate,
  getDistanceRecordsForWeek,
};

import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Loader,
  Pagination,
  Select,
  Stack,
  Table,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ArrowsSort,
  Plus,
  Search,
  Settings,
  SortAscending2,
  SortDescending2,
} from "tabler-icons-react";
import {
  useTable,
  useSortBy,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";

import { getHeight } from "../utility/getWindowSize";
import userService from "../api/userService";
import { useSelector } from "react-redux";
import { getCompany } from "../store/company";
import { showNotification } from "@mantine/notifications";
import GlobalSearch from "../components/GlobalSearch";
import { User } from "../model/User";
import ManagerDetail from "../components/ManagerDetail";
import NewAccountForm from "../components/NewAccountForm";
import { i18n } from "../config/translation";

interface ManagerListProps {}

const ManagerList: React.FC<ManagerListProps> = ({}) => {
  const theme = useMantineTheme();
  const [newForm, setNewForm] = useState(false);
  const [currentManager, setCurrentManager] = useState<User>();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const company = useSelector(getCompany);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await userService.getCompanyUsers(company._id);
      setData(result.data);
      setLoading(false);
    } catch (ex) {
      if (ex.response.status >= 400 && ex.response.status < 500)
        showNotification({
          title: i18n.t("somethingWrong"),
          message: i18n.t("tryAgain"),
          color: "red",
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: i18n.t("username"),
        accessor: "userName",
        sortType: "basic",
        Cell: (props) => (
          <Group spacing={"xs"} noWrap>
            <Avatar
              src={props.row.original.avatar}
              color="indigo"
              size={"sm"}
              radius="xl"
            >
              {props.row.original.userName[0]}
            </Avatar>
            <Text size="sm">{props.row.original.userName}</Text>
          </Group>
        ),
      },
      {
        Header: i18n.t("email"),
        accessor: "email",
        sortType: "basic",
      },
      {
        Header: i18n.t("contacts"),
        accessor: "phones",
        sortType: "basic",
        Cell: (props) =>
          props.row.original.phones.length === 0
            ? "No data"
            : props.row.original.phones.length > 1
            ? `${props.row.original.phones[0]} and more`
            : props.row.original.phones[0],
      },
      {
        Header: i18n.t("roles"),
        accessor: "roles",
        Cell: (props) => (
          <Group spacing={"xs"}>
            {props.row.original.roles &&
              props.row.original.roles.map((r) => (
                <Badge key={r} color={r === "admin" ? "green" : "indigo"}>
                  {r}
                </Badge>
              ))}
          </Group>
        ),
      },
      {
        Header: i18n.t("assignedVehicles"),
        accessor: "vehicles",
        sortType: "basic",
        Cell: (props) => props.row.original.vehicles.length,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      //   defaultColumn,
      //   filterTypes,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    state,
  } = tableInstance;

  const updateAccount = (value: User) => {
    const copied = [...data];
    const currentIndex = copied.findIndex((u) => u._id === value._id);

    copied[currentIndex] = value;
    setData(copied);
  };

  const handleUpdate = (user: User) => {
    setData([user, ...data]);
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <NewAccountForm
        visible={newForm}
        onClose={() => setNewForm(false)}
        companyId={company._id}
        initialData={undefined}
        onUpdate={handleUpdate}
      />
      <Grid style={{ width: "100%", height: "100vh" }} gutter={0}>
        <Grid.Col span={8} p={"xl"}>
          <Group position="apart" mb="md">
            <Title order={3}>{i18n.t("managers")}</Title>
            <Button
              size="xs"
              radius={"xl"}
              leftIcon={<Plus size={14} />}
              color="indigo"
              onClick={() => setNewForm(true)}
            >
              {i18n.t("addNewManager")}
            </Button>
          </Group>

          <GlobalSearch
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={i18n.t("searchNManagers", {
              number: preGlobalFilteredRows.length,
            })}
          />
          {loading && (
            <Group position="center" style={{ width: "100%" }} py="xl">
              <Loader variant="bars" color={theme.colors.secondary[0]} />
            </Group>
          )}
          {!loading && (
            <div
              style={{
                maxHeight: "calc(100vh - 250px)",
                overflow: "scroll",
                border: `1px solid ${theme.colors.border[0]}`,
                borderRadius: 5,
              }}
            >
              <Table highlightOnHover>
                <table {...getTableProps()} style={{ width: "100%" }}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: theme.colors.background[1],
                        }}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <Group>
                              {column.render("Header")}

                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDescending2
                                    size={15}
                                    color={
                                      column.isSorted
                                        ? theme.colors.secondary[0]
                                        : theme.colors.white[0]
                                    }
                                  />
                                ) : (
                                  <SortAscending2
                                    size={15}
                                    color={
                                      column.isSorted
                                        ? theme.colors.secondary[0]
                                        : theme.colors.white[0]
                                    }
                                  />
                                )
                              ) : (
                                <ArrowsSort size={15} />
                              )}
                            </Group>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.length === 0 ? (
                      <Text p="md">No account data</Text>
                    ) : (
                      rows.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() => setCurrentManager(row.original)}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </Table>
            </div>
          )}
        </Grid.Col>
        <Grid.Col
          span={4}
          p={"xl"}
          sx={(theme) => ({
            backgroundColor: theme.colors.background[1],
            borderLeft: `1px solid ${theme.colors.border[0]}`,
          })}
        >
          {currentManager && (
            <ManagerDetail id={currentManager._id} onUpdate={updateAccount} />
          )}
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default ManagerList;

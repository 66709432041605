export interface Subscription {
  name: string;
  price: number;
  startAt?: Date;
  endAt?: Date;
}

export const subsList = [
  { value: "Mini", label: "Mini", price: 5000 },
  { value: "Regular", label: "Regular", price: 15000 },
  { value: "Xl", label: "Xl", price: 40000 },
  { value: "Cooperate", label: "Cooperate", price: 200000 },
];

export const subsPrice = {
  Mini: 5000,
  Regular: 15000,
  Xl: 40000,
  Cooperate: 200000,
};

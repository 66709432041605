import React, { useState } from "react";
import {
  AppShell,
  Navbar,
  MantineProvider,
  useMantineTheme,
} from "@mantine/core";
import { MyNavbar } from "./MyAside";

interface MyAppShellProps {}

export const MyAppShell: React.FC<MyAppShellProps> = ({ children }) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      navbar={<MyNavbar />}
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.background[0]
              : theme.colors.gray[0],
          padding: 0,
        },
      })}
    >
      {children}
    </AppShell>
  );
};

import moment from "moment";
import { Vehicle } from "../model/Vehicle";

export const getDistance = (vehicle: Vehicle) => {
  if (!vehicle.initialRecord) return "0.0";

  const latestDistance = vehicle.latestRecord.totalOdometer;
  const initialDistance = vehicle.initialRecord.totalOdometer;

  return (latestDistance - initialDistance) / 1000 >= 0
    ? ((latestDistance - initialDistance) / 1000).toFixed(1)
    : "N/A";
};

export const getHours = (vehicle: Vehicle) => {
  if (!vehicle.initialRecord || !vehicle.latestRecord) return "0";

  const latestCount = vehicle.latestRecord.ignitionOnCounter;
  const initialCount = vehicle.initialRecord.ignitionOnCounter;

  if (!latestCount || !initialCount) return "N/A";

  const seconds = latestCount - initialCount;
  return seconds >= 0 ? moment.utc(1000 * seconds).format("H:mm") : "N/A";
};

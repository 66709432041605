import { Box, Select, Text, useMantineTheme } from "@mantine/core";
import React from "react";

interface NotificationSelectorProps {
  label: string;
  data: any[];
  value?: any;
  onChange: (value?: any) => void;
  disabled?: boolean;
  valueLabel: string;
}

const NotificationSelector: React.FC<NotificationSelectorProps> = ({
  label,
  data,
  value,
  onChange,
  disabled,
  valueLabel,
}) => {
  const theme = useMantineTheme();

  return (
    <Box
      style={{
        backgroundColor: theme.colors.background[0],
        borderRadius: 10,
        border: `1px solid ${theme.colors.border[0]}`,
      }}
      px="md"
      py="md"
    >
      <Text size="sm" color={"dimmed"} align="center">
        {label}
      </Text>
      {disabled ? (
        <Text align="center" py="md">
          {valueLabel}
        </Text>
      ) : (
        <Select
          /* @ts-ignore */
          data={data}
          value={value}
          onChange={onChange}
          mt="xs"
          mb={"xs"}
          styles={{
            input: {
              padding: 0,
              backgroundColor: "transparent",
              color: theme.white,
              fontSize: theme.fontSizes.md,
              textAlign: "center",
              "&::placeholder": {
                color: theme.colors.grey,
              },
            },
            disabled: {
              backgroundColor: "transparent",
              color: theme.white,
            },
          }}
          placeholder="Off"
        />
      )}
    </Box>
  );
};

export default NotificationSelector;

import {
  Avatar,
  Box,
  Button,
  Drawer,
  Group,
  Loader,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DriverCard from "./DriverCard";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import GlobalSearch from "../components/GlobalSearch";
import {
  ArrowsSort,
  SortAscending2,
  SortDescending2,
} from "tabler-icons-react";
import driverService from "../api/driverService";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../store/company";
import { Driver } from "../model/Driver";
import { showNotification } from "@mantine/notifications";
import logService from "../api/logService";
import { Vehicle } from "../model/Vehicle";
import vehicleService from "../api/vehicleService";
import { assignDriver } from "../store/vehicles";

interface ChangeDriverDrawerProps {
  visible: boolean;
  onClose: () => void;
  currentDriverId?: string;
  vehicle: Vehicle;
}

const ChangeDriverDrawer: React.FC<ChangeDriverDrawerProps> = ({
  visible,
  onClose,
  currentDriverId,
  vehicle,
}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState<Driver>();
  const company = useSelector(getCompany);
  const [data, setData] = useState([]);
  const dataRef = useRef<Driver[]>();
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await driverService.getDrivers(company._id);
      if (currentDriverId) {
        setCurrent(result.data.find((d) => d._id === currentDriverId));
        setData(result.data.filter((d) => d._id !== currentDriverId));
      } else {
        setData(result.data);
      }

      dataRef.current = result.data;
      setLoading(false);
    } catch (ex) {
      if (ex.response.status === 404)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      logService.log(ex);
    }
  };

  const saveDriver = async () => {
    setSaving(true);
    try {
      await vehicleService.assignDriver(
        vehicle._id,
        current ? current._id : null
      );
      showNotification({
        title: `Successfully saved!`,
        message: `Assigned driver for  ${vehicle.name}.`,
        color: "indigo",
      });
      dispatch(assignDriver(vehicle.deviceIMEI, current));
      onClose();
    } catch (ex) {
      if (ex.response.status === 400 || ex.response.status === 404) {
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      }
      logService.log(ex);
    }
    setSaving(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        Cell: (props) => (
          <Group spacing={"xs"}>
            <Avatar
              src={props.row.original.avatar}
              color="indigo"
              size={"sm"}
              radius="xl"
            >
              {props.row.original.name[0]}
            </Avatar>
            <Text size="sm">{props.row.original.name}</Text>
          </Group>
        ),
      },
      {
        Header: "Mobile",
        accessor: "phone",
        sortType: "basic",
        Cell: (props) =>
          props.row.original.phone && props.row.original.phone.length > 0
            ? props.row.original.phone.join(", ")
            : "No phone data",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      //   defaultColumn,
      //   filterTypes,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    state,
  } = tableInstance;

  return (
    <Drawer
      opened={visible}
      onClose={onClose}
      title={`Change driver for ${vehicle.name}`}
      position="right"
      size="lg"
      styles={{
        title: { fontWeight: 600 },
        header: {
          borderBottom: `0.5px solid ${theme.colors.border[0]}`,
          padding: theme.spacing.xl,
          paddingBottom: theme.spacing.xs,
        },
        drawer: {
          backgroundColor: theme.colors.background[0],
          borderRight: `1px solid ${theme.colors.border[0]}`,
        },
      }}
    >
      <Box px={"lg"}>
        <Stack spacing={"xs"}>
          <Group position="apart">
            <Text size="sm" color={theme.colors.secondary[0]}>
              Current driver
            </Text>
            {current && (
              <Button
                size="xs"
                radius={"xl"}
                variant="outline"
                color="gray"
                onClick={() => {
                  setCurrent(undefined);
                  setData(dataRef.current);
                }}
              >
                Remove
              </Button>
            )}
          </Group>
          {current ? (
            <DriverCard data={current} />
          ) : (
            <Text size="sm" color="dimmed">
              No driver assigned
            </Text>
          )}
        </Stack>
      </Box>
      {/* <ScrollArea p={"lg"}> */}
      <Box p={"lg"}>
        <Text size="sm">Choose new driver</Text>
        <GlobalSearch
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholder={`Search ${preGlobalFilteredRows.length} drivers..`}
        />
        {loading && (
          <Group position="center" style={{ width: "100%" }} py="xl">
            <Loader variant="bars" color={theme.colors.secondary[0]} />
          </Group>
        )}
        {!loading && (
          <div
            style={{
              maxHeight: `calc(100vh - ${current ? "" : 330}px)`,
              overflow: "scroll",
              border: `1px solid ${theme.colors.border[0]}`,
              borderRadius: 5,
            }}
          >
            <Table highlightOnHover>
              <table {...getTableProps()} style={{ width: "100%" }}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      style={{
                        position: "sticky",
                        zIndex: 1,
                        top: 0,
                        backgroundColor: theme.colors.background[1],
                      }}
                    >
                      {headerGroup.headers.map((column) => {
                        return (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <Group>
                              {column.render("Header")}
                              {column.id !== "selection" &&
                                (column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDescending2
                                      size={15}
                                      color={
                                        column.isSorted
                                          ? theme.colors.secondary[0]
                                          : theme.colors.white[0]
                                      }
                                    />
                                  ) : (
                                    <SortAscending2
                                      size={15}
                                      color={
                                        column.isSorted
                                          ? theme.colors.secondary[0]
                                          : theme.colors.white[0]
                                      }
                                    />
                                  )
                                ) : (
                                  <ArrowsSort size={15} />
                                ))}
                            </Group>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.length === 0 ? (
                    <Text p="md" size="sm">
                      No Data
                    </Text>
                  ) : (
                    rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                onClick={() => {
                                  setCurrent(row.original);

                                  dataRef.current &&
                                    setData(
                                      dataRef.current.filter(
                                        (d) => d._id !== row.original._id
                                      )
                                    );
                                }}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </Table>
          </div>
        )}
      </Box>
      <Box
        px="lg"
        pt={"sm"}
        style={{
          position: "absolute",
          width: "100%",
          bottom: theme.spacing.sm,
          borderTop: `0.5px solid ${theme.colors.border}`,
        }}
      >
        <Button
          fullWidth
          color="indigo"
          radius={"xl"}
          loading={saving}
          onClick={saveDriver}
        >
          Update
        </Button>
      </Box>
    </Drawer>
  );
};

export default ChangeDriverDrawer;

import { Box, CloseButton, TextInput, useMantineTheme } from "@mantine/core";
import React from "react";
import { useAsyncDebounce } from "react-table";
import { Search } from "tabler-icons-react";

function GlobalSearch({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const theme = useMantineTheme();
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Box py={"lg"}>
      <TextInput
        placeholder={placeholder ?? "Search..."}
        icon={<Search size={14} />}
        radius="xl"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        styles={{
          input: {
            backgroundColor: theme.colors.background[1],
            border: `0.5px solid ${theme.colors.border[0]}`,
          },
        }}
        rightSection={
          value && (
            <CloseButton
              radius={"xl"}
              onClick={() => {
                setValue("");
                onChange("");
              }}
            />
          )
        }
      />
    </Box>
  );
}

export default GlobalSearch;

import moment from "moment";

export const historyStyle = (time: Date) => {
  return moment(time).calendar(null, {
    sameDay: "h:mm A",
    nextWeek: "dddd",
    lastDay: "[Yesterday] h:mm A",
    lastWeek: "ddd h:mm A",
    sameElse: "DD/MM/YYYY",
  });
};

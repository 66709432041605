import {
  Box,
  Button,
  Checkbox,
  Drawer,
  ScrollArea,
  SegmentedControl,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useState } from "react";
import { Calendar } from "tabler-icons-react";
import logService from "../api/logService";
import vehicleService from "../api/vehicleService";
import { subsList, subsPrice } from "../model/Subscription";
import AppLoadingOverlay from "./AppLoadingOverlay";

interface SubscriptionUpdateFormProps {
  visible: boolean;
  onClose: () => void;
  selection: string[];
  onUpdate: (data: any, selected: string[]) => void;
}

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

const SubscriptionUpdateForm: React.FC<SubscriptionUpdateFormProps> = ({
  visible,
  onClose,
  selection,
  onUpdate,
}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState("0");
  const [contractDuration, setContractDuration] = useState("0");
  const [subscription, setSubscription] = useState(false);
  const [contract, setContract] = useState(false);

  const datePickerStyle = {
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
    filledVariant: {
      backgroundColor: theme.colors.background[1],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    dropdown: {
      backgroundColor: theme.colors.background[0],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    day: {
      "&:hover": {
        backgroundColor: theme.colors.dark[8],
      },
    },
    selected: {
      backgroundColor: theme.colors.background[1],
    },
  };

  const textInputStyle = {
    filledVariant: {
      backgroundColor: theme.colors.background[1],
      border: `1px solid ${theme.colors.border[0]}`,
    },
    input: {
      "&::placeholder": { color: theme.colors.dark[1] },
    },
  };

  const form = useForm({
    initialValues: {
      subscription: undefined,
      price: undefined,
      startAt: undefined,
      endAt: undefined,
      contractStartAt: undefined,
      contractEndAt: undefined,
    },

    validate: subscription
      ? {
          price: (value) =>
            !isNumeric(value)
              ? "Price should be number"
              : Number(value) < 0
              ? "Price should not be less than 0"
              : null,
          //   name: (value) =>
          //     value.length < 3 ? "Name must have at least 3 letters" : null,
          //   trustedContactName: (value) =>
          //     value.length < 3 ? "Name must have at least 3 letters" : null,
          //   trustedContactNumber: (value) =>
          //     value.length < 10 ? "Wrong format" : null,
        }
      : {},
  });

  const handleClose = () => {
    setDuration("0");
    setContractDuration("0");
    setSubscription(false);
    setContract(false);
    form.reset();
    onClose();
  };

  const handleSubmit = async (values) => {
    if (!subscription && !contract) return;

    const payload: {
      subscription?: {
        name?: string;
        price?: string;
        startAt?: Date;
        endAt?: Date;
      };
      contractStartAt?: Date;
      contractEndAt?: Date;
    } = {};

    if (subscription) {
      payload.subscription = {
        name: values.subscription,
        price: values.price,
        startAt: values.startAt,
        endAt: values.endAt,
      };
    }

    if (contract) {
      payload.contractStartAt = values.contractStartAt;
      payload.contractEndAt = values.contractEndAt;
    }

    setLoading(true);
    try {
      const result = await vehicleService.updateVehicles(payload, selection);
      onUpdate(payload, selection);
      showNotification({
        title: "Updated successfully",
        message: `${selection.length} vehicles updated successfully`,
        color: "indigo",
      });

      handleClose();
    } catch (ex) {
      if (ex.response.status === 400 || ex.response.status === 404)
        showNotification({
          title: "Something went wrong",
          message: "Plesase try again",
          color: "red",
        });
      logService.log(ex);
    }

    setLoading(false);
  };

  return (
    <Drawer
      opened={visible}
      onClose={handleClose}
      title={`Update ${selection.length} selected vehicles`}
      size="xl"
      styles={{
        title: { fontWeight: 600 },
        header: {
          borderBottom: `0.5px solid ${theme.colors.border[0]}`,
          padding: theme.spacing.xl,
          paddingBottom: theme.spacing.xs,
        },
        drawer: {
          backgroundColor: theme.colors.background[0],
          borderRight: `1px solid ${theme.colors.border[0]}`,
        },
      }}
    >
      <div style={{ position: "relative" }}>
        <AppLoadingOverlay loading={loading} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <ScrollArea
            px="xl"
            style={{
              height: "calc(100vh - 180px)",
            }}
          >
            <Stack>
              <Button
                color={"indigo"}
                radius="xl"
                variant={"light"}
                onClick={() => {
                  if (subscription) {
                    form.setFieldValue("subscription", undefined);
                    form.setFieldValue("price", undefined);
                    form.setFieldValue("startAt", undefined);
                    form.setFieldValue("endAt", undefined);
                    setDuration("0");
                  }
                  setSubscription(!subscription);
                }}
              >
                {`${
                  subscription ? "I don't want to" : "I want to"
                } update subscription information`}
              </Button>
              {subscription && (
                <Stack mb={50}>
                  <Text weight={"bold"}>Subscription</Text>
                  <Stack px="md">
                    <Select
                      label="Subscription"
                      placeholder="Subscription"
                      value={form.values.subscription}
                      radius="xl"
                      {...form.getInputProps("subscription")}
                      onChange={(value) => {
                        form.setFieldValue("subscription", value);
                        form.setFieldValue("price", subsPrice[value]);
                      }}
                      styles={textInputStyle}
                      clearable
                      required={subscription}
                      data={subsList}
                    />
                    <TextInput
                      radius={"xl"}
                      label="Price"
                      value={form.values.price}
                      placeholder="Price"
                      {...form.getInputProps("price")}
                      onChange={(event) =>
                        form.setFieldValue("price", event.currentTarget.value)
                      }
                      required={subscription}
                      styles={textInputStyle}
                    />
                    <DatePicker
                      placeholder={
                        form.values.startAt
                          ? moment(form.values.startAt).format("LL")
                          : "Start date"
                      }
                      icon={<Calendar size={16} />}
                      {...form.getInputProps("startAt")}
                      label="Start date"
                      radius={"xl"}
                      value={form.values.startAt}
                      onChange={(value) => form.setFieldValue("startAt", value)}
                      styles={datePickerStyle}
                    />
                    <DatePicker
                      placeholder={
                        form.values.endAt
                          ? moment(form.values.endAt).format("LL")
                          : "End date"
                      }
                      icon={<Calendar size={16} />}
                      label="End date"
                      {...form.getInputProps("endAt")}
                      radius={"xl"}
                      value={form.values.endAt}
                      minDate={form.values.startAt}
                      onChange={(value) => form.setFieldValue("endAt", value)}
                      styles={datePickerStyle}
                      disabled={duration !== "0"}
                    />
                    <SegmentedControl
                      value={duration}
                      disabled={!form.values.startAt}
                      onChange={(d) => {
                        setDuration(d);
                        if (d !== "0") {
                          const date = moment(form.values.startAt)
                            .add(d, "months")
                            .toDate();
                          form.setFieldValue("endAt", date);
                        }
                      }}
                      color="indigo"
                      styles={{
                        root: {
                          backgroundColor: theme.colors.background[1],
                          border: `0.5px solid ${theme.colors.border[0]}`,
                        },
                      }}
                      size="xs"
                      radius="xl"
                      data={[
                        { label: "Calendar", value: "0" },
                        { label: "1m", value: "1" },
                        { label: "3m", value: "3" },
                        { label: "6m", value: "6" },
                        { label: "12m", value: "12" },
                      ]}
                    />
                  </Stack>
                </Stack>
              )}

              <Button
                color={"indigo"}
                radius="xl"
                variant="light"
                onClick={() => {
                  if (contract) {
                    form.setFieldValue("contractStartAt", undefined);
                    form.setFieldValue("contractEndAt", undefined);

                    setContractDuration("0");
                  }
                  setContract(!contract);
                }}
              >
                {`${
                  contract ? "I don't want to" : "I want to"
                } update contract information`}
              </Button>
              {contract && (
                <Stack>
                  <Text weight={"bold"} pt="xl">
                    Contract
                  </Text>
                  <Stack px="md" mb={50}>
                    <DatePicker
                      placeholder={
                        form.values.contractStartAt
                          ? moment(form.values.contractStartAt).format("LL")
                          : "Start date"
                      }
                      icon={<Calendar size={16} />}
                      label="Start date"
                      radius={"xl"}
                      value={form.values.contractStartAt}
                      onChange={(value) =>
                        form.setFieldValue("contractStartAt", value)
                      }
                      styles={datePickerStyle}
                      {...form.getInputProps("contractStartAt")}
                    />
                    <DatePicker
                      minDate={form.values.contractStartAt}
                      icon={<Calendar size={16} />}
                      placeholder={
                        form.values.contractEndAt
                          ? moment(form.values.contractEndAt).format("LL")
                          : "End date"
                      }
                      label="End date"
                      radius={"xl"}
                      value={form.values.contractEndAt}
                      onChange={(value) =>
                        form.setFieldValue("contractEndAt", value)
                      }
                      styles={datePickerStyle}
                      disabled={contractDuration !== "0"}
                      {...form.getInputProps("contractEndAt")}
                    />
                    <SegmentedControl
                      disabled={!form.values.contractStartAt}
                      value={contractDuration}
                      onChange={(d) => {
                        setContractDuration(d);
                        if (d !== "0") {
                          form.setFieldValue(
                            "contractEndAt",
                            moment(form.values.contractStartAt)
                              .add(d, "years")
                              .toDate()
                          );
                        }
                      }}
                      color="indigo"
                      styles={{
                        root: {
                          backgroundColor: theme.colors.background[1],
                          border: `0.5px solid ${theme.colors.border[0]}`,
                        },
                      }}
                      size="xs"
                      radius="xl"
                      data={[
                        { label: "Calendar", value: "0" },
                        { label: "1y", value: "1" },
                        { label: "2y", value: "2" },
                        { label: "3y", value: "3" },
                      ]}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </ScrollArea>
          <Box
            p="sm"
            px="lg"
            style={{ borderTop: `1px solid ${theme.colors.border[0]}` }}
          >
            <Text size="xs" pb="sm" style={{ fontStyle: "italic" }} color="red">
              {`❗️This operation will update ${selection.length} vehicles information together`}
            </Text>
            <Button type="submit" color="indigo" radius={"lg"} fullWidth>
              Submit
            </Button>
          </Box>
        </form>
      </div>
    </Drawer>
  );
};

export default SubscriptionUpdateForm;

import { Grid, LoadingOverlay } from "@mantine/core";
import React, { useEffect, useState } from "react";
import DistanceWeek from "../components/DistanceWeek";
import DailyDistance from "../components/DailyDistance";

interface DistanceStatiscticsProps {}

const DistanceStatisctics: React.FC<DistanceStatiscticsProps> = ({}) => {
  return (
    <Grid style={{ position: "relative", minHeight: "80vh" }}>
      <Grid.Col span={8}>
        <DistanceWeek />
      </Grid.Col>
      <Grid.Col span={4}>
        <DailyDistance />
      </Grid.Col>
    </Grid>
  );
};

export default DistanceStatisctics;
